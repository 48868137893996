import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, GlobalMessageService, GlobalMessageType, WindowRef } from '@spartacus/core';
import { LoginFormComponent, LoginFormComponentService } from '@spartacus/user/account/components';
import { from } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';
import { SloanCpqUserService } from 'src/app/common/services/sloan-cpq-user.service';
import { SLOAN_CPQ_USER_KEY } from 'src/app/constants/storage.constant';
import { SloanSignupComponent } from '../sloan-signup/sloan-signup.component';
import { SloanForgotPasswordComponent } from 'src/feature-libs/cpq-user/cpq-profile/components/sloan-forgot-password/sloan-forgot-password.component';

@Component({
  selector: 'app-sloan-login',
  templateUrl: './sloan-login.component.html',
  styleUrls: ['./sloan-login.component.scss'],
})
export class SloanLoginComponent extends LoginFormComponent implements OnInit {
  signupModalRef;
  forgotPasswordModalRef;
  disableSubmitBtn = false;
  loginForm: UntypedFormGroup;
  constructor(
    private modalService: NgbModal,
    protected fb: UntypedFormBuilder,
    protected auth: AuthService,
    protected globalMessageService: GlobalMessageService,
    protected winRef: WindowRef,
    private ngbActiveModal: NgbActiveModal,
    private sloanCpqUsersService: SloanCpqUserService,
    protected loginFormComponentService: LoginFormComponentService
  ) {
    // TODO:Changed by Pushpak
    super(loginFormComponentService);
  }

  ngOnInit(): void {
    // TODO:Commented by Pushpak
    //super.ngOnInit();
  }

  submitForm(): void {
    if (this.form.valid) {
      this.disableSubmitBtn = true;
      this.loginUser();
    } else {
      this.form.markAllAsTouched();
    }
  }

  protected loginUser(): void {
    const { userId, password } = this.form.controls;
    from(
      this.auth.loginWithCredentials(
        SLOAN_CPQ_USER_KEY + userId.value.toLowerCase(), // backend accepts lowercase emails only
        password.value
      )
    )
      .pipe(
        withLatestFrom(this.auth.isUserLoggedIn()),
        tap(([_, isLoggedIn]) => {
          if (isLoggedIn) {
            this.disableSubmitBtn = false;
            // We want to remove error messages on successful login (primary the bad username/password combination)
            this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
            this.globalMessageService.add(
              { key: 'sloanloginForm.loginsucessmsg' },
              GlobalMessageType.MSG_TYPE_CONFIRMATION
            );
            this.closeModal();
            this.sloanCpqUsersService.getCPQUserData();
          } else {
            this.disableSubmitBtn = false;
          }
        })
      )
      .subscribe();
  }

  closeModal() {
    this.ngbActiveModal.dismiss();
  }

  openSignUpModal() {
    this.closeModal();
    this.signupModalRef = this.modalService.open(SloanSignupComponent);
  }

  openForgotPwd() {
    this.closeModal();
    this.forgotPasswordModalRef = this.modalService.open(SloanForgotPasswordComponent);
  }
}
