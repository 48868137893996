import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
// TODO:Spartacus 4.3- Many methods from 'UserService' were removed, for more details please look into 4.0 migration documentation.
import { RoutingService } from '@spartacus/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustomFormValidators } from '@spartacus/storefront';
import { UserPasswordFacade } from '@spartacus/user/profile/root';

@Component({
  selector: 'app-sloan-cpq-reset-password',
  templateUrl: './sloan-cpq-reset-password.component.html',
  styleUrls: ['./sloan-cpq-reset-password.component.scss'],
})
export class SloanCpqResetPasswordComponent implements OnInit, OnDestroy {
  isLowercase = false;
  isUpperCase = false;
  isNumber = false;
  eightCharacters = false;
  resetPasswordForm: UntypedFormGroup = this.fb.group(
    {
      password: ['', [Validators.required]],
      repassword: ['', [Validators.required]],
    },
    {
      validators: CustomFormValidators.passwordsMustMatch(
        'password',
        'repassword'
      ),
    }
  );
  subscription = new Subscription();
  token: string;
  constructor(
    private fb: UntypedFormBuilder,
    private routingService: RoutingService,
    private userPasswordFacade: UserPasswordFacade
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.routingService
        .getRouterState()
        .subscribe((state) => (this.token = state.state.queryParams['token']))
    );

    this.subscription.add(
           // TODO:Commented by Pushpak  
   //   this.userService.isPasswordReset().subscribe((reset) => {
  //      if (reset) {
// TODO:Spartacus 4.3- 'RoutingService.go' changed signature. Before 4.0, the object with query params could be passed in the 2nd argument. Now the 2nd argument is Angular NavigationExtras object (with 'queryParams' property).
   //       this.routingService.go({ cxRoute: 'login' });
  //      }
   //   })
    );
  }

  isValidPassword() {
    return (
      this.isLowercase &&
      this.isNumber &&
      this.isUpperCase &&
      this.eightCharacters
    );
  }

  resetPassword() {
    if (this.resetPasswordForm.valid && this.isValidPassword()) {
// TODO:Spartacus - Method 'get' was removed from Class 'UserService'. Use 'UserAccountFacade.get()' from '@spartacus/user' instead.
      const password = this.resetPasswordForm.get('password').value;
      this.userPasswordFacade.reset(this.token, password);
// TODO:Spartacus 4.3- 'RoutingService.go' changed signature. Before 4.0, the object with query params could be passed in the 2nd argument. Now the 2nd argument is Angular NavigationExtras object (with 'queryParams' property).
      this.routingService.go('/');
    } else {
      this.resetPasswordForm.markAllAsTouched();
    }
  }

  checkPasswordValidations() {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;
    const specialCaseLetters = /[]/g;
    const password = this.resetPasswordForm.value.password;
    this.isLowercase = password.match(lowerCaseLetters);
    this.isUpperCase = password.match(upperCaseLetters);
    this.isNumber = password.match(numbers);
    this.eightCharacters = password && password.length >= 8 ? true : false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
