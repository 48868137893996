import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthRedirectService, AuthService, SemanticPathService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SloanCpqAuthGuard  {
  constructor(
    protected authService: AuthService,
    protected authRedirectService: AuthRedirectService,
    protected router: Router,
    protected semanticPathService: SemanticPathService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authService.isUserLoggedIn().pipe(
      map((isLoggedIn) => {
        if (!isLoggedIn) {
          this.authRedirectService.saveCurrentNavigationUrl();
          return this.router.parseUrl(this.semanticPathService.get(''));
        }
        return isLoggedIn;
      })
    );
  }
}
