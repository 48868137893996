import { NgModule } from '@angular/core';
import {
  CartBaseCoreModule,
  CartPageEventModule,
} from '@spartacus/cart/base/core';
import { CartBaseOccModule } from '@spartacus/cart/base/occ';
import { WishListModule } from '@spartacus/cart/wish-list';
import { CheckoutCoreModule } from '@spartacus/checkout/base/core';
import { CheckoutOccModule } from '@spartacus/checkout/base/occ';
import {
  AnonymousConsentsModule,
  AuthModule,
  CostCenterOccModule,
  ProductModule,
  ProductOccModule,
  UserOccModule,
} from '@spartacus/core';
import {
  OrderCancellationModule,
  OrderConfirmationModule,
  OrderDetailsModule,
  OrderHistoryModule,
  OrderReturnModule,
  ReplenishmentOrderDetailsModule,
  ReplenishmentOrderHistoryModule,
  ReturnRequestDetailModule,
  ReturnRequestListModule,
} from '@spartacus/order/components';
import {
  AnonymousConsentManagementBannerModule,
  AnonymousConsentsDialogModule,
  BannerCarouselModule,
  BannerModule,
  BreadcrumbModule,
  CategoryNavigationModule,
  CmsParagraphModule,
  ConsentManagementModule,
  FooterNavigationModule,
  HamburgerMenuModule,
  LayoutModule,
  LinkModule,
  MyCouponsModule,
  MyInterestsModule,
  NavigationEventModule,
  NavigationModule,
  NotificationPreferenceModule,
  PaymentMethodsModule,
  ProductCarouselModule,
  ProductFacetNavigationModule,
  ProductImagesModule,
  ProductIntroModule,
  ProductListModule,
  ProductPageEventModule,
  ProductReferencesModule,
  ProductSummaryModule,
  ProductTabsModule,
  SearchBoxModule,
  SiteContextSelectorModule,
  StockNotificationModule,
  TabParagraphContainerModule,
  UserComponentModule,
} from '@spartacus/storefront';
import { UserAccountModule } from '@spartacus/user/account';
import {
  AddressBookModule,
  CloseAccountModule,
  ForgotPasswordModule,
  ResetPasswordModule,
  UpdateEmailModule,
  UpdatePasswordModule,
  UpdateProfileModule,
} from '@spartacus/user/profile/components';
import { ProductVariantsFeatureModule } from './features/product/product-variants-feature.module';
import { SmartEditFeatureModule } from './features/smartedit/smart-edit-feature.module';
import { TagManagementFeatureModule } from './features/tracking/tag-management-feature.module';
import { UserFeatureModule } from './features/user/user-feature.module';
import { SloanCpqProjectsModule } from 'src/feature-libs/cpq-project/sloan-cpq-projects.module';
import { SloanCpqAccountModule } from 'src/feature-libs/cpq-user/cpq-account/sloan-login-signup.module';
import { SloanCpqProfileModule } from 'src/feature-libs/cpq-user/cpq-profile/sloan-cpq-profile.module';
import { SloanHeadersModule } from '../common/sloan-headers/sloan-headers.module';
import { SloancpqLayoutConfigModule } from '../common/sloancpq-layout-config/sloancpq-layout-config.module';
import { Sloani18nModule } from '../common/i18n/sloani18n/sloani18n.module';
import { SloanCpqCommonComponentModule } from '../common/component/sloan-cpq-common-component.module';
import { SloanCpqLibsModule } from '../cpq-libs/sloan-cpq-libs.module';

@NgModule({
  imports: [
    Sloani18nModule,
    SloancpqLayoutConfigModule,
    SloanCpqLibsModule,
    LayoutModule,
    SloanCpqAccountModule,
    SloanHeadersModule,
    SloanCpqProfileModule,
    SloanCpqCommonComponentModule,
    SloanCpqProjectsModule,
    AnonymousConsentManagementBannerModule,
    HamburgerMenuModule,
    CmsParagraphModule,
    LinkModule,
    BannerModule,
    CategoryNavigationModule,
    NavigationModule,
    FooterNavigationModule,
    BreadcrumbModule,
    SearchBoxModule,
    SiteContextSelectorModule,
    AddressBookModule,
    OrderHistoryModule,
    OrderCancellationModule,
    OrderReturnModule,
    ReturnRequestListModule,
    ReturnRequestDetailModule,
    ProductListModule,
    ProductFacetNavigationModule,
    ProductTabsModule,
    ProductCarouselModule,
    ProductReferencesModule,
    OrderDetailsModule,
    PaymentMethodsModule,
    ConsentManagementModule,
    TabParagraphContainerModule,
    OrderConfirmationModule,
    ProductImagesModule,
    ProductSummaryModule,
    ProductIntroModule,
    BannerCarouselModule,
    MyCouponsModule,
    WishListModule,
    NotificationPreferenceModule,
    MyInterestsModule,
    StockNotificationModule,
    ReplenishmentOrderHistoryModule,
    ReplenishmentOrderDetailsModule,
    UserComponentModule,
    CloseAccountModule,
    UpdateEmailModule,
    UpdatePasswordModule,
    UpdateProfileModule,
    ForgotPasswordModule,
    ResetPasswordModule,
    AnonymousConsentsDialogModule,
    AuthModule.forRoot(),
    AnonymousConsentsModule.forRoot(),
    CheckoutCoreModule,
    UserAccountModule,
    ProductModule.forRoot(),
    CartPageEventModule,
    NavigationEventModule,
    ProductPageEventModule,
    CheckoutOccModule,
    ProductOccModule,
    UserOccModule,
    CostCenterOccModule,
    ProductVariantsFeatureModule,
    CartBaseCoreModule,
    CartBaseOccModule,
    SmartEditFeatureModule,
    TagManagementFeatureModule,
    UserFeatureModule,
  ],
})
export class SpartacusFeaturesModule {}
