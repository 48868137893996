<div class="container-fluid">
  <div class="row mt-3 projectHeader">
    <div class="col col-xs-12 col-sm-6 col-md-6 col-lg-6 nopad">
      <h3 class="header-title">{{ 'projectList.projects' | cxTranslate }}</h3>
    </div>
    <div class="col col-xs-12 col-sm-6 col-md-6 col-lg-6 nopad">
      <button (click)="openNewProjectModal()" class="btn btn-primary float-right">
        {{ 'projectList.createNewProject' | cxTranslate }}
      </button>
    </div>
  </div>
  <ng-container *ngIf="(output$ | async) && (output$ | async).length > 0 && !(isLoading$ | async)">
    <div class="row mt-3 projectSearch nomar">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 nopad nopad">
        <!-- <input type="checkbox" class="ml-2 mt-3" /> -->
        <div class="form-group has-search">
          <span class="fas fa-search form-control-feedback">

          </span>
          <input
            type="text"
            [(ngModel)]="projectSearchText"
            class="form-control"
            placeholder="Search"
            (ngModelChange)="sloanCPQOnClickEvent(projectSearchText)"
          />
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 nopad text-right nopad">
        <div ngbDropdown class="d-inline-block sortByDropdown">
          <button class="btn btn-outline-default" id="dropdownForm1" ngbDropdownToggle>
            <ng-container *ngIf="selectedSortType">
              {{ selectedSortType }}
            </ng-container>
            <ng-container *ngIf="!selectedSortType">
              {{ 'projectList.sortBy' | cxTranslate }}
            </ng-container>
            <img src="assets/images/chevron-down-black.svg" />
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownForm1" class="w-100 dropdown-menu">
            <a href="javascript:void(0)" (click)="sortAtoZ()" ngbDropdownItem>
              {{ 'projectList.aToZ' | cxTranslate }}</a
            >
            <a href="javascript:void(0)" (click)="sortByLastModified()" ngbDropdownItem>
              {{ 'projectList.lastModified' | cxTranslate }}</a
            >
            <a (click)="sortByCreationDate()" ngbDropdownItem> {{ 'projectList.recentlyUsed' | cxTranslate }}</a>
            <a href="javascript:void(0)" (click)="sortByArchived()" ngbDropdownItem>
              {{ 'projectList.archived' | cxTranslate }}</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row sloanProjectsList">
      <ng-container *ngIf="output$ | async | search : projectSearchText : 'name' as result">
        <ng-container *ngFor="let project of result">
          <ng-container *ngIf="isArchived(project)">
            <sloan-cpq-project-item [project]="project" class="sloanProject"> </sloan-cpq-project-item>
          </ng-container>
        </ng-container>
        <div class="alert-info p-3 rounded mt-3" *ngIf="result.length <= 0">
          {{ 'projectList.nodataMsg' | cxTranslate }}
        </div>
      </ng-container>
    </div>
    <div class="text-center more-projects">
      <button
        *ngIf="(output$ | async) && (output$ | async).length >= 20 && !(lastPage$ | async)"
        (click)="onClikMore()"
      >
        {{ 'projectList.moreProjectsButton' | cxTranslate }}
      </button>
    </div>
  </ng-container>
  <div
    class="alert-info p-3 rounded mt-3"
    *ngIf="(output$ | async) && (output$ | async).length <= 0 && !(isLoading$ | async)"
  >
    {{ 'projectList.nodataMsg' | cxTranslate }}
  </div>
  <!-- 
  <ng-container *ngIf="!(output$ | async) || (isLoading$ | async)">
    <cx-spinner></cx-spinner>
  </ng-container> -->
</div>
