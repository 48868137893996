import { NgModule } from '@angular/core';
import { SloanCpqProfileComponentModule } from './components/solan-cpq-profile-components.module';

@NgModule({
  declarations: [],
  imports: [
    SloanCpqProfileComponentModule
  ],
})
export class SloanCpqProfileModule {}
