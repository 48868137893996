<div class="container-fluid">
  <div class="row mt-3 projectHeader">
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 nopad">
      <p>
        <a href="javascript:void(0)" class="back-link" (click)="goToProjects()">
          <img src="../../../assets/images/arrow-left.svg" />
          Projects</a
        >
      </p>
      <p
        class="confInfo"
        *ngIf="(configuratorList$ | async)?.savedCartData as configurators"
      >
        <span class="confName">{{ configurators.name }}</span
        ><span class="projCode">Project Code {{ configurators.code }}</span>
      </p>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 nopad">
      <button
        [disabled]="isLoading$ | async"
        class="btn btn-default"
        (click)="createConfiguration()"
      >
        {{ "configuratorsList.addNewConfiguration" | cxTranslate }}
      </button>
      <button
        [disabled]="!selectedConfigurators || selectedConfigurators.length < 1"
        (click)="openDownloadDocunments()"
        class="btn btn-primary"
      >
        {{ "configuratorsList.downloadandRequestDocuments" | cxTranslate }}
      </button>
    </div>
  </div>
  <ng-container
    *ngIf="
      (output$ | async) && (output$ | async).length > 0 && !(isLoading$ | async)
    "
  >
    <div class="row mt-3 projectSearch nomar">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 nopad nopad">
        <input
          type="checkbox"
          [checked]="allChecked"
          (change)="selectAll($event)"
          class="ml-2 mt-3"
        />
        <div class="form-group has-search ml-5">
          <span class="fa fa-search form-control-feedback"></span>
          <input
            type="text"
            [(ngModel)]="configSearchText"
            class="form-control"
            placeholder="Search"
          />
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 nopad text-right nopad">
        <div ngbDropdown class="d-inline-block sortByDropdown">
          <button
            class="btn btn-outline-default"
            id="dropdownForm1"
            ngbDropdownToggle
          >
            <ng-container *ngIf="selectedConfigurationSortType">
              {{ selectedConfigurationSortType }}
            </ng-container>
            <ng-container *ngIf="!selectedConfigurationSortType">
              {{ "configuratorsList.sortBy" | cxTranslate }}
            </ng-container>
            <img src="../../../assets/images/chevron-down-black.svg" />
          </button>
          <div
            ngbDropdownMenu
            aria-labelledby="dropdownForm1"
            class="dropdown-menu"
          >
            <a (click)="sortByAtoZ()" ngbDropdownItem>{{
              "configuratorsList.atoz" | cxTranslate
            }}</a>
            <a (click)="sortByLastModified()" ngbDropdownItem>{{
              "configuratorsList.lastModified" | cxTranslate
            }}</a>
            <a (click)="sortByCreationDate()" ngbDropdownItem>{{
              "configuratorsList.recentlyUsed" | cxTranslate
            }}</a>
            <a (click)="sortByLocked()" ngbDropdownItem>{{
              "configuratorsList.locked" | cxTranslate
            }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row sloanProjectsList">
      <ng-container
        *ngIf="
          output$
            | async
            | search: configSearchText:'configurationName' as result
        "
      >
      
 <!--  TODO: Commented by Pushpak needs to be resolved  (duplicateConfigEvent)="duplicateConfigCommerce($event)"--> 
        <ng-container *ngFor="let config of result">
          <ng-container *ngIf="isLocked(config)">
            <sloan-cpq-configurator-item
              [configurator]="config"
              [projectCode]="projectCode"
              (checkedConfigurators)="onSelectConfigurator($event)"
              (editConfigEvent)="editConfig($event)"
              class="sloanProject"
            >
            </sloan-cpq-configurator-item>
          </ng-container>
        </ng-container>
        <div class="alert-info p-3 rounded mt-3" *ngIf="result.length <= 0">
          {{ "configuratorsList.nodataMsg" | cxTranslate }}
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div
    #noConfigs
    class="alert-info p-3 rounded mt-3"
    *ngIf="
      (output$ | async) &&
      (output$ | async).length <= 0 &&
      !(isLoading$ | async)
    "
  >
    {{ "configuratorsList.nodataMsg" | cxTranslate }}
  </div>

  <ng-container *ngIf="!(output$ | async) || (isLoading$ | async)">
    <ngx-spinner size="medium" color="#fff" type="ball-clip-rotate">
    </ngx-spinner>
  </ng-container>
</div>
