import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { SloanCpqConfiguratorService } from 'src/app/common/services/sloan-cpq-configurator.service';
import { SloanCpqThreeKitService } from 'src/app/common/services/sloan-cpq-three-kit.service';
import { ThreekitMappingService } from 'src/app/common/services/threekit-mapping.service';
import { ConfigSaveObj, Entry2 } from 'src/app/models/configurator.model';
import { ThreeKitConfigObj } from 'src/app/models/threekit.model';
import { SloanCpqAproveDesignComponent } from '../../sloan-cpq-aprove-design/sloan-cpq-aprove-design.component';
import { SloanCpqViewNotesComponent } from '../../sloan-cpq-view-notes/sloan-cpq-view-notes.component';
import { SloanCpqConfirmDialogService } from 'src/app/common/component/sloan-cpq-confirm-dialog/sloan-cpq-confirm-dialog.service';
import { SloanSaveProjectConfigurationComponent } from 'src/feature-libs/cpq-project/components/sloan-save-project-configuration/sloan-save-project-configuration.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'sloan-cpq-configurator-item',
  templateUrl: './sloan-cpq-configurator-item.component.html',
  styleUrls: ['./sloan-cpq-configurator-item.component.scss'],
})
export class SloanCpqConfiguratorItemComponent implements OnInit {
  imageUrl = '../../../assets/images/';
  modalRef;
  @Input() configurator: Entry2;
  @Input() projectCode: string;
  @Input() entries: Entry2[];
  @Output() editConfigEvent: EventEmitter<Entry2> = new EventEmitter();
  @Output() duplicateConfigEvent: EventEmitter<ThreeKitConfigObj> = new EventEmitter();
  @Output() checkedConfigurators: EventEmitter<boolean> = new EventEmitter();
  approveDesignModalRef: NgbModalRef;
  openViewNotesModalRef;
  subscription = new Subscription();
  selectedConfigurators: string[] = [];
  savemodalRef;
  constructor(
    public sloanCpqConfiguratorService: SloanCpqConfiguratorService,
    private modalService: NgbModal,
    private confirmationDialogService: SloanCpqConfirmDialogService,
    private sloanCpqThreeKitService: SloanCpqThreeKitService,
    private globalMessageService: GlobalMessageService,
    private threekitMappingService: ThreekitMappingService
  ) {
    this.subscription.add(
      this.sloanCpqConfiguratorService.loadSelectedConfigurators$().subscribe((selectedProductsCodes) => {
        this.selectedConfigurators = selectedProductsCodes;
      })
    );
  }

  ngOnInit(): void {}

  deleteConfig() {
    this.confirmationDialogService
      .confirm('configurators.configuratorItem.delete', 'configurators.configuratorItem.deleteConfirmMsg')
      .then((confirmed) => {
        if (confirmed) {
          let reqobj;
          if (this.selectedConfigurators && this.selectedConfigurators.length > 0) {
            reqobj = this.selectedConfigurators;
          } else {
            reqobj = [this.configurator.configurationUid];
          }
          this.sloanCpqThreeKitService.deleteConfig(reqobj).subscribe((res) => {
            this.sloanCpqConfiguratorService.deleteConfiguration(this.projectCode, this.configurator.configurationUid);
          });
        } else {
          // console.log(confirmed);
        }
      })
      .catch(() => {});
  }

  editConfig() {
    this.editConfigEvent.emit(this.configurator);
  }

  openAproveDesign() {
    let modalInstance;
    this.approveDesignModalRef = this.modalService.open(SloanCpqAproveDesignComponent);
    modalInstance = this.approveDesignModalRef.componentInstance;
    modalInstance.configurator = this.configurator;
    modalInstance.entries = this.entries;
    modalInstance.projectId = this.projectCode;
  }

  openViewNotes() {
    let modalInstance;
    this.openViewNotesModalRef = this.modalService.open(SloanCpqViewNotesComponent);
    modalInstance = this.openViewNotesModalRef.componentInstance;
    modalInstance.configDetail = this.configurator;
  }

  isChecked(code: any): boolean {
    if (this.selectedConfigurators) {
      return this.selectedConfigurators.indexOf(code) > -1;
    }
    return false;
  }
  changeCheckBox(event) {
    event.stopPropagation();
    const selectedConfiguratorCodes = event.currentTarget.checked ? this.addProductCode() : this.removeProductCode();
    this.sloanCpqConfiguratorService.setSelectedConfigurators(selectedConfiguratorCodes);
  }

  private addProductCode() {
    if (this.selectedConfigurators && this.selectedConfigurators.length) {
      this.selectedConfigurators.push(this.configurator.configurationUid as unknown as string);
    } else {
      this.selectedConfigurators = [this.configurator.configurationUid as unknown as string];
    }
    return this.selectedConfigurators;
  }
  private removeProductCode() {
    this.selectedConfigurators.splice(
      this.selectedConfigurators.indexOf(this.configurator.configurationUid as unknown as string),
      1
    );
    return this.selectedConfigurators;
  }

  duplicateConfig() {
    this.confirmationDialogService
      .confirm('configurators.configuratorItem.duplicate', 'configurators.configuratorItem.duplicateConfirmMsg')
      .then((confirmed) => {
        if (confirmed) {
          this.openConfigNameModal();
        } else {
          // console.log(confirmed);
        }
      })
      .catch(() => {});
  }

  openConfigNameModal() {
    this.savemodalRef = this.modalService.open(SloanSaveProjectConfigurationComponent);
    const modalInstance = this.savemodalRef.componentInstance;
    modalInstance.projectId = this.projectCode;
    modalInstance.firstPage = false;
    modalInstance.isDuplicate = true;
    this.savemodalRef.result
      .then((res) => {
        if (!res.projectData) {
          const duplicateconfig = {
            configData: {
              configName: res.configName,
              projectId: this.projectCode,
            },
          };
          this.sloanCpqThreeKitService.duplicateConfig(this.configurator.configurationUid, duplicateconfig).subscribe(
            (duplicateresponse) => {
              const saveProjectBody: ConfigSaveObj =
                this.threekitMappingService.createSaveProjectBody(duplicateresponse);
              const projectId = duplicateresponse.metadata.configData.projectId;
              this.addNewConfiguration(saveProjectBody, projectId);
              // this.duplicateConfigEvent.emit(res);
            },
            (err) => {
              this.globalMessageService.add(
                { key: 'configurators.approveDesign.errorMsg' },
                GlobalMessageType.MSG_TYPE_ERROR
              );
            }
          );
        }
      })
      .catch(() => {});
  }

  addNewConfiguration(reqObject, projectId) {
    this.sloanCpqConfiguratorService.saveConfigurator(reqObject, projectId).subscribe(
      (res) => {
        this.sloanCpqConfiguratorService.getConfigurators(this.projectCode);
        this.sloanCpqThreeKitService.getConfigurators();
      },
      (err) => {}
    );
  }

  canShowOption(): boolean {
    return (this.selectedConfigurators && this.selectedConfigurators.length < 2) || this.selectedConfigurators === null;
  }

  isConfigLocked() {
    return !(this.configurator && this.configurator.status === 'APPROVED_LOCKED');
  }
}
