import { Component, OnInit, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalMessageService, GlobalMessageType, LoggerService, normalizeHttpError } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { SloanCpqConfiguratorService } from 'src/app/common/services/sloan-cpq-configurator.service';
import { Entry2 } from 'src/app/models/configurator.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sloan-cpq-aprove-design',
  templateUrl: './sloan-cpq-aprove-design.component.html',
  styleUrls: ['./sloan-cpq-aprove-design.component.scss'],
})
export class SloanCpqAproveDesignComponent implements OnInit {
  protected logger = inject(LoggerService);
  configurator: Entry2;
  subscription = new Subscription();
  selectedConfigurators: string[];
  entries: Entry2[];
  selectedEntries: Entry2[] = [];
  projectId: string;
  showErrorMessage = false;
  fromDownloadReq = false;
  phoneNumber = environment.globalHelpphoneNumber;
  email = environment.globalHelpemail;
  workingDays = environment.globalHelpworkingDays;
  workingHours = environment.globalHelpworkingHours;
  constructor(
    private ngbActiveModal: NgbActiveModal,
    private sloanCpqConfiguratorService: SloanCpqConfiguratorService,
    private globalMessageService: GlobalMessageService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.sloanCpqConfiguratorService.loadSelectedConfigurators$().subscribe((selectedProductsCodes) => {
        this.selectedConfigurators = selectedProductsCodes;
        this.sloanCpqConfiguratorService.loadConfigurators().subscribe((res) => {
          if (res) {
            if (this.selectedConfigurators && this.selectedConfigurators.length > 0) {
              this.selectedEntries = res.savedCartData.entries.filter((entry) => {
                return this.selectedConfigurators.includes(entry.configurationUid as unknown as string);
              });
            } else {
              this.selectedEntries.push(this.configurator);
            }
          }
        });
      })
    );
  }

  approveDesign() {
    if (!this.fromDownloadReq) {
      const reqObject = {
        configIds: '',
      };
      if (this.selectedConfigurators && this.selectedConfigurators.length > 0) {
        reqObject.configIds = this.selectedConfigurators.join(',');
      } else {
        reqObject.configIds = this.configurator.configurationUid;
      }
      this.sloanCpqConfiguratorService.approveDesign(reqObject, this.projectId).subscribe(
        (res) => {
          this.sloanCpqConfiguratorService.getConfigurators(this.projectId);
          this.closeModal();
          this.globalMessageService.add(
            { key: 'configurators.approveDesign.approvedDesignSuccessFully' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
        },
        (error) => {
          // console.log(err);
          const errordetail = normalizeHttpError(error, this.logger);
          if (errordetail.details) {
            this.globalMessageService.add({ raw: errordetail.details[0].message }, GlobalMessageType.MSG_TYPE_ERROR);
          } else {
            this.globalMessageService.add({ key: 'user.errorMsg' }, GlobalMessageType.MSG_TYPE_ERROR);
          }
          this.closeModal();
        }
      );
    } else {
      this.ngbActiveModal.close(true);
    }
  }

  removeAlert() {
    setTimeout(() => {
      this.showErrorMessage = false;
    }, 3000);
  }

  closeModal() {
    this.ngbActiveModal.dismiss();
  }
}
