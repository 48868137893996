import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { FileSaverService } from 'ngx-filesaver';
import { Subscription } from 'rxjs';
import { SloanCpqConfiguratorService } from 'src/app/common/services/sloan-cpq-configurator.service';
import { Entry2 } from 'src/app/models/configurator.model';
import { SloanCpqAproveDesignComponent } from './../sloan-cpq-aprove-design/sloan-cpq-aprove-design.component';
@Component({
  selector: 'app-sloan-download-and-req-documents',
  templateUrl: './sloan-download-and-req-documents.component.html',
  styleUrls: ['./sloan-download-and-req-documents.component.scss'],
})
export class SloanDownloadAndReqDocumentsComponent implements OnInit {
  configurator: Entry2;
  subscription = new Subscription();
  selectedConfigurators: string[];
  entries: Entry2[];
  selectedEntries: Entry2[] = [];
  projectId: string;
  documentTypes = [
    {
      documentName: 'Standard Size Model Installations Instructions (.pdf)',
      documentValue: 'MII',
      checked: false,
      disabled: false,
    },
    {
      documentName: 'Standard Size Model Rough In Drawings (.pdf)',
      documentValue: 'MRD',
      checked: false,
      disabled: false,
    },
    {
      documentName: 'Standard Size Model Line Drawings (.pdf)',
      documentValue: 'MLD',
      checked: false,
      disabled: false,
    },
    {
      documentName: 'Full Rendering (.png)',
      documentValue: 'FR',
      checked: false,
      disabled: false,
    },
    {
      documentName: 'Detailed Pricing/Configuration Description (.pdf)',
      documentValue: 'QD',
      checked: false,
      disabled: false,
    },
  ];

  emailTypes = [
    {
      emailType: 'Configuration Line Drawings (.pdf)',
      value: 'CLD',
      checked: false,
      disabled: false,
    },
    // {
    //   emailType: 'Configuration Rough-in Drawings (.pdf)',
    //   value: 'CRD',
    //   checked: false,
    //   disabled: false,
    // },
    // {
    //   emailType: 'Configuration BIM/Revit Family (.rfa)',
    //   value: 'BIM',
    //   checked: false,
    //   disabled: false
    // },
    // {
    //   emailType: 'CAD',
    //   value: 'CAD',
    //   cheked: false
    // }
  ];
  selectedEmailTypes;
  selectedDownloadDocTypes: string[] = [];
  showErrorMessage = false;
  selectedReqDocTypes: string[] = [];
  approveDesignModalRef: NgbModalRef;
  disableButtons = false;
  projectName;
  isSelectedCLD = false;
  constructor(
    private ngbActiveModal: NgbActiveModal,
    private sloanCpqConfiguratorService: SloanCpqConfiguratorService,
    private globalMessageService: GlobalMessageService,
    private fileSaver: FileSaverService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.sloanCpqConfiguratorService.loadSelectedConfigurators$().subscribe((selectedProductsCodes) => {
        this.selectedConfigurators = selectedProductsCodes;
        this.sloanCpqConfiguratorService.loadConfigurators().subscribe((res) => {
          if (res) {
            if (this.selectedConfigurators && this.selectedConfigurators.length > 0) {
              this.selectedEntries = res.savedCartData.entries.filter((entry) => {
                return this.selectedConfigurators.includes(entry.configurationUid as unknown as string);
              });
            } else {
              this.selectedEntries.push(this.configurator);
            }
          }
        });
      })
    );
  }

  onChangeDownloadBox(event, documentCode) {
    // console.log(event);
    this.selectedDownloadDocTypes = event.currentTarget.checked
      ? this.addDocument(documentCode)
      : this.removeDocument(documentCode);
    if (this.selectedDownloadDocTypes && this.selectedDownloadDocTypes.length > 0) {
      this.emailTypes.forEach((email) => {
        email.disabled = true;
      });
    } else {
      this.emailTypes.forEach((email) => {
        email.disabled = false;
      });
    }
  }

  openAproveDesign() {
    let modalInstance;
    this.approveDesignModalRef = this.modalService.open(SloanCpqAproveDesignComponent);
    modalInstance = this.approveDesignModalRef.componentInstance;
    modalInstance.configurator = this.configurator;
    modalInstance.entries = this.entries;
    modalInstance.projectId = this.projectId;
    modalInstance.fromDownloadReq = true;
    this.approveDesignModalRef.result.then((confirmed) => {
      if (confirmed) {
        // this.selectedReqDocTypes.push('CAD');
        const reqObject = {
          configIds: '',
          projectId: this.projectId,
          docType: this.selectedReqDocTypes.join(','),
        };
        if (this.selectedConfigurators && this.selectedConfigurators.length > 0) {
          reqObject.configIds = this.selectedConfigurators.join(',');
        } else {
          reqObject.configIds = this.configurator.configurationUid;
        }
        this.disableButtons = true;
        this.sloanCpqConfiguratorService.reqDocuments(reqObject).subscribe(
          (response) => {
            this.closeModal();
            this.disableButtons = false;
            this.globalMessageService.add(
              { key: 'configurators.downloadDocuments.reqDocuments' },
              GlobalMessageType.MSG_TYPE_CONFIRMATION
            );
          },
          (err) => {
            console.log(err);
            this.showErrorMessage = true;
            this.disableButtons = false;
            this.removeAlert();
          }
        );
      }
    });
  }

  onChangeMailBoxNew() {}

  onChangeMailBox(event, documentCode) {
    // If user select CLD we are making
    if (documentCode === 'CLD') {
      if (event.currentTarget.checked) {
        this.selectedReqDocTypes.push('CLD');
        this.isSelectedCLD = true;
      } else {
        this.emailTypes.forEach((reqDocument, index) => {
          reqDocument.checked = false;
          reqDocument.disabled = false;
        });
        this.selectedReqDocTypes.length = 0;
        this.isSelectedCLD = false;
        // this.selectedReqDocTypes.splice(
        //   this.selectedReqDocTypes.indexOf('CAD'),
        //   1
        // );
        // console.log(this.selectedReqDocTypes);
      }
    } else {
      if (event.currentTarget.checked) {
        if (this.selectedReqDocTypes && this.selectedReqDocTypes.length < 1) {
          this.selectedReqDocTypes.push('CLD');
        }
        this.selectedReqDocTypes.push(documentCode);
        this.emailTypes[0].disabled = true;
        this.emailTypes[0].checked = true;
      } else {
        this.selectedReqDocTypes.splice(this.selectedReqDocTypes.indexOf(documentCode as unknown as string), 1);
        if (this.selectedReqDocTypes && this.selectedReqDocTypes.length === 1 && !this.isSelectedCLD) {
          this.selectedReqDocTypes.splice(this.selectedReqDocTypes.indexOf('CLD' as unknown as string), 1);
        }
        if (this.selectedReqDocTypes && this.selectedReqDocTypes.length === 0 && !this.isSelectedCLD) {
          this.emailTypes[0].disabled = false;
          this.emailTypes[0].checked = false;
        } else if (this.selectedReqDocTypes && this.selectedReqDocTypes.length === 1 && this.isSelectedCLD) {
          this.emailTypes[0].disabled = false;
        }
      }
    }
    if (this.selectedReqDocTypes && this.selectedReqDocTypes.length > 0) {
      this.documentTypes.forEach((document) => {
        document.disabled = true;
      });
    } else {
      this.documentTypes.forEach((document) => {
        document.disabled = false;
      });
    }
  }
  private addDocument(documentCode) {
    if (this.selectedDownloadDocTypes && this.selectedDownloadDocTypes.length) {
      this.selectedDownloadDocTypes.push(documentCode as unknown as string);
    } else {
      this.selectedDownloadDocTypes = [documentCode as unknown as string];
    }
    return this.selectedDownloadDocTypes;
  }
  private removeDocument(documentCode) {
    this.selectedDownloadDocTypes.splice(this.selectedDownloadDocTypes.indexOf(documentCode as unknown as string), 1);
    return this.selectedDownloadDocTypes;
  }

  reqForDownloadDocuments() {
    // this.closeModal();
    this.openAproveDesign();
  }

  downloadDocuments() {
    const reqObject = {
      configIds: '',
      projectId: this.projectId,
      docType: this.selectedDownloadDocTypes.join(','),
    };
    if (this.selectedConfigurators && this.selectedConfigurators.length > 0) {
      reqObject.configIds = this.selectedConfigurators.join(',');
    } else {
      reqObject.configIds = this.configurator.configurationUid;
    }
    this.disableButtons = true;
    this.sloanCpqConfiguratorService.downloadDocuments(reqObject).subscribe(
      (response) => {
        // if (this.selectedConfigurators && this.selectedConfigurators.length > 0) {
        this.disableButtons = false;
        this.fileSaver.save(response as any, this.projectId + '_' + this.projectName + '.zip');
        // }
        // else {
        //   this.fileSaver.save((response as any), this.configurator.configurationUid + '.pdf');
        // }
        this.closeModal();
        this.globalMessageService.add(
          { key: 'configurators.downloadDocuments.downloadedSuccessfully' },
          GlobalMessageType.MSG_TYPE_CONFIRMATION
        );
      },
      (err) => {
        this.disableButtons = false;
        // console.log(err);
        this.showErrorMessage = true;
        this.removeAlert();
      }
    );
  }

  removeAlert() {
    setTimeout(() => {
      this.showErrorMessage = false;
    }, 3000);
  }

  closeModal() {
    this.ngbActiveModal.dismiss();
  }
}
