import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProectSearchPipe } from './proect-search.pipe';
import { ConfigSearchPipe } from './config-search.pipe';
import { SafePipe } from './safe-pipe.pipe';

@NgModule({
  declarations: [ProectSearchPipe, ConfigSearchPipe, SafePipe],
  imports: [CommonModule],
  exports: [ProectSearchPipe, ConfigSearchPipe, SafePipe],
})
export class SloanCpqPipesModule {}
