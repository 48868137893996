import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, GlobalMessageService, GlobalMessageType, RoutingService, WindowRef } from '@spartacus/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SloanCpqConfiguratorService } from 'src/app/common/services/sloan-cpq-configurator.service';
import { SloanCpqProjectService } from 'src/app/common/services/sloan-cpq-project.service';
import { SloanCpqThreeKitService } from 'src/app/common/services/sloan-cpq-three-kit.service';
import { SloanCpqUserService } from 'src/app/common/services/sloan-cpq-user.service';
import { ThreekitMappingService } from 'src/app/common/services/threekit-mapping.service';
import { CONFIG_DATA_KEY, PREVIOUS_URL, USER_DATA_KEY } from 'src/app/constants/storage.constant';
import { POST_MESSAGE_TYPE, RECEIVE_MESSAGE_TYPE } from 'src/app/constants/threekit.constant';
import { ConfigSaveObj, SavedCartData, UpdateConfiguration } from 'src/app/models/configurator.model';
import { SloanUser } from 'src/app/models/sloan-user';
import {
  ThreeKitConfigData,
  ThreeKitConfigFailResponse,
  ThreeKitConfigResponse,
  ThreeKitUserData,
} from 'src/app/models/threekit.model';
import { environment } from 'src/environments/environment';
import { SloanCpqConfiguratorsSaveComponent } from '../sloan-cpq-configurators/sloan-cpq-configurators-save/sloan-cpq-configurators-save.component';
import { SloanSinkConfigHelpComponent } from '../sloan-sink-config-help/sloan-sink-config-help.component';
import { SloanSaveProjectConfigurationComponent } from 'src/feature-libs/cpq-project/components/sloan-save-project-configuration/sloan-save-project-configuration.component';
import { SlaonThreeKitLoginPopupComponent } from 'src/feature-libs/cpq-user/cpq-account/components/slaon-three-kit-login-popup/slaon-three-kit-login-popup.component';
import { SloanLoginComponent } from 'src/feature-libs/cpq-user/cpq-account/components/sloan-login/sloan-login.component';
import { SloanSignupComponent } from 'src/feature-libs/cpq-user/cpq-account/components/sloan-signup/sloan-signup.component';
import { SloanGlobalHelpComponent } from 'src/app/common/component/sloan-global-help/sloan-global-help.component';

@Component({
  selector: 'app-sloancpq-home',
  templateUrl: './sloancpq-home.component.html',
  styleUrls: ['./sloancpq-home.component.scss'],
})
export class SloancpqHomeComponent implements OnInit, OnDestroy {
  messages: any[] = [];
  isLoggedIn = false;
  subscription = new Subscription();
  userData: ThreeKitUserData = {} as ThreeKitUserData;
  configRequest: ThreeKitConfigData = {} as ThreeKitConfigData;
  configResponse: ThreeKitConfigResponse = {} as ThreeKitConfigResponse;
  isThreeKitPageLoaded = false;
  savemodalRef: NgbModalRef;
  newProjectModalRef: NgbModalRef;
  // childOrigin = 'https://sloan-staging.herokuapp.com/demo/';
  savedData;
  isUpdateConfig = false;
  projectDataForUpdate;
  configDataForUpdate;
  childOrigin = environment.threeKitUrl;
  showsaveprogress = false;
  showupdateProgress = false;
  isSaveConfigPopupOpened = false;
  logInModalRef: NgbModalRef;
  signUpModalRef: NgbModalRef;
  constructor(
    private winRef: WindowRef,
    private authService: AuthService,
    private sloanCpqUsersService: SloanCpqUserService,
    private modalService: NgbModal,
    private sloanCpqThreeKitService: SloanCpqThreeKitService,
    private routingService: RoutingService,
    private sloanCpqProjectService: SloanCpqProjectService,
    private globalMessageService: GlobalMessageService,
    private sloanCpqConfiguratorService: SloanCpqConfiguratorService,
    private threeKitMappingService: ThreekitMappingService,
    private ref: ChangeDetectorRef,
    private spinner: NgxSpinnerService
  ) {
    this.subscription.add(
      this.routingService.getRouterState().subscribe((state) => {
        if (state.state?.queryParams) {
          const queryParams = state.state?.queryParams;
          if (queryParams.templateId) {
            this.childOrigin = this.childOrigin + state.state.url;
          }
          if (queryParams.cpqEvent === 'login') {
            this.openLoginModal();
          }
          if (queryParams.cpqEvent === 'register') {
            this.openSignUpModal();
          }
        }
      })
    );
    this.subscription.add(
      this.sloanCpqThreeKitService.loadDataForConfigUpdate().subscribe((configdata) => {
        if (configdata) {
          console.log(configdata);
          this.isUpdateConfig = true;
          this.projectDataForUpdate = configdata.projectData;
          this.configDataForUpdate = configdata.configurator;
          this.childOrigin = this.childOrigin + '?uuid=' + configdata.configurator.configurationUid + '&mode=update';
        }
      })
    );
    // this.stopListening = renderer.listen(
    //   'window',
    //   'message',
    //   this.onremovelistener.bind(this)
    // );
  }

  sendConfigDataToThreeKitWhileUpdate() {
    if (this.isUpdateConfig) {
      const formData = {
        configName: this.configDataForUpdate.configurationName,
        notes: this.projectDataForUpdate.description,
        cpqVerticalId: this.projectDataForUpdate.cpqVerticalId,
        projectData: this.projectDataForUpdate,
      };
      const preparedConfigData = this.threeKitMappingService.prepareConfigRequest(formData);
      this.postMessageToThreeKit(POST_MESSAGE_TYPE.CONFIG_DATA, preparedConfigData);
    } else {
      this.openSaveConfigModal();
    }
  }

  loadConfigData() {
    if (this.winRef.sessionStorage) {
      const context = this.winRef.sessionStorage.getItem(CONFIG_DATA_KEY);
      if (context) {
        const configselecteddata: SavedCartData = JSON.parse(context);
        this.threeKitMappingService.prepareConfigRequest(configselecteddata);
      }
    }
  }

  ngOnInit(): void {
    // window.addEventListener('message', this.receiveMessage.bind(this), false);
    this.subscription.add(
      this.authService.isUserLoggedIn().subscribe((res) => {
        this.isLoggedIn = res;
        if (res) {
          this.sloanCpqUsersService.getCPQUserData();
        } else {
          this.winRef.sessionStorage.removeItem(USER_DATA_KEY);
          if (this.isThreeKitPageLoaded) {
            this.postMessageToThreeKit(POST_MESSAGE_TYPE.USER_DATA, null);
          }
        }
      })
    );
    this.subscription.add(
      this.sloanCpqUsersService.loadUserData().subscribe((loginUser: SloanUser) => {
        if (loginUser) {
          // this.winRef.sessionStorage.setItem(
          //   USER_DATA_KEY,
          //   JSON.stringify(loginUser)
          // );
          this.userData = this.threeKitMappingService.prepareUserData(loginUser);
          if (this.isThreeKitPageLoaded) {
            this.postMessageToThreeKit(POST_MESSAGE_TYPE.USER_DATA, this.userData);
          }
        }
      })
    );
    this.loadConfigData();
  }

  @HostListener('window:message', ['$event'])
  receiveMessage(event: any) {
    // if (event.origin !== this.childOrigin) return;
    // console.log(event);
    try {
      const data = event && event?.data ? JSON.parse(event?.data) : event?.data;
      switch (data.messageType) {
        case RECEIVE_MESSAGE_TYPE.STATE_LOADED:
          console.log(
            '%cChild state loaded, parent site can post data to child site now!',
            'color: blue; font-size:8px '
          );
          this.isThreeKitPageLoaded = true;
          if (this.isLoggedIn) {
            this.postMessageToThreeKit(POST_MESSAGE_TYPE.USER_DATA, this.userData);
            if (this.isUpdateConfig) {
              this.sendConfigDataToThreeKitWhileUpdate();
            }
          } else {
            this.postMessageToThreeKit(POST_MESSAGE_TYPE.USER_DATA, null);
          }
          break;
        case RECEIVE_MESSAGE_TYPE.REQUEST_USER_DATA:
          if (this.isLoggedIn) {
            this.postMessageToThreeKit(POST_MESSAGE_TYPE.USER_DATA, this.userData);
          } else {
            this.modalService.open(SlaonThreeKitLoginPopupComponent);
          }
          break;
        case RECEIVE_MESSAGE_TYPE.REQUEST_CONFIG_DATA:
          // use setTimeout to simulate extra time required for enter configData
          if (this.isLoggedIn) {
            this.sendConfigDataToThreeKitWhileUpdate();
          }
          break;
        case RECEIVE_MESSAGE_TYPE.SAVE_CONFIG_SUCCESS:
          console.log(data.value);
          this.processSaveConfigSuccessResponse(data.value, true);
          break;
        case RECEIVE_MESSAGE_TYPE.SAVE_CONFIG_FAIL:
          console.log(data.value);
          this.processSaveConfigFailResponse(data.value, true);
          break;
        case RECEIVE_MESSAGE_TYPE.UPDATE_CONFIG_SUCCESS:
          console.log(data.value);
          this.ref.markForCheck();
          this.showupdateProgress = true;
          this.spinner.show();
          this.processSaveConfigSuccessResponse(data.value, false);
          break;
        case RECEIVE_MESSAGE_TYPE.UPDATE_CONFIG_FAIL:
          console.log(data.value);
          this.processSaveConfigFailResponse(data.value, false);
          break;
        case RECEIVE_MESSAGE_TYPE.SHOW_PROJECTS:
          this.goToMyProjects();
          break;
        case RECEIVE_MESSAGE_TYPE.SHOW_INFO:
          console.log('clicked on info');
          this.openSinkHelpModal();
          break;
        default:
          throw new Error(`Unknow messageType from child site ${data.messageType}`);
      }
    } catch (e) {
      return;
    }
  }

  openGlobalHelpModal() {
    const globalHelpModalRef: NgbModalRef = this.modalService.open(SloanGlobalHelpComponent);
  }

  openSinkHelpModal() {
    const userdata = this.winRef.sessionStorage.getItem(USER_DATA_KEY);
    if (userdata) {
      const userdataJson: SloanUser = JSON.parse(userdata);
      if (userdataJson && userdataJson.cpqAccountManager && Object.keys(userdataJson.cpqAccountManager).length > 0) {
        const sinkHelpModalRef: NgbModalRef = this.modalService.open(SloanSinkConfigHelpComponent);
      } else {
        this.openGlobalHelpModal();
      }
    } else {
      this.openGlobalHelpModal();
    }
  }

  goToMyProjects() {
    this.routingService.goByUrl('/projects');
  }

  postMessageToThreeKit(messageType: string, value: any) {
    const message = { messageType, value };
    const iframe = document.getElementById('threekitiframe');
    if (iframe == null) {
      return;
    }
    const iWindow = (iframe as HTMLIFrameElement).contentWindow;
    console.log('%cParent site post message:', 'color: green; font-size: 12px', message);
    iWindow.postMessage(JSON.stringify(message), this.childOrigin);
  }

  openSaveConfigModal() {
    this.savemodalRef = this.modalService.open(SloanSaveProjectConfigurationComponent);
    this.savemodalRef.result
      .then((res) => {
        if (!res.projectData) {
          // new project - projectdata is null
          this.openNewProjectModal(res.configName);
        } else {
          // Existing project
          const preparedConfigData = this.threeKitMappingService.prepareConfigRequest(res);
          this.ref.markForCheck();
          this.showsaveprogress = true;
          this.spinner.show();
          this.postMessageToThreeKit(POST_MESSAGE_TYPE.CONFIG_DATA, preparedConfigData);
        }
      })
      .catch(() => {});
  }

  openNewProjectModal(configName) {
    let modalInstance: any;
    this.isSaveConfigPopupOpened = true;
    this.newProjectModalRef = this.modalService.open(SloanCpqConfiguratorsSaveComponent);
    modalInstance = this.newProjectModalRef.componentInstance;
    modalInstance.configurationName = configName;

    this.newProjectModalRef.result
      .then((res) => {
        this.isSaveConfigPopupOpened = false;
        const reqObjForSaveconfig = this.threeKitMappingService.prepareSaveProjectBodyAndSave(res);
        this.spinner.show();
        this.addNewProject(reqObjForSaveconfig, res);
      })
      .catch(() => {
        this.isSaveConfigPopupOpened = false;
      });
  }

  addNewProject(saveProjectBody, newProjectFormData) {
    this.ref.markForCheck();
    this.showsaveprogress = true;
    this.sloanCpqProjectService.saveProject(saveProjectBody).subscribe(
      (res: any) => {
        this.savedData = res.savedCartData;
        newProjectFormData.code = this.savedData.code;
        const preparedConfigData = this.threeKitMappingService.prepareConfigRequest(newProjectFormData);
        this.showsaveprogress = true;
        this.postMessageToThreeKit(POST_MESSAGE_TYPE.CONFIG_DATA, preparedConfigData);
      },
      (err) => {
        this.showsaveprogress = false;
        this.spinner.hide();
        this.ref.markForCheck();
        this.globalMessageService.add(
          { key: 'configurators.configuratorSave.errorMsg' },
          GlobalMessageType.MSG_TYPE_ERROR
        );
      }
    );
  }

  processSaveConfigSuccessResponse(saveConfigResponse: ThreeKitConfigResponse, isNewConfig: boolean) {
    if (isNewConfig) {
      // create new config
      const saveProjectBody: ConfigSaveObj = this.threeKitMappingService.createSaveProjectBody(saveConfigResponse);
      const projectId = saveConfigResponse.metadata.configData.projectId;
      this.addNewConfiguration(saveProjectBody, projectId);
    } else {
      // update existing config
      const saveProjectBody: ConfigSaveObj = this.threeKitMappingService.createSaveProjectBody(saveConfigResponse);
      const projectId = saveConfigResponse.metadata.configData.projectId;
      const configId = saveConfigResponse.id;
      this.updateConfigurtaion(saveProjectBody, projectId, configId);
    }
  }

  addNewConfiguration(reqObject, projectId) {
    this.showsaveprogress = true;
    this.sloanCpqConfiguratorService.saveConfigurator(reqObject, projectId).subscribe(
      (res) => {
        this.showsaveprogress = false;
        this.spinner.hide();
        this.ref.markForCheck();
        this.globalMessageService.add(
          { key: 'configurators.configuratorSave.savedSuccessfully' },
          GlobalMessageType.MSG_TYPE_CONFIRMATION
        );
        if (this.winRef.sessionStorage.getItem(PREVIOUS_URL)) {
          this.routingService.goByUrl(this.winRef.sessionStorage.getItem(PREVIOUS_URL));
        }
      },
      (err) => {
        this.showsaveprogress = false;
        this.spinner.hide();
        this.ref.markForCheck();
        this.globalMessageService.add(
          { key: 'configurators.configuratorSave.saveErrorMessage' },
          GlobalMessageType.MSG_TYPE_ERROR
        );
      }
    );
  }

  updateConfigurtaion(reqObject, projectId, configId) {
    const req: UpdateConfiguration = {
      configurations: [reqObject],
    };
    this.showupdateProgress = true;
    this.sloanCpqConfiguratorService.updateConfigurations(req, projectId, configId).subscribe(
      (res) => {
        this.showupdateProgress = false;
        this.spinner.hide();
        this.ref.markForCheck();
        this.globalMessageService.add(
          { key: 'configurators.configuratorSave.updatedSuccessfully' },
          GlobalMessageType.MSG_TYPE_CONFIRMATION
        );
        if (this.winRef.sessionStorage.getItem(PREVIOUS_URL)) {
          this.routingService.goByUrl(this.winRef.sessionStorage.getItem(PREVIOUS_URL));
        }
      },
      (err) => {
        this.showupdateProgress = false;
        this.spinner.hide();
        this.ref.markForCheck();
        this.globalMessageService.add(
          { key: 'configurators.configuratorSave.updateErrorMessage' },
          GlobalMessageType.MSG_TYPE_ERROR
        );
      }
    );
  }

  processSaveConfigFailResponse(saveConfigFailResponse: ThreeKitConfigFailResponse, isNewConfig: boolean) {
    this.showupdateProgress = false;
    this.showsaveprogress = false;
    this.spinner.hide();
    console.log('isNewConfig: ', isNewConfig);
    console.log('falied ' + saveConfigFailResponse);
    if (isNewConfig) {
      this.globalMessageService.add(
        { key: 'configurators.configuratorSave.saveErrorMessage' },
        GlobalMessageType.MSG_TYPE_ERROR
      );
    } else {
      this.globalMessageService.add(
        { key: 'configurators.configuratorSave.updateErrorMessage' },
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  onremovelistener() {
    console.log('removed');
  }

  openLoginModal() {
    this.logInModalRef = this.modalService.open(SloanLoginComponent);
    this.sloanCpqUsersService.setGlobalMessage(false);
    this.logInModalRef.result
      .then((res) => {
        this.sloanCpqUsersService.setGlobalMessage(true);
      })
      .catch(() => {
        this.sloanCpqUsersService.setGlobalMessage(true);
      })
      .finally(() => {
        this.sloanCpqUsersService.setGlobalMessage(true);
      });
  }

  openSignUpModal() {
    this.signUpModalRef = this.modalService.open(SloanSignupComponent);
    this.sloanCpqUsersService.setGlobalMessage(false);
    this.signUpModalRef.result
      .then((res) => {
        this.sloanCpqUsersService.setGlobalMessage(true);
      })
      .catch(() => {
        this.sloanCpqUsersService.setGlobalMessage(true);
      })
      .finally(() => {
        this.sloanCpqUsersService.setGlobalMessage(true);
      });
  }

  ngOnDestroy() {
    console.log('on destroy');
    // window.removeEventListener('message', this.onremovelistener.bind(this), true);
    // window.removeEventListener('message', this.onremovelistener.bind(this), false);
    this.subscription.unsubscribe();
    if (this.winRef.sessionStorage.getItem(PREVIOUS_URL)) {
      this.winRef.sessionStorage.removeItem(PREVIOUS_URL);
    }
    this.sloanCpqThreeKitService.dataForConfigUpdate$.next(null);
  }
}
