import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { USER_DATA_KEY } from 'src/app/constants/storage.constant';
import {
  CREATE_CONFIG_URL,
  DELETE_CONFIG_URL,
  DUPLICATE_CONFIG_URL,
  GET_THREE_KIT_CONFIGS_URL,
  GET_THREE_KIT_CONFIG_URL,
} from 'src/app/constants/threekit.constant';
import { SloanUser } from 'src/app/models/sloan-user';
import { ThreeKitConfigObj, ThreeKitConfigResponse } from 'src/app/models/threekit.model';

@Injectable({
  providedIn: 'root',
})
export class SloanCpqThreeKitService {
  threeKitConfigs$: BehaviorSubject<ThreeKitConfigObj[]> = new BehaviorSubject(null);
  isConfigUpdate$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dataForConfigUpdate$: BehaviorSubject<any> = new BehaviorSubject(null);
  customeId: string;
  constructor(private http: HttpClient, private spinner: NgxSpinnerService, private winRef: WindowRef) {
    const userdata = this.winRef.sessionStorage.getItem(USER_DATA_KEY);
    if (userdata) {
      const userdataJson: SloanUser = JSON.parse(userdata);
      this.customeId = userdataJson.customerId;
    }
  }

  createConfig(reqObject) {
    return this.http.post(CREATE_CONFIG_URL(reqObject.udid), reqObject);
  }

  getConfigurators(proectId?: string) {
    // this.spinner.show();
    this.customeId = this.customeId ? this.customeId : this.getCustomerId();
    let url = GET_THREE_KIT_CONFIGS_URL + '&metadata.customerId=' + this.customeId;
    if (proectId) {
      url = url + '&metadata.configData.projectId=' + proectId;
    }
    this.http.get(url).subscribe(
      (res: any) => {
        if (res && res.code) {
          this.threeKitConfigs$.next([]);
          console.error(res.message);
        } else {
          this.threeKitConfigs$.next(res);
        }
        // this.spinner.hide();
      },
      (err) => {
        console.log(err);
        //  this.spinner.hide();
      }
    );
  }

  getCustomerId(): string {
    const userdata = this.winRef.sessionStorage.getItem(USER_DATA_KEY);
    if (userdata) {
      const userdataJson: SloanUser = JSON.parse(userdata);
      this.customeId = userdataJson.customerId;
    }
    return this.customeId;
  }

  getConfig() {
    this.http.get(GET_THREE_KIT_CONFIG_URL).subscribe(
      (res: ThreeKitConfigObj[]) => {
        //  console.log(res);
      },
      (err) => {
        // console.log(err);
      }
    );
  }

  loadThreeKitConfigs(): Observable<ThreeKitConfigObj[]> {
    return this.threeKitConfigs$.asObservable();
  }

  isThreeKitConfigUpdate(): Observable<boolean> {
    return this.isConfigUpdate$.asObservable();
  }

  loadDataForConfigUpdate(): Observable<any> {
    return this.dataForConfigUpdate$.asObservable();
  }

  duplicateConfig(udid, configobj?): Observable<ThreeKitConfigResponse> {
    return this.http.post<ThreeKitConfigResponse>(DUPLICATE_CONFIG_URL(udid), configobj);
  }

  deleteConfig(udidlist): Observable<ThreeKitConfigResponse> {
    return this.http.post<ThreeKitConfigResponse>(DELETE_CONFIG_URL, udidlist);
  }
}
