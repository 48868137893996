import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SloanCpqConfiguratorService } from 'src/app/common/services/sloan-cpq-configurator.service';
import { SloanCpqProjectService } from 'src/app/common/services/sloan-cpq-project.service';
import { Project, Projects } from 'src/app/models/projects.model';

@Component({
  selector: 'app-sloan-save-project-configuration',
  templateUrl: './sloan-save-project-configuration.component.html',
  styleUrls: ['./sloan-save-project-configuration.component.scss'],
})
export class SloanSaveProjectConfigurationComponent implements OnInit, AfterContentChecked {
  saveConfigModalRef: NgbModalRef;
  newProjectModalRef: NgbModalRef;
  saveConfigForm: UntypedFormGroup;
  firstPage = true;
  existingProjects: any = [];
  existProjectList: Observable<Projects> = this.sloanCpqProjectService.existingLoadProjects();
  projectId;
  showErrorMessage = false;
  projectData: Project;
  disableSubmitBtn = false;
  isDuplicate = false;
  existingConfigMsg = '';
  constructor(
    private fb: UntypedFormBuilder,
    private ngbActiveModal: NgbActiveModal,
    private sloanCpqProjectService: SloanCpqProjectService,
    private sloanCpqConfiguratorService: SloanCpqConfiguratorService,
    private globalMessageService: GlobalMessageService
  ) {}

  ngOnInit(): void {
    // console.log('projectid ' + this.projectId);
    // console.log('projectdata ' + this.projectData);
    this.saveConfigForm = this.fb.group({
      configurationName: ['', [Validators.required]],
      projectType:
        this.projectId === undefined
          ? new UntypedFormControl({ value: 'newProject', disabled: false })
          : new UntypedFormControl({ value: 'existingProject', disabled: true }),
      selectedProject:
        this.projectId === undefined
          ? new UntypedFormControl({ value: null, disabled: false })
          : new UntypedFormControl({ value: this.projectId, disabled: true }),
    });
    this.sloanCpqProjectService.getExistingProjects();
    // console.log("after init " + this.saveConfigForm.value.selectedProject);
  }

  ngAfterContentChecked(): void {
    if (this.projectId) {
      this.saveConfigForm?.get('selectedProject').setValue(this.projectId);
    }
  }

  next() {
    if (this.saveConfigForm.value.projectType === 'newProject') {
      this.closeModal();
      this.disableSubmitBtn = true;
    } else {
      this.firstPage = !this.firstPage;
    }
  }

  save() {
    let projectDetails;

    if (this.isDuplicate) {
      this.sloanCpqConfiguratorService.getConfigurators(this.projectId);
      this.sloanCpqConfiguratorService.loadConfigurators().subscribe((res) => {
        if (res) {
          projectDetails = res.savedCartData;
        }
      });
    } else {
      projectDetails = this.projectData;
    }

    if (this.saveConfigForm.value.projectType === 'newProject') {
      this.closeModal();
    } else {
      const configNames = projectDetails.entries.map((config) => config.configurationName);
      // console.log('names : ', configNames);

      const existingConfigName = projectDetails.entries
        .filter((config) => config.configurationName === this.saveConfigForm.value.configurationName)
        .map((config) => config.configurationName);

      if (existingConfigName.length > 0) {
        this.existingConfigMsg =
          'Configuration Name ' + existingConfigName + ' already exist. Please rename your configuration.';
      } else {
        this.closeModal();
      }
    }
  }

  closeModal() {
    const formData: any = {};
    formData.configName = this.saveConfigForm.value.configurationName;
    formData.projectData = this.projectData;
    this.ngbActiveModal.close(formData);
  }

  dismissModal() {
    this.ngbActiveModal.dismiss();
  }

  addNewConfiguration(reqObject) {
    this.sloanCpqConfiguratorService.saveConfigurator(reqObject, this.projectId).subscribe(
      (res) => {
        this.globalMessageService.add(
          { key: 'configurators.configuratorSave.savedSuccessfully' },
          GlobalMessageType.MSG_TYPE_CONFIRMATION
        );
        this.disableSubmitBtn = true;
        this.closeModal();
        this.sloanCpqProjectService.getProjects();
        this.sloanCpqConfiguratorService.getConfigurators(this.projectId);
      },
      (err) => {
        // console.log(err);
        this.showErrorMessage = true;
        this.removeAlert();
      }
    );
  }

  selectProjectEvent(event) {
    // console.log(event);
    this.existProjectList
      .pipe(map((projects) => projects.carts.find((project) => project.code === event.target.value)))
      .subscribe((res) => {
        this.projectData = res;
      });
  }

  removeAlert() {
    setTimeout(() => {
      this.showErrorMessage = false;
    }, 3000);
  }
}
