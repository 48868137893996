<!-- <p>sloan-cpq-faq-projects works!</p>
<ng-container *ngIf="componentData$ | async as data">
    {{data.name}}
</ng-container> -->
<ng-container *ngIf="node$ | async as node">
    <!-- {{node.children[0].children| json}} -->
    <app-sloan-cms-faq
    [questions]="node"
    ></app-sloan-cms-faq>
    
      <!-- <div *ngFor="let question of node.children[0].children; let i = index">
        <div class="accordion-item">
            <button
              [id]="'heading' + i"
              class="accordion-button collapsed accordion-header"
              type="button"
              data-bs-toggle="collapse"
              aria-expanded="false"
              [aria-controls]="'collapse' + i"
              [attr.data-bs-target]="'#collapse' + i"
              (click)="test()"
            >
              <b>{{ question.question }}</b>
            </button>
       
          <div
            [id]="'collapse' + i"
            class="accordion-collapse collapse"
            [aria-labelledby]="'heading' + i"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              {{ question.answer }}
            </div>
          </div>
        </div>
      </div> -->
   
</ng-container>
