import { SloanMyAccountComponent } from './sloan-my-account.component';
import { ConfigModule, CmsConfig } from '@spartacus/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule } from '@spartacus/core';


@NgModule({
  declarations: [SloanMyAccountComponent],
  imports: [
    CommonModule,
    NgbModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CPQMyAccountComponent: {
          component: SloanMyAccountComponent
        }
      }
    } as CmsConfig),
  ]
})
export class SloanMyAccountModule { }
