import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  GlobalMessageService,
  GlobalMessageType,
  InterceptorUtil,
  LoggerService,
  OCC_USER_ID_CURRENT,
  OccEndpointsService,
  USE_CLIENT_TOKEN,
  WindowRef,
  normalizeHttpError,
} from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  SLOAN_CPQ_FORGOT_PASSWORD_REQ,
  SLOAN_CPQ_ROLES,
  SLOAN_CPQ_SIGNUP,
  SLOAN_CPQ_TITLES,
  SLOAN_CPQ_USER_DETAIL,
  SLOAN_CPQ_USER_UPDATE,
} from 'src/app/constants/api.endpoint.constant';
import { USER_DATA_KEY } from 'src/app/constants/storage.constant';
import { SloanUser } from 'src/app/models/sloan-user';

@Injectable({
  providedIn: 'root',
})
export class SloanCpqUserService {
  protected logger = inject(LoggerService);
  userInfo$: BehaviorSubject<SloanUser> = new BehaviorSubject(null);
  showGlobalMessage$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  constructor(
    private http: HttpClient,
    private endPointService: OccEndpointsService,
    private winRef: WindowRef,
    private globalMessageService: GlobalMessageService
  ) {}

  getTitles() {
    return this.http.get(this.endPointService.buildUrl(SLOAN_CPQ_TITLES));
  }

  getRoles() {
    return this.http.get(this.endPointService.buildUrl(SLOAN_CPQ_ROLES));
  }

  // tslint:disable-next-line: typedef
  register(userData) {
    return this.http.post(this.endPointService.buildUrl(SLOAN_CPQ_SIGNUP), userData);
  }

  updateProfile(userData) {
    return this.http.patch(
      this.endPointService.buildUrl(SLOAN_CPQ_USER_UPDATE, {
        urlParams: {
          userId: userData.email,
        },
      }),
      userData
    );
  }

  forgotPasswordRequest(userEmailAddress) {
    const httpParams: HttpParams = new HttpParams().set('userId', userEmailAddress);
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    this.http.post(this.endPointService.buildUrl(SLOAN_CPQ_FORGOT_PASSWORD_REQ), httpParams, { headers }).subscribe(
      (res) => {
        this.globalMessageService.add(
          { key: 'forgottenPassword.passwordResetEmailSent' },
          GlobalMessageType.MSG_TYPE_CONFIRMATION
        );
      },
      (error) => {
        const errordetail = normalizeHttpError(error, this.logger);
        if (errordetail.details) {
          this.globalMessageService.add({ raw: errordetail.details[0].message }, GlobalMessageType.MSG_TYPE_ERROR);
        } else {
          this.globalMessageService.add({ key: 'user.errorMsg' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      }
    );
  }

  getCPQUserData() {
    this.http
      .get<SloanUser>(
        this.endPointService.buildUrl(SLOAN_CPQ_USER_DETAIL, {
          urlParams: {
            userId: OCC_USER_ID_CURRENT,
          },
        })
      )
      .subscribe((res) => {
        this.winRef.sessionStorage.setItem(USER_DATA_KEY, JSON.stringify(res));
        this.userInfo$.next(res);
      });
  }

  loadUserData(): Observable<SloanUser> {
    return this.userInfo$.asObservable();
  }

  setGlobalMessage(show) {
    this.showGlobalMessage$.next(show);
  }

  canShowGlobalMessage(): Observable<boolean> {
    return this.showGlobalMessage$.asObservable();
  }
}
