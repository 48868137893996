// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseUrl: 'https://api.sloan.com',
  prefix: '/occ/v2/',
  baseSite: ['sloancpq'],
  ORIGIN: '*.sloan.com:443',
  threeKitToken: 'fc9e37a9-3a01-44dd-862a-7d526678f4ae',
  threeKitUrl: 'https://sloan-production.herokuapp.com',
  templateId: '101',
  globalHelpphoneNumber: '800.982.5839',
  globalHelpemail: 'designersinks@sloan.com',
  globalHelpworkingDays: 'Monday through Friday',
  globalHelpworkingHours: '7:00AM - 5:00PM(Central Time)',
  gtmId: 'GTM-TS6V67S',
  recaptchaSiteKey: '6LcKCgspAAAAAC6pu8xR-mQYgwueKUflsok3SIhX',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
