import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanTopHeaderComponent } from './sloan-top-header.component';



@NgModule({
  declarations: [SloanTopHeaderComponent],
  imports: [
    CommonModule,
    NgbModule,
    // ConfigModule.withConfig({
    //   cmsComponents: {
    //     NavigationComponent: {
    //       component: SloanTopHeaderComponent
    //     }
    //   }
    // } as CmsConfig),
  ]
})
export class SloanTopHeaderModule { }
