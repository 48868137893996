import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EventService, GlobalMessageService, GlobalMessageType, RoutingService, createFrom } from '@spartacus/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SloanCpqProjectService } from 'src/app/common/services/sloan-cpq-project.service';
import { SloanCpqThreeKitService } from 'src/app/common/services/sloan-cpq-three-kit.service';
import { SloanCpqUserService } from 'src/app/common/services/sloan-cpq-user.service';
import { ThreekitMappingService } from 'src/app/common/services/threekit-mapping.service';
import { SloanCpqConfiguratorsSaveComponent } from 'src/app/cpq-libs/sloan-cpq-configurators/sloan-cpq-configurators-save/sloan-cpq-configurators-save.component';
import { Project, Projects } from 'src/app/models/projects.model';
import { ThreeKitConfigObj } from 'src/app/models/threekit.model';
import { SloanCpqOnSearchEvent } from 'src/app/store/custom-spartacus-events';
import { environment } from 'src/environments/environment';
import { SloanSaveProjectConfigurationComponent } from './../sloan-save-project-configuration/sloan-save-project-configuration.component';

@Component({
  selector: 'app-sloan-cpq-projects-list',
  templateUrl: './sloan-cpq-projects-list.component.html',
  styleUrls: ['./sloan-cpq-projects-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanCpqProjectsListComponent implements OnInit {
  projectList$: Observable<Projects> = this.sloanCpqProjectService.loadProjects();
  lastPage$ = this.sloanCpqProjectService.lastPage$;
  projectSearchText: string;
  isLoading$: Observable<boolean> = this.sloanCpqProjectService.isProjectsLoading$();
  savemodalRef: NgbModalRef;
  output$: Observable<Project[]>;
  private readonly unsubscribe$ = new Subject<void>();
  customerId: string;
  configuratorListFromThreeKit$: Observable<ThreeKitConfigObj[]> = this.sloanCpqThreeKitService.loadThreeKitConfigs();
  customeId: string;
  newProjectModalRef: NgbModalRef;
  showOnlyArchived = false;
  selectedSortType;
  currentPage = 0;
  constructor(
    public routingService: RoutingService,
    private sloanCpqProjectService: SloanCpqProjectService,
    private modalService: NgbModal,
    private sloanCpqThreeKitService: SloanCpqThreeKitService,
    private sloanCpqUsersService: SloanCpqUserService,
    private threeKitMappingService: ThreekitMappingService,
    private messageService: GlobalMessageService,
    private cdr: ChangeDetectorRef,
    private events: EventService
  ) {
    this.sloanCpqUsersService.getCPQUserData();
  }

  ngOnInit(): void {
    this.sloanCpqProjectService.getProjects(this.currentPage);
    this.sloanCpqThreeKitService.getConfigurators();
    const combined$ = combineLatest([this.projectList$, this.configuratorListFromThreeKit$]);
    this.output$ = combined$.pipe(
      takeUntil(this.unsubscribe$),
      map(([projects, configs]) => {
        if (projects && configs) {
          return projects.carts.map((project) => {
            return {
              ...project,
              threeKitData:
                configs.length > 0
                  ? configs.filter((config) => config.metadata.configData.projectId === project.code)
                  : [],
              anyConfigExpired:
                project && project.entries && project.entries.length > 0
                  ? project.entries.some((config) => config.status === 'EXPIRED')
                  : false,
            };
          });
        }
      })
    );
    this.cdr.markForCheck();
    // this.output$.subscribe(res => {
    //   console.log(res);
    // });
  }
  onClikMore() {
    this.currentPage = this.currentPage + 1;
    this.sloanCpqProjectService.getProjects(this.currentPage);
  }

  sloanCPQOnClickEvent(searchTerm): void {
    this.events.dispatch(createFrom(SloanCpqOnSearchEvent, { searchTerm: searchTerm }));
  }

  prepareReqObjectForThreeKitConfigList() {
    const reqObj = {
      bearer_token: environment.threeKitToken,
      customerId: this.customerId,
    };
    return reqObj;
  }

  sortByArchived() {
    this.selectedSortType = 'Archived';
    this.showOnlyArchived = true;
    const statuses = {
      CREATED: 2,
      ARCHIVED: 1,
    };
    this.output$ = this.output$.pipe(
      map((data) => {
        data.sort((a, b) => {
          return statuses[a.projectStatus] - statuses[b.projectStatus];
        });
        return data;
      })
    );
  }

  sortArchived() {
    const statuses = {
      CREATED: 1,
      ARCHIVED: 2,
    };
    this.output$ = this.output$.pipe(
      map((data) => {
        data.sort((a, b) => {
          return statuses[a.projectStatus] - statuses[b.projectStatus];
        });
        return data;
      })
    );
  }

  sortAtoZ() {
    this.selectedSortType = 'A - Z';
    this.showOnlyArchived = false;
    this.output$ = this.output$.pipe(
      map((data) => {
        data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        return data;
      })
    );
    this.sortArchived();
  }

  sortByCreationDate() {
    this.selectedSortType = 'Recently Created';
    this.showOnlyArchived = false;
    this.output$ = this.output$.pipe(
      map((data) => {
        data.sort((a, b) => {
          return new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime();
        });
        return data;
      })
    );
    this.sortArchived();
  }

  sortByLastModified() {
    this.selectedSortType = 'Last Modified';
    this.showOnlyArchived = false;
    this.output$ = this.output$.pipe(
      map((data) => {
        data.sort((a, b) => {
          return new Date(b.modifiedTime).getTime() - new Date(a.modifiedTime).getTime();
        });
        return data;
      })
    );
    this.sortArchived();
  }

  openNewProjectModal() {
    let modalInstance: any;
    this.newProjectModalRef = this.modalService.open(SloanCpqConfiguratorsSaveComponent);
    modalInstance = this.newProjectModalRef.componentInstance;
    modalInstance.showConfigName = false;

    this.newProjectModalRef.result
      .then((res) => {
        const reqObjForSaveconfig = this.threeKitMappingService.prepareSaveProjectBodyAndSave(res);
        this.addNewProject(reqObjForSaveconfig);
      })
      .catch(() => {});
  }

  addNewProject(saveProjectBody) {
    this.currentPage = 0;
    this.sloanCpqProjectService.saveProject(saveProjectBody).subscribe(
      (res: any) => {
        this.messageService.add(
          { key: 'configurators.configuratorSave.savedSuccessfully' },
          GlobalMessageType.MSG_TYPE_CONFIRMATION
        );
        this.sloanCpqProjectService.getProjects(this.currentPage);
      },
      (err) => {
        this.messageService.add({ key: 'configurators.configuratorSave.errorMsg' }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    );
  }

  // addNewProject() {
  //   this.routingService.goByUrl('');
  // }

  saveProject() {
    this.savemodalRef = this.modalService.open(SloanSaveProjectConfigurationComponent);
    // this.savemodalRef = this.modalService.open(SloanCpqConfiguratorsSaveComponent);
  }

  isArchived(project): boolean {
    if (this.showOnlyArchived) {
      if (project && project.projectStatus === 'ARCHIVED') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
