import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoutingService, WindowRef } from '@spartacus/core';
import { environment } from 'src/environments/environment';
import { USER_DATA_KEY } from '../../constants/storage.constant';
import { SloanUser } from '../../models/sloan-user';

@Component({
  selector: 'app-sloan-sink-config-help',
  templateUrl: './sloan-sink-config-help.component.html',
  styleUrls: ['./sloan-sink-config-help.component.scss']
})
export class SloanSinkConfigHelpComponent implements OnInit {

  phoneNumber  = environment.globalHelpphoneNumber;
  email = environment.globalHelpemail;
  workingDays = environment.globalHelpworkingDays;
  workingHours = environment.globalHelpworkingHours;
  userdataJson: SloanUser;
  constructor(
    private ngbActiveModal: NgbActiveModal,
    private routingService: RoutingService,
    private winRef: WindowRef
  ) { }

  ngOnInit(): void {
    const userdata = this.winRef.sessionStorage.getItem( USER_DATA_KEY);
    if (userdata) {
      this.userdataJson = JSON.parse(userdata);
    }
  }

  closeModal(){
    this.ngbActiveModal.dismiss();
  }

  openFaqs(){
    this.closeModal();
    this.routingService.goByUrl('/faq');
  }

}
