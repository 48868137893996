import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RoutingService, WindowRef } from '@spartacus/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SloanCpqConfiguratorService } from 'src/app/common/services/sloan-cpq-configurator.service';
import { SloanCpqThreeKitService } from 'src/app/common/services/sloan-cpq-three-kit.service';
import { ThreekitMappingService } from 'src/app/common/services/threekit-mapping.service';
import { PREVIOUS_URL } from 'src/app/constants/storage.constant';
import {
  ConfigSaveObj,
  Configurator,
  Entry2,
  SavedCartData,
  UpdateConfiguration,
} from 'src/app/models/configurator.model';
import { ThreeKitConfigObj } from 'src/app/models/threekit.model';
import { environment } from 'src/environments/environment';
import { SloanDownloadAndReqDocumentsComponent } from '../sloan-download-and-req-documents/sloan-download-and-req-documents.component';
import { SloanSaveProjectConfigurationComponent } from 'src/feature-libs/cpq-project/components/sloan-save-project-configuration/sloan-save-project-configuration.component';

@Component({
  selector: 'app-sloan-cpq-configurators-list',
  templateUrl: './sloan-cpq-configurators-list.component.html',
  styleUrls: ['./sloan-cpq-configurators-list.component.scss'],
})
export class SloanCpqConfiguratorsListComponent implements OnInit, OnDestroy {
  savemodalRef: NgbModalRef;
  downloadModalRef: NgbModalRef;
  userCheckedProjects;
  selectedIds: any[] = [];
  configuratorList$: Observable<Configurator> = this.sloanCpqConfiguratorService.loadConfigurators();
  openDownloadDocumentModalRef;
  subscription = new Subscription();
  projectCode;
  selectedConfigurators: string[] = [];
  allChecked = false;
  configSearchText: string;
  isLoading$: Observable<boolean> = this.sloanCpqConfiguratorService.isConfigsLoading$();
  selectedConfigs: Observable<string[]> = this.sloanCpqConfiguratorService.loadSelectedConfigurators$();
  projectDetail: SavedCartData;
  configuratorListFromThreeKit$: Observable<ThreeKitConfigObj[]> = this.sloanCpqThreeKitService.loadThreeKitConfigs();
  output$: Observable<Entry2[]>;
  customerId: string;
  updatedConfigList: ConfigSaveObj[] = [];
  selectedConfigurationSortType;
  showOnlyLocked = false;
  constructor(
    private modalService: NgbModal,
    private sloanCpqConfiguratorService: SloanCpqConfiguratorService,
    private routingService: RoutingService,
    private ref: ChangeDetectorRef,
    private winRef: WindowRef,
    private sloanCpqThreeKitService: SloanCpqThreeKitService,
    private threeKitMappingService: ThreekitMappingService
  ) {
    this.subscription.add(
      this.routingService.getRouterState().subscribe((state) => {
        if (state.state.params.code) {
          this.projectCode = state.state.params.code;
          this.sloanCpqConfiguratorService.getConfigurators(this.projectCode);
          this.sloanCpqThreeKitService.getConfigurators(this.projectCode);
        }
      })
    );
    const combined$ = combineLatest([this.configuratorList$, this.configuratorListFromThreeKit$]);
    this.output$ = combined$.pipe(
      map(([sloanConfigdata, threeKitConfigs]) => {
        if (sloanConfigdata && threeKitConfigs) {
          return sloanConfigdata.savedCartData.entries.map((entry) => {
            return {
              ...entry,
              threeKitData:
                threeKitConfigs.length > 0
                  ? threeKitConfigs.filter((threekitCOnfig) => threekitCOnfig.id === entry.configurationUid)
                  : [],
            };
          });
        }
      })
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.sloanCpqConfiguratorService.loadSelectedConfigurators$().subscribe((selectedProductCodes) => {
        if (selectedProductCodes) {
          this.selectedConfigurators = selectedProductCodes;
          let configurationUids = [];
          this.configuratorList$.subscribe((configurator) => {
            if (configurator) {
              this.projectDetail = configurator.savedCartData;
              configurationUids = configurator.savedCartData.entries.map((entry) => entry.configurationUid);
              this.allChecked =
                configurationUids &&
                configurationUids.length &&
                configurationUids.length === this.selectedConfigurators.length;
            }
          });
          this.ref.markForCheck();
        }
      })
    );
    this.subscription.add(
      this.configuratorList$.subscribe((configurator) => {
        if (configurator) {
          this.projectDetail = configurator.savedCartData;
        }
      })
    );
    this.updateConfigurations();
    this.ref.markForCheck();
  }

  updateConfigurations() {
    this.subscription.add(
      this.configuratorListFromThreeKit$.subscribe((threekitConfigs) => {
        if (threekitConfigs && threekitConfigs.length > 0) {
          // console.log(threekitConfigs);
          threekitConfigs.forEach((threeKitConfig) => {
            this.updatedConfigList.push(this.threeKitMappingService.createSaveProjectBody(threeKitConfig));
          });
          if (this.updatedConfigList && this.updatedConfigList.length > 0) {
            const req: UpdateConfiguration = {
              configurations: this.updatedConfigList,
            };
            this.sloanCpqConfiguratorService
              .updateConfigurations(req, this.projectCode, this.updatedConfigList[0].configurationUid)
              .subscribe((res) => {
                // console.log(res);
              });
          }
        }
      })
    );
  }

  prepareReqObjectForThreeKitConfigList() {
    const reqObj = {
      bearer_token: environment.threeKitToken,
      customerId: this.customerId,
      'metadata.configData.projectId': this.projectCode,
    };
    return reqObj;
  }

  sortByAtoZ() {
    this.selectedConfigurationSortType = 'A - Z';
    this.showOnlyLocked = false;
    this.output$ = this.output$.pipe(
      map((data) => {
        data.sort((a, b) => {
          return a.configurationName.localeCompare(b.configurationName);
        });
        return data;
      })
    );
  }

  sortByLocked() {
    this.selectedConfigurationSortType = 'Locked';
    this.showOnlyLocked = true;
    const statuses = {
      APPROVED_LOCKED: 1,
      EXPIRED: 2,
      CREATED: 3,
      DOCUMENTREQUESTED: 4,
    };
    this.output$ = this.output$.pipe(
      map((data) => {
        data.sort((a, b) => {
          return statuses[a.status] - statuses[b.status];
        });
        return data;
      })
    );
  }

  sortByCreationDate() {
    this.selectedConfigurationSortType = 'Recently Created';
    this.showOnlyLocked = false;
    this.output$ = this.output$.pipe(
      map((data) => {
        data.sort((a, b) => {
          return new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime();
        });
        return data;
      })
    );
  }

  sortByLastModified() {
    this.selectedConfigurationSortType = 'Last Modified';
    this.showOnlyLocked = false;
    this.output$ = this.output$.pipe(
      map((data) => {
        data.sort((a, b) => {
          return new Date(b.modifiedTime).getTime() - new Date(a.modifiedTime).getTime();
        });
        return data;
      })
    );
  }

  saveConfiguration() {
    let modalInstance;
    this.savemodalRef = this.modalService.open(SloanSaveProjectConfigurationComponent);
    modalInstance = this.savemodalRef.componentInstance;
    modalInstance.projectId = this.projectCode;
    // this.savemodalRef = this.modalService.open(SloanCpqConfiguratorsSaveComponent);
  }

  createConfiguration() {
    this.winRef.sessionStorage.setItem(PREVIOUS_URL, 'Configurations/' + this.projectCode);
    this.routingService.goByUrl('');
  }

  openDownloadDocunments() {
    let modalInstance;
    this.openDownloadDocumentModalRef = this.modalService.open(SloanDownloadAndReqDocumentsComponent);
    modalInstance = this.openDownloadDocumentModalRef.componentInstance;
    modalInstance.projectDetail = this.selectedIds;
    modalInstance.projectId = this.projectCode;
    modalInstance.projectName = this.projectDetail.name;
  }

  onSelectConfigurator(event) {
    console.log(event);
  }

  selectAll(event) {
    let configurationUids = [];
    if (event.currentTarget.checked) {
      this.configuratorList$
        .subscribe((configurator) => {
          if (configurator) {
            configurationUids = configurator.savedCartData.entries.map((entry) => entry.configurationUid);
          }
        })
        .unsubscribe();
    }
    this.sloanCpqConfiguratorService.setSelectedConfigurators(configurationUids);
  }

  editConfig(configurator) {
    // uuid comes here
    // send project details
    // console.log('event : ' + configurator);

    const data = {
      configurator,
      projectData: this.projectDetail,
    };
    this.sloanCpqThreeKitService.dataForConfigUpdate$.next(data);
    this.winRef.sessionStorage.setItem(PREVIOUS_URL, 'Configurations/' + this.projectCode);
    // console.log('go to home');
    this.routingService.goByUrl('');
  }

  goToProjects() {
    // const data = {
    //   configurator: null,
    //   projectData : this.projectDetail
    //   };
    // this.sloanCpqThreeKitService.dataForConfigUpdate$.next(data);
    // TODO:Spartacus 4.3- 'RoutingService.go' changed signature. Before 4.0, the object with query params could be passed in the 2nd argument. Now the 2nd argument is Angular NavigationExtras object (with 'queryParams' property).
    this.routingService.go({ cxRoute: 'projectslist' });
  }

  ngOnDestroy() {
    this.sloanCpqConfiguratorService.setSelectedConfigurators([]);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  isLocked(config): boolean {
    if (this.showOnlyLocked) {
      if (config && config.status === 'APPROVED_LOCKED') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
