import { Injectable } from '@angular/core';
import { SLOAN_CPQ_USER_KEY } from 'src/app/constants/storage.constant';
import { ConfigSaveObj } from 'src/app/models/configurator.model';
import { SloanUser } from 'src/app/models/sloan-user';
import {
  ProjectDetails,
  ProjectLocation,
  ThreeKitConfigData,
  ThreeKitConfigObj,
  ThreeKitConfigResponse,
  ThreeKitUserData,
} from 'src/app/models/threekit.model';

@Injectable({
  providedIn: 'root',
})
export class ThreekitMappingService {
  constructor() {}

  createSaveProjectBody(saveConfigResponse: ThreeKitConfigResponse | ThreeKitConfigObj) {
    const saveProjectBody: ConfigSaveObj = {} as ConfigSaveObj;

    saveProjectBody.configPrice = '' + saveConfigResponse.metadata.price;
    saveProjectBody.configurationName = saveConfigResponse.metadata.configData.configName;
    saveProjectBody.configurationUid = saveConfigResponse.id;
    saveProjectBody.fixtureNotes = saveConfigResponse.metadata.configNote.faucetAndSoap.faucetAndSoapHoles;
    saveProjectBody.mountingNotes = saveConfigResponse.metadata.configNote.sink.mountingType;
    saveProjectBody.notes = saveConfigResponse.metadata.configData.notes;
    saveProjectBody.productCode = saveConfigResponse.metadata.productId;
    saveProjectBody.sinkFeatureNotes = saveConfigResponse.metadata.configNote.sink.optionalSinkFeatures;
    saveProjectBody.soapDispenserNotes = saveConfigResponse.metadata.configNote.faucetAndSoap.soapDispenser;
    saveProjectBody.fullRenderingUrl = saveConfigResponse.metadata.rendering;
    saveProjectBody.quoteDocUrl = saveConfigResponse.metadata.quoteDocument;
    saveProjectBody.configurationUrl = saveConfigResponse.metadata.configUrl;
    saveProjectBody.csvOutput = saveConfigResponse.metadata.csvOutput;
    saveProjectBody.leadId = saveConfigResponse.metadata.configData.ZZ_LEAD_ID
      ? saveConfigResponse.metadata.configData.ZZ_LEAD_ID
      : '';
    saveProjectBody.opportunityId = saveConfigResponse.metadata.configData.ZZ_OPPORTUNITY_ID
      ? saveConfigResponse.metadata.configData.ZZ_OPPORTUNITY_ID
      : '';
    return saveProjectBody;
  }

  prepareUserData(loginUser: SloanUser): ThreeKitUserData {
    const userData: ThreeKitUserData = {} as ThreeKitUserData;
    if (loginUser) {
      userData.firstName = loginUser.firstName;
      userData.lastName = loginUser.lastName;
      let email = loginUser.uid;
      if (loginUser.uid.includes(SLOAN_CPQ_USER_KEY)) {
        email = loginUser.uid.split(SLOAN_CPQ_USER_KEY)[1];
      }
      userData.emailAddress = email;
      userData.title = loginUser.title;
      userData.titleCode = loginUser.titleCode;
      userData.role = loginUser?.userRole?.roleName;
      userData.companyName = loginUser.defaultAddress?.companyName;
      userData.companyPhone = loginUser.defaultAddress?.phone;
      userData.city = loginUser.defaultAddress?.town;
      userData.state = loginUser.defaultAddress?.region ? loginUser.defaultAddress?.region.name : '';
      userData.stateCode = loginUser.defaultAddress?.region ? loginUser.defaultAddress?.region?.isocode : '';
      userData.country = loginUser.defaultAddress?.country ? loginUser.defaultAddress?.country?.name : '';
      userData.countryCode = loginUser.defaultAddress?.country ? loginUser.defaultAddress?.country?.isocode : '';
      userData.zipCode = loginUser.defaultAddress?.postalCode;
      userData.customerId = loginUser.customerId;
      userData.ZZ_APP_NAME = loginUser.firstName + loginUser.lastName;
      userData.ZZ_APP_TITLE = loginUser?.userRole?.roleName;
      userData.ZZ_CUSTOMER = loginUser.defaultAddress?.companyName;
      userData.ZZ_EMAIL = email;
      userData.ZZ_LOCATION = loginUser.defaultAddress?.region ? loginUser.defaultAddress?.region?.name : '';
      userData.ZZ_LOCATION_DET = loginUser.defaultAddress.town;
      userData.ZZ_PHONE = loginUser.defaultAddress.phone;
      userData.ZZ_SALES_REP = loginUser.cpqAccountManager?.name;
      userData.SAM_NAME = loginUser.cpqAccountManager?.name;
      userData.SAM_PHONE = loginUser.cpqAccountManager?.phone;
      userData.SAM_EMAIL = loginUser.cpqAccountManager?.email;
      userData.REP_OFFICE_NAME = loginUser.cpqAccountManager?.territoryName;
      userData.companyStreetAddress = loginUser.defaultAddress?.line1;
    }
    return userData;
  }

  prepareConfigRequest(formData): ThreeKitConfigData {
    const configData: ThreeKitConfigData = {} as ThreeKitConfigData;

    if (formData) {
      if (formData.projectData) {
        configData.configName = formData.configName;
        configData.projectId = formData.projectData.code;
        configData.ZZ_PROJECT_NAME = formData.projectData.name;
        configData.projectDetails = {} as ProjectDetails;
        configData.projectDetails.projectName = formData.projectData.name;
        configData.projectDetails.estimatedInstallDate = formData.projectData.cpqInstallationDate;
        configData.projectDetails.vertical = formData.cpqVerticalId;
        //  configData.projectDetails.verticalName
        configData.projectLocation = {} as ProjectLocation;
        configData.projectLocation.streetAddress1 = formData.projectData.deliveryAddress.line1
          ? formData.projectData.deliveryAddress.line1
          : '';
        configData.projectLocation.streetAddress2 = formData.projectData.deliveryAddress.line2
          ? formData.projectData.deliveryAddress.line2
          : '';
        configData.projectLocation.city = formData.projectData.deliveryAddress.town;
        // configData.projectLocation.state = formData.state ? formData.state : '';
        configData.projectLocation.stateCode = formData.projectData.deliveryAddress.region.isocode
          ? formData.projectData.deliveryAddress.region.isocode
          : '';
        // configData.projectLocation.country = formData
        //   .deliveryAddress.country
        //   ? formData.deliveryAddress.country.name
        //   : '';
        configData.projectLocation.countryCode = formData.projectData.deliveryAddress.region.countryIso
          ? formData.projectData.deliveryAddress.region.countryIso
          : '';
        configData.projectLocation.zipCode = formData.projectData.deliveryAddress.postalCode;

        configData.notes = formData.notes;
        configData.lastmodified = formData.projectData?.modifiedTime;
      } else {
        configData.configName = formData.configurationName;
        configData.projectId = formData.code ? formData.code : '';
        configData.ZZ_PROJECT_NAME = formData.projectName;
        configData.projectDetails = {} as ProjectDetails;
        configData.projectDetails.projectName = formData.projectName;
        configData.projectDetails.estimatedInstallDate = formData.cpqInstallationDate;
        configData.projectDetails.vertical = formData.cpqVerticalId;
        //  configData.projectDetails.verticalName
        configData.projectLocation = {} as ProjectLocation;
        configData.projectLocation.streetAddress1 = formData.streetAddress1;
        configData.projectLocation.streetAddress2 = formData.streetAddress2;
        configData.projectLocation.city = formData.city;
        // configData.projectLocation.state = formData.state ? formData.state : '';
        configData.projectLocation.stateCode = formData.state ? formData.state : '';
        // configData.projectLocation.country = formData
        //   .deliveryAddress.country
        //   ? formData.deliveryAddress.country.name
        //   : '';
        configData.projectLocation.countryCode = formData.countryIso ? formData.countryIso : '';
        configData.projectLocation.zipCode = formData.zipcode;

        configData.notes = formData.notes;
      }
    }
    return configData;
  }

  prepareSaveProjectBodyAndSave(newProjectFormData) {
    const saveProjectBody: ConfigSaveObj = {} as ConfigSaveObj;

    saveProjectBody.city = newProjectFormData.city;
    saveProjectBody.countryIso = newProjectFormData.countryIso;
    saveProjectBody.cpqInstallationDate = newProjectFormData.cpqInstallationDate;
    saveProjectBody.cpqVerticalId = newProjectFormData.cpqVerticalId;
    saveProjectBody.projectName = newProjectFormData.projectName;
    saveProjectBody.state = newProjectFormData.state;
    saveProjectBody.streetAddress1 = newProjectFormData.streetAddress1;
    saveProjectBody.streetAddress2 = newProjectFormData.streetAddress2;
    saveProjectBody.zipcode = newProjectFormData.zipcode;
    saveProjectBody.notes = newProjectFormData.notes;

    return saveProjectBody;
  }
}
