import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService, RoutingService, createFrom } from '@spartacus/core';
import { SloanCpqProjectService } from 'src/app/common/services/sloan-cpq-project.service';
import { Project } from 'src/app/models/projects.model';
import { SloanCpqOnClickEvent } from 'src/app/store/custom-spartacus-events';
import { SloanCpqProjectDetailsComponent } from '../../sloan-cpq-project-details/sloan-cpq-project-details.component';
import { SloanCpqConfirmDialogService } from 'src/app/common/component/sloan-cpq-confirm-dialog/sloan-cpq-confirm-dialog.service';

@Component({
  selector: 'sloan-cpq-project-item',
  templateUrl: './sloan-cpq-project-item.component.html',
  styleUrls: ['./sloan-cpq-project-item.component.scss'],
})
export class SloanCpqProjectItemComponent implements OnInit {
  imageUrl = '../../../assets/images/';
  modalRef;
  @Input() project: Project;
  constructor(
    public routeService: RoutingService,
    private modalService: NgbModal,
    private sloanCpqProjectService: SloanCpqProjectService,
    private confirmationDialogService: SloanCpqConfirmDialogService,
    private events: EventService
  ) {}

  ngOnInit(): void {}

  sloanCPQOnClickEvent(title): void {
    this.events.dispatch(createFrom(SloanCpqOnClickEvent, { title: title }));
  }

  goToConfigurators() {
    this.routeService.go({ cxRoute: 'configurationlist', params: { code: this.project.code } });
  }

  archive() {
    this.confirmationDialogService
      .confirm('projects.projectItem.archive', 'projects.projectItem.archiveConfirmMsg')
      .then((confirmed) => {
        if (confirmed) {
          this.sloanCpqProjectService.archiveProject(this.project.code);
        } else {
          console.log(confirmed);
        }
      });
  }

  projectDetails() {
    let modalInstance: any;
    this.modalRef = this.modalService.open(SloanCpqProjectDetailsComponent);
    modalInstance = this.modalRef.componentInstance;
    modalInstance.projectDetail = this.project;
  }
}
