import { environment } from 'src/environments/environment';

export const THREE_KIT_BASE_URL = environment.threeKitUrl + '/api';
export const DUPLICATE_CONFIG_URL = (udid: string) =>
  THREE_KIT_BASE_URL +
  `/configuration/duplicate/${udid}?bearer_token=` +
  environment.threeKitToken;
export const CREATE_CONFIG_URL = (udid: string) =>
  THREE_KIT_BASE_URL + `/duplicate/${udid}`;
export const KN_BR_QUOTES_VALUE = (customerId: string) =>
  `knbrcounter/customers/${customerId}/quotesValue?fields=DEFAULT`;
export const DELETE_CONFIG_URL =
  THREE_KIT_BASE_URL +
  '/configurations/delete?bearer_token=' +
  environment.threeKitToken;
export const GET_THREE_KIT_CONFIGS_URL =
  THREE_KIT_BASE_URL +
  '/configurations?bearer_token=' +
  environment.threeKitToken;
export const GET_THREE_KIT_CONFIG_URL =
  THREE_KIT_BASE_URL +
  '/configuration/72a0d7a5-8354-4ebc-afe3-5c1b8220dba7?bearer_token=' +
  environment.threeKitToken;

// event types

export const POST_MESSAGE_TYPE = {
  USER_DATA: 'userData',
  CONFIG_DATA: 'configData',
};

export const RECEIVE_MESSAGE_TYPE = {
  STATE_LOADED: 'stateLoaded',
  REQUEST_USER_DATA: 'requestUserData',
  REQUEST_CONFIG_DATA: 'requestConfigData',
  SAVE_CONFIG_SUCCESS: 'saveConfigSuccess',
  SAVE_CONFIG_FAIL: 'saveConfigFailed',
  UPDATE_CONFIG_SUCCESS: 'updateConfigSuccess',
  UPDATE_CONFIG_FAIL: 'updateConfigFailed',
  SHOW_PROJECTS: 'showProjects',
  SHOW_INFO: 'showInfo',
};
