import { ConfigModule, CmsConfig } from '@spartacus/core';
import { GenericLinkModule, NavigationModule, PageComponentModule, PageLayoutModule, PageSlotModule } from '@spartacus/storefront';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanCpqFooterComponent } from '../sloan-cpq-footer/sloan-cpq-footer.component';



@NgModule({
  declarations: [SloanCpqFooterComponent],
  imports: [
    PageComponentModule,
    PageLayoutModule,
    PageSlotModule,
    CommonModule,
    NavigationModule,
    GenericLinkModule,
    ConfigModule.withConfig({
      cmsComponents: {
        FooterNavigationComponent: {
          component: SloanCpqFooterComponent
        }
      }
    } as CmsConfig)
  ]
})
export class SloanCpqFooterModule { }
