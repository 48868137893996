import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { SpinnerModule } from '@spartacus/storefront';
// import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SloanCpqPipesModule } from 'src/app/common/pipes/sloan-cpq-pipes/sloan-cpq-pipes.module';
import { SloanCpqProjectItemComponent } from './sloan-cpq-project-item/sloan-cpq-project-item.component';
import { SloanCpqProjectsListComponent } from './sloan-cpq-projects-list.component';

@NgModule({
  declarations: [SloanCpqProjectsListComponent, SloanCpqProjectItemComponent],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CPQCreateProjectComponent: {
          component: SloanCpqProjectsListComponent,
        },
      },
    } as CmsConfig),
    NgbModule,
    SpinnerModule,
    // Ng2SearchPipeModule,
    SloanCpqPipesModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
  ],
})
export class SloanCpqProjectsListModule {}
