import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Country, OccSiteAdapter, Region } from '@spartacus/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SloanCpqConfiguratorService } from 'src/app/common/services/sloan-cpq-configurator.service';
import { SloanCpqProjectService } from 'src/app/common/services/sloan-cpq-project.service';
import { Vertical } from 'src/app/models/configurator.model';

@Component({
  selector: 'app-sloan-cpq-configurators-save',
  templateUrl: './sloan-cpq-configurators-save.component.html',
  styleUrls: ['./sloan-cpq-configurators-save.component.scss'],
})
export class SloanCpqConfiguratorsSaveComponent implements OnInit {
  configForm: UntypedFormGroup;
  countries$: Observable<Country[]>;
  isCountryLoading = false;
  regions$: Observable<Region[]>;
  isRegionLoading = false;
  verticals$: Observable<Vertical[]> = this.sloanCpqProjectService.loadVerticals();
  showErrorMessage = false;
  configurationName;
  disableSubmitBtn = false;
  showConfigName = true;
  constructor(
    public sloanCpqConfiguratorService: SloanCpqConfiguratorService,
    private occSiteAdapter: OccSiteAdapter,
    private sloanCpqProjectService: SloanCpqProjectService,
    private ngbActiveModal: NgbActiveModal,
    private fb: UntypedFormBuilder
  ) {}

  initConfigForm() {
    let pattern = /(^\d{5}-\d{4}$)|(^\d{5}$)/;
    this.configForm = this.fb.group({
      configurationName: [this.configurationName],
      projectName: ['', Validators.required],
      cpqInstallationDate: ['', Validators.required],
      cpqVerticalId: ['', Validators.required],
      streetAddress1: ['', Validators.required],
      streetAddress2: [''],
      countryIso: ['US', Validators.required],
      zipcode: new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern(pattern)])),
      city: ['', Validators.required],
      state: ['', Validators.required],
      notes: [''],
    });

    this.getCountries();
    this.countrySelected('US');
  }

  ngOnInit(): void {
    this.initConfigForm();
    this.sloanCpqProjectService.getVerticals();
  }

  closeModal() {
    this.ngbActiveModal.dismiss();
  }

  submitForm() {
    if (this.configForm.valid) {
      const formData = this.configForm.value;
      this.ngbActiveModal.close(formData);
      this.disableSubmitBtn = false;
    } else {
      this.configForm.markAllAsTouched();
    }
  }

  removeAlert() {
    setTimeout(() => {
      this.showErrorMessage = false;
    }, 3000);
  }

  getCountries() {
    this.isCountryLoading = true;
    this.countries$ = this.occSiteAdapter.loadCountries().pipe(tap(() => (this.isCountryLoading = false)));
  }
  countrySelected(isocode) {
    if (isocode) {
      this.isRegionLoading = true;
      this.regions$ = this.occSiteAdapter.loadRegions(isocode).pipe(tap(() => (this.isRegionLoading = false)));
    } else {
      this.regions$ = null;
    }
    this.configForm.get('countryIso').valueChanges.subscribe((selectedCountry) => {
      if (selectedCountry === 'IN') {
        let pattern = '[0-9]{6}';
        this.configForm.controls['zipcode'].setValidators(
          Validators.compose([Validators.required, Validators.pattern(pattern)])
        );
      }
      if (selectedCountry === 'CA') {
        let pattern = '[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][ ]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]';
        this.configForm.controls['zipcode'].setValidators(
          Validators.compose([Validators.required, Validators.pattern(pattern)])
        );
      }
      if (selectedCountry === 'US') {
        let pattern = /(^\d{5}-\d{4}$)|(^\d{5}$)/;
        this.configForm.controls['zipcode'].setValidators(
          Validators.compose([Validators.required, Validators.pattern(pattern)])
        );
      }
    });
  }
}
