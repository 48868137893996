<div class="row width-100 topheader col-md-12">
  <div class="col-md-9">
    <cx-page-slot
      position="TopHeaderSlot"
      class="topnav d-none d-sm-none d-md-block"
    ></cx-page-slot>
  </div>
  <div class="col-md-3">
    <cx-page-slot position="TopHeaderRightNavSlot"></cx-page-slot>
  </div>
</div>
<ng-template cxOutletRef="cx-header">
  <cx-global-message *ngIf="showGlobalMessage | async"></cx-global-message>
  <div class="col-lg-12 bg-white d-flex align-items-center">
    <div class="col-lg-3 float-left p-0 res-selected-pages">
      <cx-page-slot position="SiteLogo"></cx-page-slot>
    </div>
    <div class="col-lg-9 float-left p-0 res-selected-pages">
      <cx-page-slot position="BottomHeaderSlot"></cx-page-slot>
    </div>
  </div>
</ng-template>
<ngx-spinner size="medium" color="#fff" type="ball-clip-rotate" class="h-100">
</ngx-spinner>
