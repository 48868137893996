import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalMessageService, GlobalMessageType, OccEndpointsService } from '@spartacus/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  SLOAN_CPQ_APPROVAL_DESIGN,
  SLOAN_CPQ_CONFIGURATOR_DETAIL,
  SLOAN_CPQ_CONFIGURATOR_LIST,
  SLOAN_CPQ_CONFIGURATOR_SAVE,
  SLOAN_CPQ_CONFIGURATOR_UPDATE,
  SLOAN_CPQ_DOCUMENT_DOWNLOAD,
  SLOAN_CPQ_DOCUMENT_URL,
} from 'src/app/constants/api.endpoint.constant';
import { ConfigSaveObj, Configurator, UpdateConfiguration } from 'src/app/models/configurator.model';

@Injectable({
  providedIn: 'root',
})
export class SloanCpqConfiguratorService {
  configuratorsList$: BehaviorSubject<Configurator> = new BehaviorSubject(null);
  setSelectedProducts$: BehaviorSubject<string[]> = new BehaviorSubject(null);
  configuratorLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  projectId: string;
  configId: string;
  constructor(
    private http: HttpClient,
    private endPointService: OccEndpointsService,
    private spinner: NgxSpinnerService,
    private globalMessageService: GlobalMessageService
  ) {}

  getConfigurators(proojectCode) {
    this.spinner.show();
    this.configuratorLoading$.next(true);
    this.http
      .get<Configurator>(
        this.endPointService.buildUrl(SLOAN_CPQ_CONFIGURATOR_LIST, {
          urlParams: {
            projectId: proojectCode,
          },
        })
      )
      .subscribe(
        (res) => {
          this.configuratorLoading$.next(false);
          this.configuratorsList$.next(res);
          this.spinner.hide();
        },
        (err) => {
          this.configuratorLoading$.next(false);
          this.spinner.hide();
          this.configuratorsList$.next({ savedCartData: null });
          this.globalMessageService.add({ key: 'user.errorMsg' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      );
  }

  loadConfigurators(): Observable<Configurator> {
    return this.configuratorsList$.asObservable();
  }

  isConfigsLoading$(): Observable<boolean> {
    return this.configuratorLoading$.asObservable();
  }

  saveConfigurator(configData: ConfigSaveObj, projectId) {
    return this.http.post(
      this.endPointService.buildUrl(SLOAN_CPQ_CONFIGURATOR_SAVE, {
        urlParams: {
          projectId: projectId,
        },
      }),
      configData
    );
  }

  configurationDetail(projectId, configId) {
    return this.http
      .get(
        this.endPointService.buildUrl(SLOAN_CPQ_CONFIGURATOR_DETAIL, {
          urlParams: {
            projectId: projectId,
            configId: configId,
          },
        })
      )
      .subscribe(
        (res) => {
          this.getConfigurators(projectId);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  // updateConfiguration(configData: ConfigSaveObj, projectId, configId){
  //   return this.http.patch(this.endPointService.getUrl(SLOAN_CPQ_CONFIGURATOR_UPDATE, {projectId, configId}), configData);
  // }

  updateConfigurations(configs: UpdateConfiguration, projectId, configId) {
    return this.http.patch(
      this.endPointService.buildUrl(SLOAN_CPQ_CONFIGURATOR_UPDATE, {
        urlParams: {
          projectId: projectId,
          configId: configId,
        },
      }),
      configs
    );
  }

  deleteConfiguration(projectId, configId) {
    this.http
      .delete(
        this.endPointService.buildUrl(SLOAN_CPQ_CONFIGURATOR_UPDATE, {
          urlParams: {
            projectId: projectId,
            configId: configId,
          },
        })
      )
      .subscribe(
        (res) => {
          this.getConfigurators(projectId);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  reqDocuments(reqObject) {
    return this.http.post(
      this.endPointService.buildUrl(SLOAN_CPQ_DOCUMENT_URL, {
        urlParams: {
          projectId: reqObject.projectId,
        },
        //TODO : Changed by Pushpak
        queryParams: {
          configIds: reqObject.configIds,
          docType: reqObject.docType,
        },
      }),
      null
    );
  }

  downloadDocuments(reqObject) {
    return this.http.get(
      this.endPointService.buildUrl(SLOAN_CPQ_DOCUMENT_DOWNLOAD, {
        urlParams: {
          projectId: reqObject.projectId,
        },
        //TODO : Changed by Pushpak
        queryParams: {
          configIds: reqObject.configIds,
          docType: reqObject.docType,
        },
      }),
      { responseType: 'blob' }
    );
  }

  approveDesign(reqObject, projectCode) {
    return this.http.post(
      this.endPointService.buildUrl(
        SLOAN_CPQ_APPROVAL_DESIGN,
        {
          urlParams: {
            projectId: projectCode,
          },
          queryParams: {
            configIds: reqObject?.configIds
          },
        },
        
      ),
      null
    );
  }

  setSelectedConfigurators(configuartorCodes: string[]) {
    this.setSelectedProducts$.next(configuartorCodes);
  }

  loadSelectedConfigurators$(): Observable<string[]> {
    return this.setSelectedProducts$.asObservable();
  }
}
