import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfigModule, I18nModule, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { SloanCpqUserProfileModule } from './sloan-cpq-user-profile/sloan-cpq-user-profile.module';
import { SloanSinkConfigHelpComponent } from 'src/app/cpq-libs/sloan-sink-config-help/sloan-sink-config-help.component';
import { SloanCpqAuthGuard } from 'src/app/common/guards/sloan-cpq-auth-guard.guard';
import { SloanCpqResetPasswordModule } from './sloan-cpq-reset-password/sloan-cpq-reset-password.module';
import { SloanForgotPasswordModule } from './sloan-forgot-password/sloan-forgot-password.module';
import { SloanGlobalHelpComponent } from 'src/app/common/component/sloan-global-help/sloan-global-help.component';

@NgModule({
  declarations: [SloanGlobalHelpComponent, SloanSinkConfigHelpComponent],
  imports: [
    CommonModule,
    SloanCpqUserProfileModule,
    SloanCpqResetPasswordModule,
    SloanForgotPasswordModule,
    I18nModule,
    RouterModule.forChild([
      {
        data: { pageLabel: '/cpqUsers', cxRoute: 'userUpdate' },
        path: null,
        canActivate: [SloanCpqAuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          userUpdate: {
            paths: ['cpqUsers'],
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class SloanCpqProfileComponentModule {}
