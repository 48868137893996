import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPasswordComponent, ForgotPasswordComponentService } from '@spartacus/user/profile/components';
import { SloanCpqUserService } from 'src/app/common/services/sloan-cpq-user.service';
import { SLOAN_CPQ_USER_KEY } from 'src/app/constants/storage.constant';

@Component({
  selector: 'app-sloan-forgot-password',
  templateUrl: './sloan-forgot-password.component.html',
  styleUrls: ['./sloan-forgot-password.component.scss'],
})
export class SloanForgotPasswordComponent extends ForgotPasswordComponent implements OnInit {
  disableSubmitBtn = false;

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private sloancpqUserService: SloanCpqUserService,
    forgotPasswordComponentService: ForgotPasswordComponentService
  ) {
    super(forgotPasswordComponentService);

    this.form = new UntypedFormGroup({
      userEmail: new UntypedFormControl(),
    });
  }
  ngOnInit(): void {
    // TODO:Commented by Pushpak
    //super.ngOnInit();
  }

  // sendLink() {
  //   this.userServices.forgotPassword(this.forgotPasswordForm.value)
  //     .subscribe(
  //       response => console.log('Sucess!', response),
  //       error => console.log('Error!', error)
  //     );
  //   this.closeModal();
  // }

  requestForgotPasswordEmail() {
    if (this.form.valid) {
      this.disableSubmitBtn = true;
      this.sloancpqUserService.forgotPasswordRequest(SLOAN_CPQ_USER_KEY + this.form.value.userEmail);
      this.closeModal();
    } else {
      this.form.markAllAsTouched();
      this.disableSubmitBtn = true;
    }
  }

  closeModal() {
    this.ngbActiveModal.dismiss();
  }
}
