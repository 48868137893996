import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Country, OccSiteAdapter, Region } from '@spartacus/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SloanCpqProjectService } from 'src/app/common/services/sloan-cpq-project.service';
import { Vertical } from 'src/app/models/configurator.model';
import { Project } from 'src/app/models/projects.model';

@Component({
  selector: 'app-sloan-cpq-project-details',
  templateUrl: './sloan-cpq-project-details.component.html',
  styleUrls: ['./sloan-cpq-project-details.component.scss'],
})
export class SloanCpqProjectDetailsComponent implements OnInit {
  projectDetail: Project;
  // deliveryDetail: DeliveryAddress;
  countries$: Observable<Country[]>;
  isCountryLoading = false;
  regions$: Observable<Region[]>;
  isRegionLoading = false;
  verticals$: Observable<Vertical[]> = this.sloanCpqProjectService.loadVerticals();
  constructor(
    public activeModal: NgbActiveModal,
    private occSiteAdapter: OccSiteAdapter,
    private sloanCpqProjectService: SloanCpqProjectService
  ) {}

  ngOnInit(): void {
    this.sloanCpqProjectService.getVerticals();
    this.getCountries();
    if (this.projectDetail && this.projectDetail.description) {
      this.projectDetail.description = this.projectDetail.description === 'EMPTY' ? '' : this.projectDetail.description;
    }
    if (this.projectDetail && this.projectDetail.deliveryAddress && this.projectDetail.deliveryAddress.country) {
      this.countrySelected(this.projectDetail.deliveryAddress.country.isocode);
    }
  }

  getCountries() {
    this.isCountryLoading = true;
    this.countries$ = this.occSiteAdapter.loadCountries().pipe(tap(() => (this.isCountryLoading = false)));
  }

  countrySelected(isocode) {
    // console.log(isocode);
    if (isocode) {
      this.isRegionLoading = true;
      this.regions$ = this.occSiteAdapter.loadRegions(isocode).pipe(tap(() => (this.isRegionLoading = false)));
    } else {
      this.regions$ = null;
    }
  }

  convertDate(insatllationdate) {
    const date = new Date(insatllationdate);
    const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('/');
  }

  closeModal() {
    this.activeModal.dismiss();
  }
}
