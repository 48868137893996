<!-- <cx-spinner *ngIf="showupdateProgress || showsaveprogress"></cx-spinner> -->
<!-- <strong *ngIf="showsaveprogress">{{
    "configurators.home.saveinprogress" | cxTranslate
  }}</strong>
  <strong *ngIf="showupdateProgress">{{
    "configurators.home.updateinprogress" | cxTranslate
  }}</strong> -->
<iframe
  class="w-100"
  id="threekitiframe"
  frameborder="0"
  scrolling="no"
  [src]="childOrigin | safe: 'resourceUrl'"
  width="100"
  height="100"
>
</iframe>
