import { ConfigModule, CmsConfig, UrlModule, I18nModule } from '@spartacus/core';
import { SloanBottomHeaderComponent } from './sloan-bottom-header.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericLinkModule, NavigationModule } from '@spartacus/storefront';



@NgModule({
  declarations: [SloanBottomHeaderComponent],
  imports: [
    CommonModule,
    UrlModule,
    NavigationModule,
    GenericLinkModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        NavigationComponent: {
          component: SloanBottomHeaderComponent,
        },
      },
    } as CmsConfig),
  ]
})
export class SloanBottomHeaderModule { }
