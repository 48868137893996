import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoutingService } from '@spartacus/core';

@Component({
  selector: 'app-sloan-global-help',
  templateUrl: './sloan-global-help.component.html',
  styleUrls: ['./sloan-global-help.component.scss']
})
export class SloanGlobalHelpComponent implements OnInit {

  phoneNumber  = environment.globalHelpphoneNumber;
  email = environment.globalHelpemail;
  workingDays = environment.globalHelpworkingDays;
  workingHours = environment.globalHelpworkingHours;
  constructor(
    private ngbActiveModal: NgbActiveModal,
    private routingService: RoutingService
  ) { }

  ngOnInit(): void {
  }

  closeModal(){
    this.ngbActiveModal.dismiss();
  }

  openFaqs(){
    this.closeModal();
    window.open(window.location+"/faq")
  }

}
