
import { NgModule } from '@angular/core';
import { SloanCpqProjectsComponentModule } from './components/cpq-project-components.module';

@NgModule({
  declarations: [],
  imports: [
    SloanCpqProjectsComponentModule,
  ],
})
export class SloanCpqProjectsModule {}
