<div class="modal-content rounded-0 sign-up">
  <div class="modal-header">
    <h4 class="modal-title w-100" id="modal-basic-title">
      {{ 'sloansignupForm.signUp' | cxTranslate }}
    </h4>
    <h6 class="w-100 mt-4">
      {{ 'sloansignupForm.haveAnAccount' | cxTranslate }}
      <a href="javascript:void(0)" class="btn-link" (click)="openLoginModal()">
        {{ 'sloansignupForm.logIn' | cxTranslate }}</a
      >
    </h6>
  </div>
  <cx-global-message></cx-global-message>
  <div class="modal-body nopad">
    <div class="row p-3">
      <div class="col-4 nopad text-center">
        <img src="../../../assets/images/account-save-project.svg" /><br />
        <span>{{ 'sloansignupForm.saveProjects' | cxTranslate }}</span>
      </div>
      <div class="col-4 nopad text-center">
        <!-- <i class="fa fa-file-code-o signup-icons" aria-hidden="true"></i> <br/> -->
        <img src="../../../assets/images/account-create-quote.svg" /><br />
        <span>{{ 'sloansignupForm.createQuotes' | cxTranslate }}</span>
      </div>
      <div class="col-4 nopad text-center">
        <img src="../../../assets/images/account-get-help.svg" /> <br />
        <span>{{ 'sloansignupForm.getHelp' | cxTranslate }}</span>
      </div>
    </div>
    <form [formGroup]="signUpForm" (ngSubmit)="next()" novalidate>
      <div *ngIf="isUserDetails" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0 float-left">
        <h5 class="col-12 mb-0 mt-3">
          {{ 'sloansignupForm.userDetails' | cxTranslate }}
        </h5>
        <div class="col-xs-12 col-sm-12 col-md-6 mt-3 float-left">
          <label for="firstName" class="form-label" #firstName
            >{{ 'sloansignupForm.firstName' | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <input type="text" class="form-control rounded-0" id="firstName" formControlName="firstName" />
          <div
            *ngIf="
              signUpForm.controls['firstName'].invalid &&
              (signUpForm.controls['firstName'].dirty || signUpForm.controls['firstName'].touched)
            "
          >
            <div class="form-mandatory-text" *ngIf="signUpForm.controls['firstName'].errors!.required">
              {{ 'sloansignupForm.firstName' | cxTranslate }}{{ 'sloansignupForm.isInvalid' | cxTranslate }}
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 col-12-6 mt-3 float-left">
          <label for="lastName" class="form-label" #lastName
            >{{ 'sloansignupForm.lastName' | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <input type="text" class="form-control rounded-0" formControlName="lastName" id="lastName" />
          <div
            *ngIf="
              signUpForm.controls['lastName'].invalid &&
              (signUpForm.controls['lastName'].dirty || signUpForm.controls['lastName'].touched)
            "
          >
            <div class="form-mandatory-text" *ngIf="signUpForm.controls['lastName'].errors!.required">
              {{ 'sloansignupForm.lastName' | cxTranslate }}{{ 'sloansignupForm.isInvalid' | cxTranslate }}
            </div>
          </div>
        </div>

        <div class="col-12 mt-3 float-left">
          <label class="form-label"
            >{{ 'sloansignupForm.emailAddress' | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <input type="email" class="form-control rounded-0" formControlName="email" id="email" />
          <div
            *ngIf="
              signUpForm.controls['email'].invalid &&
              (signUpForm.controls['email'].dirty || signUpForm.controls['email'].touched)
            "
          >
            <div class="form-mandatory-text" *ngIf="signUpForm.controls['email'].errors!.required">
              {{ 'sloansignupForm.pleaseEnter' | cxTranslate }}{{ 'sloansignupForm.emailAddress' | cxTranslate }}
            </div>
            <div class="form-mandatory-text" *ngIf="signUpForm.controls['email'].errors.email">
              {{ 'sloansignupForm.invalidEmail' | cxTranslate }}
            </div>
          </div>
        </div>

        <div class="col-12 mt-3 float-left">
          <label class="form-label"
            >{{ 'sloansignupForm.password' | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <input
            type="password"
            (keyup)="checkPasswordValidations()"
            class="form-control rounded-0"
            formControlName="password"
            id="password"
          />
          <div *ngIf="signUpForm.controls['password'].invalid && signUpForm.controls['password'].dirty">
            <div class="form-mandatory-text" *ngIf="signUpForm.controls['password'].errors!.required">
              {{ 'sloansignupForm.enterPasswordMsg' | cxTranslate }}
            </div>
          </div>
        </div>

        <div class="col-12 mt-3 float-left">
          <div class="form-check form-check-inline mb-2">
            <input
              class="form-check-input"
              type="checkbox"
              name="passwordOptions"
              id="oneLowerChar"
              value="One lowercase character"
              [checked]="isLowercase"
              tabindex="-1"
              readonly
            />
            <label class="form-check-label mb-2" for="oneLowerChar">
              {{ 'sloansignupForm.lowerCaseMsg' | cxTranslate }}</label
            >
          </div>
          <div class="form-check form-check-inline mb-2">
            <input
              class="form-check-input"
              [checked]="isNumber"
              type="checkbox"
              name="passwordOptions"
              id="oneNum"
              value="One number"
              tabindex="-1"
              readonly
            />
            <label class="form-check-label mb-2" for="oneNum"> {{ 'sloansignupForm.numberMsg' | cxTranslate }}</label>
          </div>
          <br />
          <div class="form-check form-check-inline mb-2">
            <input
              class="form-check-input"
              [checked]="isUpperCase"
              type="checkbox"
              name="passwordOptions"
              id="oneUpperChar"
              value="One uppercase charatcer"
              tabindex="-1"
              readonly
            />
            <label class="form-check-label mb-2" for="oneUpperChar">
              {{ 'sloansignupForm.upperCaseMsg' | cxTranslate }}</label
            >
          </div>
          <div class="form-check form-check-inline mb-2">
            <input
              class="form-check-input"
              [checked]="eightCharacters"
              type="checkbox"
              name="passwordOptions"
              id="eightCharMin"
              value="8 charatcer minimum"
              tabindex="-1"
              readonly
            />
            <label class="form-check-label mb-2" for="eightCharMin">
              {{ 'sloansignupForm.minCharacterMsg' | cxTranslate }}</label
            >
          </div>
        </div>

        <div class="col-12 mt-2 float-left">
          <label class="form-label"
            >{{ 'sloansignupForm.confirmPassword' | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <input
            type="password"
            class="form-control rounded-0"
            formControlName="confirmPassword"
            id="confirmPassword"
          />
          <div *ngIf="signUpForm.controls['confirmPassword'].invalid && signUpForm.controls['confirmPassword'].dirty">
            <div class="form-mandatory-text" *ngIf="signUpForm.controls['confirmPassword'].errors!.required">
              {{ 'sloansignupForm.confirmPasswordMsg' | cxTranslate }}
            </div>
          </div>
          <div
            class="form-mandatory-text"
            *ngIf="
              signUpForm.hasError('notSame') &&
              (signUpForm.get('confirmPassword').touched || signUpForm.controls['confirmPassword'].dirty)
            "
          >
            {{ 'sloansignupForm.passwordDoNotMatchMsg' | cxTranslate }}
          </div>
        </div>
        <div class="col-12 mt-3 float-left">
          <div class="form-check form-check-consent form-check-inline mb-2">
            <input class="form-check-input" type="checkbox" id="accept" formControlName="acceptanceCheckbox" />
            <label class="form-check-label mb-2" for="accept">
              <span class="text-danger mandatory-font-size ml-1">*</span>
              {{ 'sloansignupForm.policy1' | cxTranslate }}
              <a href="https://www.sloan.com/terms-of-use" target="_blank">
                {{ 'sloansignupForm.policy2' | cxTranslate }}
              </a>
              {{ 'sloansignupForm.policy3' | cxTranslate }}
              <a href="https://www.sloan.com/privacy-policy" target="_blank">
                {{ 'sloansignupForm.policy4' | cxTranslate }}</a
              ></label
            >
          </div>
        </div>
        <div class="float-left col-md-12 pl-3 pr-3 mt-3">
          <re-captcha id="recaptcha" name="recaptcha" formControlName="token"></re-captcha>
          <cx-form-errors [control]="signUpForm.get('token')"></cx-form-errors>
        </div>
        <div class="modal-footer col-12 pt-4 mt-3 float-left">
          <button type="button" class="btn btn-default float-left" (click)="closeModal()">
            {{ 'sloansignupForm.cancelButton' | cxTranslate }}
          </button>
          <button
            type="submit"
            [disabled]="signUpForm.invalid || !this.signUpForm.value.acceptanceCheckbox"
            class="btn btn-primary float-right"
          >
            {{ 'sloansignupForm.nextButton' | cxTranslate }}
          </button>
        </div>
      </div>
    </form>
    <form [formGroup]="companyForm" (ngSubmit)="onSubmit()" novalidate>
      <div *ngIf="!isUserDetails" class="col-12 p-0 float-left">
        <h4 class="col-12 mb-0 mt-3">
          {{ 'sloansignupForm.welcome' | cxTranslate }}
          <span class="font-weight-bold">{{ firstName }} {{ lastName }}.</span>
        </h4>
        <h5 class="col-12 mb-0 mt-3">
          {{ 'sloansignupForm.companyDetails' | cxTranslate }}
        </h5>
        <div class="col-6 mt-3 float-left">
          <label for="firstName" class="form-label"
            >{{ 'sloansignupForm.title' | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <ng-select
            class=""
            formControlName="titleCode"
            bindLabel="name"
            bindValue="code"
            [items]="userTitles.titles"
            [placeholder]="'sloansignupForm.title' | cxTranslate"
            [searchable]="false"
            [clearable]="false"
          >
          </ng-select>
          <div
            *ngIf="
              companyForm.controls['titleCode'].invalid &&
              (companyForm.controls['titleCode'].dirty || companyForm.controls['titleCode'].touched)
            "
          >
            <div class="form-mandatory-text" *ngIf="companyForm.controls['titleCode'].errors!.required">
              {{ 'sloansignupForm.titleMsg' | cxTranslate }}
            </div>
          </div>
        </div>

        <div class="col-6 mt-3 float-left">
          <label for="lastName" class="form-label"
            >{{ 'sloansignupForm.role' | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <ng-select
            class=""
            formControlName="roleId"
            bindLabel="roleName"
            bindValue="roleId"
            [items]="userRoles.roles"
            [placeholder]="'sloansignupForm.role' | cxTranslate"
            [searchable]="false"
            [clearable]="false"
          >
          </ng-select>
          <div
            *ngIf="
              companyForm.controls['roleId'].invalid &&
              (companyForm.controls['roleId'].dirty || companyForm.controls['roleId'].touched)
            "
          >
            <div class="form-mandatory-text" *ngIf="companyForm.controls['roleId'].errors!.required">
              {{ 'sloansignupForm.roleMsg' | cxTranslate }}
            </div>
          </div>
        </div>

        <div class="col-6 mt-3 float-left">
          <label class="form-label"
            >{{ 'sloansignupForm.companyName' | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <input type="email" class="form-control rounded-0" formControlName="companyName" id="companyName" />
          <div
            *ngIf="
              companyForm.controls['companyName'].invalid &&
              (companyForm.controls['companyName'].dirty || companyForm.controls['companyName'].touched)
            "
          >
            <div class="form-mandatory-text" *ngIf="companyForm.controls['companyName'].errors!.required">
              {{ 'sloansignupForm.companyNameMsg' | cxTranslate }}
            </div>
          </div>
        </div>

        <div class="col-6 mt-3 float-left">
          <label class="form-label"
            >{{ 'sloansignupForm.companyPhone' | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <input type="text" class="form-control rounded-0" formControlName="phone" id="companyphone" />
          <div
            *ngIf="
              companyForm.controls['phone'].invalid &&
              (companyForm.controls['phone'].dirty || companyForm.controls['phone'].touched)
            "
          >
            <div class="form-mandatory-text" *ngIf="companyForm.controls['phone'].errors!.required">
              {{ 'sloansignupForm.companyPhoneMsg' | cxTranslate }}
            </div>
          </div>
        </div>

        <div class="col-6 mt-3 float-left">
          <label class="form-label"
            >{{ 'sloansignupForm.country' | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <ng-select
            class=""
            formControlName="countryIso"
            (change)="countrySelected($event)"
            bindLabel="name"
            bindValue="isocode"
            [items]="countries$ | async"
            [placeholder]="'sloansignupForm.country' | cxTranslate"
            [searchable]="false"
            [clearable]="false"
          >
          </ng-select>
          <div
            *ngIf="
              companyForm.controls['countryIso'].invalid &&
              (companyForm.controls['countryIso'].dirty || companyForm.controls['countryIso'].touched)
            "
          >
            <div class="form-mandatory-text" *ngIf="companyForm.controls['countryIso'].errors!.required">
              {{ 'sloansignupForm.countryMsg' | cxTranslate }}
            </div>
          </div>
        </div>

        <div class="col-6 mt-3 float-left">
          <label clsas="form-label"
            >{{ 'sloansignupForm.stateOrProvince' | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <ng-select
            class=""
            formControlName="state"
            bindLabel="name"
            bindValue="isocode"
            [items]="regions$ | async"
            [placeholder]="'sloansignupForm.stateOrProvince' | cxTranslate"
            [searchable]="false"
            [clearable]="false"
          >
          </ng-select>
          <div
            *ngIf="
              companyForm.controls['state'].invalid &&
              (companyForm.controls['state'].dirty || companyForm.controls['state'].touched)
            "
          >
            <div class="form-mandatory-text" *ngIf="companyForm.controls['state'].errors!.required">
              {{ 'sloansignupForm.stateOrProvinceMsg' | cxTranslate }}
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 float-left">
          <label clsas="form-label"
            >{{ 'sloansignupForm.streetAddress' | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <textarea class="form-control rounded-0" formControlName="streetAddress"> </textarea>
          <div
            *ngIf="
              companyForm.controls['streetAddress'].invalid &&
              (companyForm.controls['streetAddress'].dirty || companyForm.controls['streetAddress'].touched)
            "
          >
            <div class="form-mandatory-text" *ngIf="companyForm.controls['streetAddress'].errors!.required">
              {{ 'sloansignupForm.streetAddressMsg' | cxTranslate }}
            </div>
          </div>
        </div>

        <div class="col-6 mt-3 float-left">
          <label class="form-label"
            >{{ 'sloansignupForm.city' | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <input type="text" class="form-control rounded-0" formControlName="city" id="city" />
          <div
            *ngIf="
              companyForm.controls['city'].invalid &&
              (companyForm.controls['city'].dirty || companyForm.controls['city'].touched)
            "
          >
            <div class="form-mandatory-text" *ngIf="companyForm.controls['city'].errors!.required">
              {{ 'sloansignupForm.cityMsg' | cxTranslate }}
            </div>
          </div>
        </div>
        <div class="col-6 mt-3 float-left">
          <label clsas="form-label"
            >{{ 'sloansignupForm.zipcode' | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <input type="text" class="form-control rounded-0" formControlName="zipcode" id="zipcode" />
          <div
            *ngIf="
              companyForm.controls['zipcode'].invalid &&
              (companyForm.controls['zipcode'].dirty || companyForm.controls['zipcode'].touched)
            "
            class="form-mandatory-text"
          >
            <div *ngIf="companyForm.controls['zipcode'].errors!.required">
              {{ 'sloansignupForm.zipcodeMsg' | cxTranslate }}
            </div>
            <div class="form-mandatory-text" *ngIf="companyForm.controls['zipcode'].errors!.pattern">
              Please enter zip code in correct formats
            </div>
          </div>
        </div>

        <div class="modal-footer col-12 pt-4 mt-3 float-left">
          <button type="button" class="btn btn-default float-left" (click)="bacKOrCloseModal()">
            {{ 'sloansignupForm.cancelButton' | cxTranslate }}
          </button>
          <button
            type="submit"
            class="btn btn-primary float-right"
            [disabled]="companyForm.invalid || disableSubmitBtn"
          >
            {{ 'sloansignupForm.signUp' | cxTranslate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
