<div class="modal-content">
  <div class="modal-header">
    <div class="mb-3">
      <button
        type="button"
        class="btn-close float-right"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="closeModal()"
      >
        <span aria-hidden="true">
          <img src="../../../assets/images/close-sm.svg   " />
        </span>
      </button>
    </div>
    <h4 class="modal-title text-center w-100" id="modal-basic-title">
      {{ "globalHelp.contactHeading" | cxTranslate }}
    </h4>
  </div>
  <div class="modal-body">
    <div class="col-12">
      <label>{{ "globalHelp.phoneLabel" | cxTranslate }}</label>
      <p>{{ phoneNumber }}</p>
      <label>{{ "globalHelp.emailLabel" | cxTranslate }}</label>
      <p>
        <a>{{ email }}</a>
      </p>
      <label>{{ "globalHelp.hoursLabel" | cxTranslate }}</label>
      <p>{{ workingDays }} <br />{{ workingHours }}</p>
    </div>
    <div class="modal-footer col-12 pt-4 mt-3 float-left">
      <button
        type="button"
        class="btn btn-default float-left"
        (click)="closeModal()"
      >
        {{ "globalHelp.cancelBtn" | cxTranslate }}
      </button>
      <button
        type="submit"
        class="btn btn-primary float-right"
        (click)="openFaqs()"
      >
        {{ "globalHelp.faqsBtn" | cxTranslate }}
      </button>
    </div>
  </div>
</div>
