<div class="modal-content">
  <div class="modal-header">
    <div class="mb-3">
      <button
        type="button"
        class="btn-close float-right"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="closeModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <h4 class="modal-title text-center w-100" id="modal-basic-title">
      {{ "downloadAndReqDocuments.downloadAndRequestDocuments" | cxTranslate }}
    </h4>
  </div>
  <div class="modal-body">
    <div
      *ngIf="showErrorMessage"
      class="alert alert-danger alert-dismissible fade show"
      role="alert"
    >
      <strong>{{
        "configurators.downloadDocuments.errorMsg" | cxTranslate
      }}</strong>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="col-12 mt-3">
      <h5 class="text-black">
        {{ "downloadAndReqDocuments.downloadNow" | cxTranslate }}
      </h5>
      <ng-container *ngFor="let document of documentTypes; let i = index">
        <div class="form-check form-check-inline mb-2 col-12">
          <input
            (change)="onChangeDownloadBox($event, document.documentValue)"
            class="form-check-input"
            type="checkbox"
            name="passwordOptions"
            [id]="document.documentValue"
            [disabled]="document.disabled"
            [value]="document.documentValue"
          />
          <label class="form-check-label mb-2" [for]="document.documentValue">
            {{ document.documentName }}</label
          >
        </div>
      </ng-container>
    </div>
    <div class="col-md-12 text-center">
      <button
        [disabled]="
          !selectedDownloadDocTypes ||
          selectedDownloadDocTypes.length < 1 ||
          disableButtons
        "
        (click)="downloadDocuments()"
        type="submit"
        class="btn btn-primary"
      >
        {{ "downloadAndReqDocuments.download" | cxTranslate }}
      </button>
    </div>

    <div class="col-12 mt-3">
      <h5 class="text-black">
        {{ "downloadAndReqDocuments.designdocuments" | cxTranslate }}
      </h5>
      <ng-container *ngFor="let email of emailTypes; let i = index">
        <div class="form-check form-check-inline mb-2 col-12">
          <input
            (change)="onChangeMailBox($event, email.value)"
            class="form-check-input"
            type="checkbox"
            name="passwordOptions"
            [id]="email.value"
            [value]="email.value"
            [disabled]="email.disabled"
            [checked]="email.checked"
          />
          <label class="form-check-label mb-2" [for]="email.value">
            {{ email.emailType }}</label
          >
        </div>
      </ng-container>
    </div>
    <div class="col-md-12 text-center">
      <button
        [disabled]="
          !selectedReqDocTypes ||
          selectedReqDocTypes.length < 1 ||
          disableButtons
        "
        (click)="reqForDownloadDocuments()"
        type="submit"
        class="btn btn-primary"
      >
        {{ "downloadAndReqDocuments.approve" | cxTranslate }}
      </button>
    </div>
  </div>
</div>
