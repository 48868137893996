import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Country, GlobalMessageService, GlobalMessageType, OccSiteAdapter, Region } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SloanCpqUserService } from 'src/app/common/services/sloan-cpq-user.service';
import { SLOAN_CPQ_USER_KEY } from 'src/app/constants/storage.constant';
import { SloanUser } from 'src/app/models/sloan-user';

@Component({
  selector: 'app-sloan-cpq-user-profile',
  templateUrl: './sloan-cpq-user-profile.component.html',
  styleUrls: ['./sloan-cpq-user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OccSiteAdapter],
})
export class SloanCpqUserProfileComponent implements OnInit {
  editAccountForm: UntypedFormGroup;
  userTitles: any = [];
  userRoles: any = [];
  isCountryLoading = false;
  countries$: Observable<Country[]>;
  isRegionLoading = false;
  regions$: Observable<Region[]>;
  countriesArray: any = [];
  regionsArray: any = [];
  userData: any = {};
  userId;
  disableSubmitBtn = false;
  constructor(
    private fb: UntypedFormBuilder,
    public occSiteAdapter: OccSiteAdapter,
    private sloanCpqUsersService: SloanCpqUserService,
    private cdr: ChangeDetectorRef,
    private globalMessageService: GlobalMessageService,
    private userService: UserAccountFacade
  ) {}

  ngOnInit(): void {
    this.editAccountForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      titleCode: ['', Validators.required],
      roleId: ['', Validators.required],
      companyName: ['', Validators.required],
      phone: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      countryIso: ['', Validators.required],
      zipcode: ['', Validators.required],
      streetAddress: [''],
    });
    this.getUserInfo();
    this.getUserInfoWithAPI();
    this.getTitles();
    this.getRoles();
    this.getCountries();
  }

  getTitles() {
    this.sloanCpqUsersService.getTitles().subscribe((data) => {
      this.userTitles = data;
      // console.log(this.userTitles);
    });
  }

  getRoles() {
    this.sloanCpqUsersService.getRoles().subscribe((data) => {
      this.userRoles = data;
      // console.log(this.userRoles);
    });
  }

  getUserInfoWithAPI() {
    this.sloanCpqUsersService.getCPQUserData();
    this.sloanCpqUsersService.loadUserData().subscribe((res) => {
      this.setDataForForm(res);
    });
  }

  setDataForForm(data: SloanUser) {
    if (data && Object.keys(data).length > 0 && data.constructor === Object) {
      this.userData.firstName = data.firstName;
      this.userData.lastName = data.lastName;
      let email = data.uid;
      if (data.uid.includes(SLOAN_CPQ_USER_KEY)) {
        email = data.uid.split(SLOAN_CPQ_USER_KEY)[1];
      }
      this.userData.email = email;
      this.userData.titleCode = data.titleCode;
      this.userData.roleId = data.userRole?.roleId;
      this.userData.companyName = data.defaultAddress?.companyName;
      this.userData.phone = data.defaultAddress?.phone;
      this.userData.city = data.defaultAddress?.town;
      this.userData.streetAddress = data.defaultAddress?.line1;
      this.userData.state = data.defaultAddress?.region
        ? data.defaultAddress?.region?.isocode
        : data.defaultAddress?.region;
      this.userData.countryIso = data.defaultAddress?.country
        ? data.defaultAddress?.country?.isocode
        : data.defaultAddress?.country;
      this.userData.zipcode = data.defaultAddress?.postalCode;
      if (this.userData) {
        this.checkZipCodeValidation(this.userData.countryIso);
        this.editAccountForm.patchValue(this.userData);
      }
      this.countrySelected(this.userData.countryIso);
    }
    this.cdr.markForCheck();
  }

  getUserInfo() {
    // TODO:Spartacus - Method 'get' was removed from Class 'UserService'. Use 'UserAccountFacade.get()' from '@spartacus/user' instead.
    this.userService.get().subscribe((userprofiledata: any) => {
      // console.log(userprofiledata);
      const data = userprofiledata;
      this.setDataForForm(data);
    });
  }

  resizeIframe(obj) {
    obj.style.height = obj.contentWindow.document.body.scrollHeight + 'px';
  }

  getCountries() {
    this.isCountryLoading = true;
    this.countries$ = this.occSiteAdapter.loadCountries().pipe(tap(() => (this.isCountryLoading = false)));
  }
  checkZipCodeValidation(selectedCountry) {
    if (selectedCountry === 'IN') {
      let pattern = '[0-9]{6}';
      this.editAccountForm.controls['zipcode'].setValidators(
        Validators.compose([Validators.required, Validators.pattern(pattern)])
      );
    }
    if (selectedCountry === 'CA') {
      let pattern = '[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][ ]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]';
      this.editAccountForm.controls['zipcode'].setValidators(
        Validators.compose([Validators.required, Validators.pattern(pattern)])
      );
    }
    if (selectedCountry === 'US') {
      let pattern = '[0-9]{5}-[0-9]{4}|[0-9]{5}';
      this.editAccountForm.controls['zipcode'].setValidators(
        Validators.compose([Validators.required, Validators.pattern(pattern)])
      );
    }
  }
  countrySelected(isocode) {
    if (isocode) {
      this.isRegionLoading = true;
      this.regions$ = this.occSiteAdapter.loadRegions(isocode).pipe(tap(() => (this.isRegionLoading = false)));
    } else {
      this.regions$ = null;
    }
    // TODO:Spartacus - Method 'get' was removed from Class 'UserService'. Use 'UserAccountFacade.get()' from '@spartacus/user' instead.
    this.editAccountForm.get('countryIso').valueChanges.subscribe((selectedCountry) => {
      this.checkZipCodeValidation(selectedCountry);
    });
  }

  submitForm() {
    if (this.editAccountForm.valid) {
      this.sloanCpqUsersService.updateProfile(this.editAccountForm.value).subscribe(
        (response) => {
          // console.log('sucess');
          this.globalMessageService.add({ key: 'messages.savedSucesfully' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
          this.getUserInfoWithAPI();
          this.disableSubmitBtn = false;
        },
        (error) => {
          // console.log('Error!', error);
          this.disableSubmitBtn = false;
        }
      );
      // console.log();
    } else {
      this.editAccountForm.markAllAsTouched();
    }
  }
}
