import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalMessageService, GlobalMessageType, OccEndpointsService } from '@spartacus/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  SLOAN_CPQ_GET_VERTICAL,
  SLOAN_CPQ_PROJECT_ARCHIVE,
  SLOAN_CPQ_PROJECT_LIST,
  SLOAN_CPQ_PROJECT_SAVE,
} from 'src/app/constants/api.endpoint.constant';
import { Vertical } from 'src/app/models/configurator.model';
import { Projects } from 'src/app/models/projects.model';

@Injectable({
  providedIn: 'root',
})
export class SloanCpqProjectService {
  projectsList$: BehaviorSubject<Projects> = new BehaviorSubject(null);
  existingProjectsList$: BehaviorSubject<Projects> = new BehaviorSubject(null);
  verticalList$: BehaviorSubject<Vertical[]> = new BehaviorSubject(null);
  projectLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  projectId;
  recordsPerPage: {};
  lastPage$ = new BehaviorSubject(false);
  response: any;
  constructor(
    private http: HttpClient,
    private endPointService: OccEndpointsService,
    private spinner: NgxSpinnerService,
    private globalMessageService: GlobalMessageService
  ) {
    this.response = [];
  }

  getProjects(currentPage = 0) {
    this.recordsPerPage = {
      currentPage: currentPage,
      pageSize: 20,
    };
    this.spinner.show();
    this.projectLoading$.next(true);
    this.http
      .get<Projects>(
        this.endPointService.buildUrl(SLOAN_CPQ_PROJECT_LIST, {
          queryParams: {
            ...this.recordsPerPage,
          },
        })
      )
      .subscribe(
        (res) => {
          this.response = currentPage !== 0 ? [...this.response, ...res.carts] : [...res.carts];
          this.projectsList$.next({ carts: this.response });
          this.projectLoading$.next(false);
          this.spinner.hide();
          res?.carts.length < 20 || res?.carts.length === 0 ? this.lastPage$.next(true) : this.lastPage$.next(false);
        },
        (err) => {
          this.projectLoading$.next(false);
          this.spinner.hide();
          this.projectsList$.next({ carts: [] });
          this.globalMessageService.add({ key: 'user.errorMsg' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      );
  }

  getExistingProjects() {
    this.recordsPerPage = {
      currentPage: 0,
      pageSize: 40,
    };
    this.spinner.show();
    this.projectLoading$.next(true);
    this.http
      .get<Projects>(
        this.endPointService.buildUrl(SLOAN_CPQ_PROJECT_LIST, {
          queryParams: {
            ...this.recordsPerPage,
          },
        })
      )
      .subscribe(
        (res) => {
          let response = res.carts;
          this.existingProjectsList$.next({ carts: response });
          this.projectLoading$.next(false);
          this.spinner.hide();
        },
        (err) => {
          this.projectLoading$.next(false);
          this.spinner.hide();
          this.existingProjectsList$.next({ carts: [] });
          this.globalMessageService.add({ key: 'user.errorMsg' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      );
  }

  loadProjects(): Observable<Projects> {
    return this.projectsList$.asObservable();
  }

  existingLoadProjects(): Observable<Projects> {
    return this.existingProjectsList$.asObservable();
  }

  isProjectsLoading$(): Observable<boolean> {
    return this.projectLoading$.asObservable();
  }

  getVerticals() {
    this.http.get<any>(this.endPointService.buildUrl(SLOAN_CPQ_GET_VERTICAL)).subscribe((res) => {
      this.verticalList$.next(res.verticals as Vertical[]);
    });
  }

  loadVerticals(): Observable<Vertical[]> {
    return this.verticalList$.asObservable();
  }

  archiveProject(projectCode) {
    this.http
      .patch(
        this.endPointService.buildUrl(SLOAN_CPQ_PROJECT_ARCHIVE, {
          urlParams: {
            projectId: projectCode,
          },
        }),
        null
      )
      .subscribe(
        (res) => {
          this.getProjects();
        },
        (err) => {
          console.log(err);
        }
      );
  }

  saveProject(projectdata) {
    return this.http.post(this.endPointService.buildUrl(SLOAN_CPQ_PROJECT_SAVE), projectdata);
  }
}
