import { NgModule } from '@angular/core';
import { LoginEvent, LogoutEvent, provideConfig } from "@spartacus/core";
import { NavigationEvent } from '@spartacus/storefront';
import { BaseTmsModule, TmsConfig } from "@spartacus/tracking/tms/core";
import { GtmModule } from "@spartacus/tracking/tms/gtm";
import { SloanCpqOnClickEvent, SloanCpqOnSearchEvent } from 'src/app/store/custom-spartacus-events';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [],
  imports: [
    BaseTmsModule.forRoot(),
    GtmModule
  ],
  providers: [provideConfig(<TmsConfig>{
    tagManager: {
      gtm: {
        gtmId : environment.gtmId,
        events: [
          NavigationEvent,
          LoginEvent,
          LogoutEvent,
          SloanCpqOnSearchEvent,
          SloanCpqOnClickEvent
        ],
      },
    },
  })]
})
export class TagManagementFeatureModule { }
