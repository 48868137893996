<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title text-center w-100" id="modal-basic-title">
      <ng-container *ngIf="fromDownloadReq">
        {{ "approveDesign.reqDocument" | cxTranslate }}
      </ng-container>
      <ng-container *ngIf="!fromDownloadReq">
        {{ "approveDesign.approveDesign" | cxTranslate }}
      </ng-container>
    </h4>
  </div>
  <div class="modal-body">
    <div
      *ngIf="showErrorMessage"
      class="alert alert-danger alert-dismissible fade show"
      role="alert"
    >
      <strong>{{
        "configurators.approveDesign.errorMsg" | cxTranslate
      }}</strong>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="col-12">
      <h5 class="mb-3">{{ "approveDesign.selectedDesigns" | cxTranslate }}:</h5>
      <ng-container *ngIf="selectedEntries && selectedEntries.length > 0">
        <ng-container *ngFor="let configurator of selectedEntries">
          <div class="col-md-12">
            <img src="assets/images/check-blue.svg" />
            <label class="ml-4">{{ configurator.configurationName }}</label>
          </div>
        </ng-container>
      </ng-container>
      <p class="mt-2" *ngIf="!fromDownloadReq">
        {{ "approveDesign.confirmation" | cxTranslate }}
        <strong>
          {{ "approveDesign.noEdits" | cxTranslate }}
        </strong>
      </p>
      <p class="mt-2">
        {{ "approveDesign.postConfirmation" | cxTranslate }}
      </p>
      <p class="mt-2">
        {{ "approveDesign.postConfirmation2" | cxTranslate }}
      </p>
      <ng-container *ngIf="!fromDownloadReq">
        <h5 class="mt-5">
          {{ "approveDesign.sloanCustomerCareCenter" | cxTranslate }}
        </h5>
        <label>{{ "approveDesign.phone" | cxTranslate }}</label>
        <p>{{ phoneNumber }}</p>
        <label>{{ "approveDesign.email" | cxTranslate }}</label>
        <p>
          <a style="text-decoration: underline;">{{ email }}</a>
        </p>
        <label>{{ "approveDesign.hours" | cxTranslate }}</label>
        <p>
          {{ workingDays }} <br />
          {{ "approveDesign.time" | cxTranslate }}
        </p>
      </ng-container>
    </div>
    <div class="modal-footer col-12 pt-4 mt-3 float-left">
      <button
        type="button"
        class="btn btn-default float-left"
        (click)="closeModal()"
      >
        {{ "approveDesign.cancel" | cxTranslate }}
      </button>
      <button
        type="submit"
        class="btn btn-primary float-right"
        (click)="approveDesign()"
      >
        {{ "approveDesign.confirm" | cxTranslate }}
      </button>
    </div>
  </div>
</div>
