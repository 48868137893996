<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="float-right btn-close text-black" (click)="closeModal()">
            <img src="../../../assets/images/close-sm.svg   "/>
        </button>
        <h4 class="modal-title text-center w-100 mb-3" id="modal-basic-title">
            {{'sloanForgotPasswordForm.forgotPassword' | cxTranslate}}</h4>
    </div>
    <div class="modal-body">
        <p class="text-center">
            {{'sloanForgotPasswordForm.forgotPasswordHeading' | cxTranslate}}
        </p>
        <form (ngSubmit)="requestForgotPasswordEmail()" [formGroup]="form">
            <div class="col-12 login-forms p-4 nopad">
                <div class="form-group">
                    <label class="form-label">{{'sloanForgotPasswordForm.emailAddress' | cxTranslate}}</label>
                    <input type="email" class="form-control" id="email" formControlName="userEmail" />
                </div>
                <span class="highlight"></span>
                <cx-form-errors [control]="form.get('userEmail')"></cx-form-errors>
            </div>
            <div class="modal-footer text-center">                
                <button type="submit" [disabled]="form.invalid || disableSubmitBtn" class="btn btn-primary">
                    {{'sloanForgotPasswordForm.sendPasswordResetLinkBtn' | cxTranslate}}
                </button>
            </div>
        </form>
    </div>
</div>