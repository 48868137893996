import { CxEvent } from '@spartacus/core';

/**
 * Will be thrown in case lazy loaded modules are loaded and instantiated.
 *
 * This event is thrown for cms driven lazy loaded feature modules amd it's
 * dependencies
 */
export class SloanCpqOnSearchEvent extends CxEvent {
  static readonly type = 'SloanCpqOnSearchEvent';
  event?: string = 'sloancpq-on-search';
  searchTerm?: string;
}

export class SloanCpqOnClickEvent extends CxEvent {
  static readonly type = 'SloanCpqOnClickEvent';
  event?: string = 'sloancpq-on-click';
  title?: string;
}
