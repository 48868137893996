import { Component, OnInit } from '@angular/core';
import { CmsNavigationComponent, AuthService } from '@spartacus/core';
// TODO:Spartacus - // TODO:Spartacus - Class ModalService has been removed and is no longer part of the public API. Use 'LaunchDialogService' instead. For more information, see the 5.0 migration guide.
import { CategoryNavigationComponent, CmsComponentData, LaunchDialogService, NavigationComponent, NavigationService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-sloan-bottom-header',
  templateUrl: './sloan-bottom-header.component.html',
  styleUrls: ['./sloan-bottom-header.component.scss']
})
export class SloanBottomHeaderComponent  implements OnInit {

  node$: Observable<any> = this.service.createNavigation(
    this.componentData.data$
  );
  // styleClass$: Observable<string> = this.componentData.data$.pipe(
  //   map((d) => d?.styleClass)
  // );
  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: NavigationService,
    protected launchDialogService: LaunchDialogService,) {
    // super(componentData, service);
  }

  ngOnInit(): void {
  }

  open() {

  }

}
