import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
// TODO:Spartacus - // TODO:Spartacus - Class ModalService has been removed and is no longer part of the public API. Use 'LaunchDialogService' instead. For more information, see the 5.0 migration guide.
import { SloanLoginComponent } from '../sloan-login/sloan-login.component';
import { SloanSignupComponent } from '../sloan-signup/sloan-signup.component';

@Component({
  selector: 'app-slaon-three-kit-login-popup',
  templateUrl: './slaon-three-kit-login-popup.component.html',
  styleUrls: ['./slaon-three-kit-login-popup.component.scss']
})
export class SlaonThreeKitLoginPopupComponent implements OnInit {

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  closeModal(){
    this.ngbActiveModal.dismiss();
  }

  openSignUpModal(){
    this.closeModal();
    this.modalService.open(SloanSignupComponent);
  }

  openLoginModal(){
    this.closeModal();
    this.modalService.open(SloanLoginComponent);
  }
}
