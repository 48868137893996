<p>sloan-cms-faq works!</p>
<ng-template>
    <ng-container *ngFor="let question of questions; let i = index">
      <div class="accordion-item">
                 <!-- TODO: Changed by Pushpak  [attr.aria-labelledby] from  [aria-labelledby]-->

        <h2 class="accordion-header" [id]="'heading' + i">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#collapse' + i"
            aria-expanded="true"
            [attr.aria-controls]="'collapse' + i"
          >
            <b>{{ question.question }}</b>
          </button>
        </h2>
                 <!-- TODO: Changed by Pushpak  [attr.aria-labelledby] from  [aria-labelledby]-->

        <div
          [id]="'collapse' + i"
          class="accordion-collapse collapse"
          [attr.aria-labelledby]="'heading' + i"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            {{ question.answer }}
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template> 