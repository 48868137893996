<div class="modal-content">
  <div class="modal-header">
    <div class="mb-3">
      <button
        type="button"
        class="btn-close float-right"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="closeModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <h4 class="modal-title text-center w-100" id="modal-basic-title">
      {{ "sinkConfigHelp.helpHeading" | cxTranslate }}
    </h4>
  </div>
  <div class="modal-body">
    <div class="col-12">
      <div class="row">
        <div
          class="col-6"
          *ngIf="userdataJson && userdataJson.cpqAccountManager"
        >
          <h6>
            <strong>{{ "sinkConfigHelp.contactHeading" | cxTranslate }}</strong>
          </h6>
          <label class="mt-3">
            <strong>{{ userdataJson.cpqAccountManager.name }}</strong> </label
          ><br />
          <label> {{ userdataJson.cpqAccountManager.phone }} </label><br />
          <a>{{ userdataJson.cpqAccountManager.email }}</a>
        </div>
        <div class="col-6">
          <h6>
            <strong>{{
              "sinkConfigHelp.customerCareHeading" | cxTranslate
            }}</strong>
          </h6>
          <label class="mt-3">
            <strong>{{
              "sinkConfigHelp.phoneLabel" | cxTranslate
            }}</strong> </label
          ><br />
          <label> {{ phoneNumber }} </label><br />
          <label class="mt-3">
            <strong>{{
              "sinkConfigHelp.emailLabel" | cxTranslate
            }}</strong> </label
          ><br />
          <a> {{ email }} </a><br />
          <label class="mt-3">
            <strong>
              {{ "sinkConfigHelp.hoursLabel" | cxTranslate }}
            </strong> </label
          ><br />
          <label> {{ workingDays }} </label><br />
          <label>
            {{ workingHours }}
          </label>
        </div>
      </div>
    </div>
    <div class="modal-footer col-12 pt-4 mt-3 float-left">
      <button
        type="button"
        class="btn btn-default float-left"
        (click)="closeModal()"
      >
        {{ "sinkConfigHelp.cancelBtn" | cxTranslate }}
      </button>
      <button
        type="submit"
        class="btn btn-primary float-right"
        (click)="openFaqs()"
      >
        {{ "sinkConfigHelp.faqsBtn" | cxTranslate }}
      </button>
    </div>
  </div>
</div>
