import { Component, OnInit } from '@angular/core';
import data from './faq.json';

@Component({
  selector: 'app-sloan-cpq-faq-tc',
  templateUrl: './sloan-cpq-faq-tc.component.html',
  styleUrls: ['./sloan-cpq-faq-tc.component.scss']
})
export class SloanCpqFaqTcComponent implements OnInit {

  constructor() { }

   active = 'general';
   public title = 'faqs';
   currentOrientation = 'horizontal';
   faqquestions = data;
   one = '1';
  ngOnInit(): void {
    console.log(data.faq);
  }

  toggleTitle() {
    this.title = this.title === 'faqs' ? 'tncs' : 'faqs';
    return false;
  }

  openTC(){
    window.open('https://www.sloan.com/terms-of-use', '_blank')
  }

}
