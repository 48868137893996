<div class="modal-content rounded-0 sign-up">
  <div class="modal-header">
    <button type="button" class="float-right btn-close" (click)="closeModal()">
      <img src="assets/images/close-lg.svg" />
    </button>
    <h4 class="modal-title text-center w-100" id="modal-basic-title">
      {{ "projectDetails.projectDetails" | cxTranslate }}
    </h4>
  </div>
  <div class="modal-body" *ngIf="projectDetail">
    <h5 class="titleSec">
      {{ "projectDetails.newProjectDetails" | cxTranslate }}
    </h5>
    <div class="row mb-3">
      <div class="col-md-12">
        <label for="projectName" class="form-label">
          {{ "projectDetails.projectName" | cxTranslate }}</label
        >
        <input
          type="text"
          [(ngModel)]="projectDetail.name"
          class="form-control form-control-lg"
          id="projectName"
          placeholder="O'Hare Restrooms"
          disabled
          readonly
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-6">
        <label for="estimatedInstallDate" class="form-label">
          {{ "projectDetails.estimatedInstallDate" | cxTranslate }}</label
        >
        <input
          type="text"
          value="{{ convertDate(projectDetail.cpqInstallationDate) }}"
          class="form-control"
          id="estimatedInstallDate"
          disabled
          readonly
        />
      </div>
      <div class="col-md-6">
        <label for="cpqVerticalId" class="form-label">
          {{ "projectDetails.vertical" | cxTranslate }}</label
        >
        <select
          id="cpqVerticalId"
          [(ngModel)]="projectDetail.cpqVerticalId"
          class="form-control"
          disabled
          readonly
        >
          <option
            *ngFor="let vertical of verticals$ | async"
            [value]="vertical.verticalId"
          >
            {{ vertical.verticalName }}
          </option>
        </select>
      </div>
    </div>
    <h5 class="titleSec">
      {{ "projectDetails.projectLocation" | cxTranslate }}
    </h5>
    <div class="row mb-3">
      <div class="col-md-12">
        <label for="streetAddress1" class="form-label">
          {{ "projectDetails.streetAddress1" | cxTranslate }}</label
        >
        <input
          type="text"
          *ngIf="projectDetail.deliveryAddress"
          [(ngModel)]="projectDetail.deliveryAddress.line1"
          class="form-control"
          id="streetAddress1"
          placeholder="111 Main Street"
          disabled
          readonly
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-12">
        <label for="streetAddress2" class="form-label">
          {{ "projectDetails.streetAddress2" | cxTranslate }}</label
        >
        <input
          *ngIf="projectDetail.deliveryAddress"
          type="text"
          [(ngModel)]="projectDetail.deliveryAddress.line2"
          class="form-control"
          id="streetAddress2"
          placeholder="Suite 1111"
          disabled
          readonly
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-12">
        <label for="country" class="form-label">
          {{ "projectDetails.country" | cxTranslate }}</label
        >
        <select
          id="country"
          class="form-control"
          *ngIf="projectDetail.deliveryAddress.country"
          [(ngModel)]="projectDetail.deliveryAddress.country.isocode"
          disabled
          readonly
        >
          <option
            *ngFor="let country of countries$ | async"
            [value]="country.isocode"
          >
            {{ country.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4">
        <label for="zipCode" class="form-label">
          {{ "projectDetails.zipCode" | cxTranslate }}</label
        >
        <input
          *ngIf="projectDetail.deliveryAddress"
          type="text"
          [(ngModel)]="projectDetail.deliveryAddress.postalCode"
          class="form-control"
          id="zipCode"
          disabled
          readonly
        />
      </div>
      <div class="col-md-4">
        <label for="town" class="form-label">
          {{ "projectDetails.city" | cxTranslate }}</label
        >
        <input
          *ngIf="projectDetail.deliveryAddress"
          type="text"
          [(ngModel)]="projectDetail.deliveryAddress.town"
          class="form-control"
          id="town"
          disabled
          readonly
        />
      </div>
      <div class="col-md-4 mb-3">
        <label for="state" class="form-label">
          {{ "projectDetails.stateOrProvince" | cxTranslate }}</label
        >
        <select
          id="state"
          *ngIf="projectDetail.deliveryAddress.region"
          [(ngModel)]="projectDetail.deliveryAddress.region.isocode"
          class="form-control"
          disabled
          readonly
        >
          <option
            *ngFor="let state of regions$ | async"
            [value]="state.isocode"
          >
            {{ state.name }}
          </option>
        </select>
      </div>
    </div>
    <h5 class="titleSec">
      {{ "projectDetails.notes" | cxTranslate }}
    </h5>
    <div class="row mb-3">
      <div class="col-md-12">
        <textarea
          class="form-control"
          [(ngModel)]="projectDetail.description"
          rows="3"
          disabled
          readonly
        ></textarea>
      </div>
    </div>
  </div>
</div>
