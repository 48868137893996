<div class="row width-100 pd-10">
  <!-- <div class="col-md-8 d-none d-sm-none d-md-block d-lg-block nopad pr-4">
    <div ngbDropdown class="float-right">
      <a href="javascript:void(0)" id="language" ngbDropdownToggle>
        <img class="globe" src="assets/images/globe.svg" />
      </a>
      <div
        ngbDropdownMenu
        class="dropdown dropdown-menu"
        aria-labelledby="language"
      >
        <ng-container *ngFor="let language of languages$ | async">
          <button ngbDropdownItem (click)="selectLanguage(language.isocode)">
            {{ language.name }}
          </button>
        </ng-container>
      </div>
    </div>
  </div> -->
  <div class="col-md-12 nopad">
    <div ngbDropdown class="float-right">
      <a
        href="javascript:void(0)"
        id="myaccount"
        ngbDropdownToggle
        class="d-none d-sm-none d-md-block d-lg-block"
      >
        <i class="far fa-user"></i>
        {{ "myAccount.myAccountLabel" | cxTranslate }}
      </a>
      <a
        href="javascript:void(0)"
        id="myaccount"
        ngbDropdownToggle
        class="d-block d-sm-block d-md-none d-lg-none"
      >
        <i class="fa fa-navicon fa-2x"></i>
      </a>
      <!-- <button class="btn btn-primary"  id="dropdownBasic1" ngbDropdownToggle>{{ 'miniCart.orderTools' | cxTranslate }}</button > -->
      <div
        *ngIf="!(isLoggedIn$ | async)"
        ngbDropdownMenu
        class="dropdown dropdown-menu myaccount-menu"
        aria-labelledby="myaccount"
      >
        <button (click)="openLoginModal()" ngbDropdownItem>
          {{ "myAccount.loginBtn" | cxTranslate }}
        </button>
        <button ngbDropdownItem (click)="openGlobalHelpModal()">
          {{ "myAccount.helpBtn" | cxTranslate }}
        </button>
        <button (click)="openSignUpModal()" ngbDropdownItem>
          {{ "myAccount.createAccountBtn" | cxTranslate }}
        </button>
      </div>

      <div
        *ngIf="isLoggedIn$ | async"
        ngbDropdownMenu
        class="dropdown dropdown-menu myaccount-menu"
        aria-labelledby="myaccount"
      >
        <button (click)="navigateToProfile()" ngbDropdownItem>
          {{ "myAccount.myProfileBtn" | cxTranslate }}
        </button>
        <button ngbDropdownItem (click)="navigateToProjects()">
          {{ "myAccount.myProjectsBtn" | cxTranslate }}
        </button>
        <button ngbDropdownItem (click)="openSinkHelpModal()">
          {{ "myAccount.helpBtn" | cxTranslate }}
        </button>
        <button (click)="logout()" ngbDropdownItem>
          {{ "myAccount.logoutBtn" | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>
