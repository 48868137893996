import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanCpqFaqProjectsComponent } from './sloan-cpq-faq-projects.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { SloanCmsFaqComponent } from './sloan-cms-faq/sloan-cms-faq.component';



@NgModule({
  declarations: [SloanCpqFaqProjectsComponent, SloanCmsFaqComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CategoryNavigationComponent : {
          component: SloanCpqFaqProjectsComponent
        }
      }
    } as CmsConfig),
  ],
  exports: [SloanCpqFaqProjectsComponent],
})
export class SloanCpqFaqProjectsModule { }
