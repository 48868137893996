import { SloanMyAccountModule } from './sloan-my-account/sloan-my-account.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanBottomHeaderModule } from './sloan-bottom-header/sloan-bottom-header.module';
import { SloanTopHeaderModule } from './sloan-top-header/sloan-top-header.module';
import { SloanMyAccountComponent } from './sloan-my-account/sloan-my-account.component';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SloanBottomHeaderModule,
    SloanTopHeaderModule,
    SloanMyAccountModule,
    NgbModule
  ]
})
export class SloanHeadersModule { }
