import { Component, OnInit } from '@angular/core';
import { CmsNavigationComponent } from '@spartacus/core';
import {
  CmsComponentData,
  FooterNavigationComponent,
  NavigationNode,
  NavigationService,
} from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
declare var MktoForms2: any;

@Component({
  selector: 'app-sloan-cpq-footer',
  templateUrl: './sloan-cpq-footer.component.html',
  styleUrls: ['./sloan-cpq-footer.component.scss'],
})
export class SloanCpqFooterComponent
  extends FooterNavigationComponent
  implements OnInit
{
  componentData$: Observable<CmsNavigationComponent> = this.componentData.data$;
  node$: Observable<NavigationNode> = this.service.getNavigationNode(
    this.componentData.data$
  );
// TODO:Spartacus - The type of property 'styleClass$: Observable<string>' changed to: 'styleClass$: Observable<string | undefined>' 
  styleClass$: Observable<string | undefined> = this.componentData.data$.pipe(
    map((d) => d?.styleClass)
  );

  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: NavigationService
  ) {
    super(componentData, service);
  }

  ngOnInit(): void {
    MktoForms2.loadForm(
      '//info.sloan.com',
      '905-IOZ-761',
      1030,
      function (form) {
        const inputElement = document.getElementById(
          'Email'
        ) as HTMLInputElement;
        inputElement.placeholder = 'Enter email';
        const button = document.querySelector(
          '.mktoButtonWrap .mktoButton'
        ) as HTMLInputElement;
        button.innerText = 'Sign Up';
        // Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
          form.getFormElem().hide();
          const confirmation = document.querySelector(
            '.newsLetterConfirmation'
          ) as HTMLInputElement;
          confirmation.style.display = 'block';
          return false;
        });
      }
    );
  }
}
