import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule, CmsConfig } from '@spartacus/core';
import { SloanCMSFAQComponent } from './sloan-cms-faq.component';



@NgModule({
  declarations: [SloanCMSFAQComponent],
  exports :[SloanCMSFAQComponent],
  imports: [
    CommonModule,
    // ConfigModule.withConfig({
    //   cmsComponents: {
    //     CategoryNavigationComponent: {
    //       component: SloanCMSFAQComponent
    //     }
    //   }
    // } as CmsConfig),
  ]
})
export class SloanCmsFaqModule { }
