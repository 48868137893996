<div class="row width-100 p-3 d-none d-sm-none d-md-block d-lg-block">
  <div class="col-lg-12 p-0">
    <div class="col-lg-9 float-left px-0">
      <cx-navigation-ui [node]="node$ | async" [flyout]="false" [ngClass]="styleClass$ | async"></cx-navigation-ui>
    </div>
    <div class="col-lg-3 float-left pt-5">
      <div>
        <h5 class="hidden-xs">Sign up for emails</h5>
        <form class="form-inline pt-2" id="mktoForm_1030"></form>
        <div class="newsLetterConfirmation">Thanks for signing up!</div>
      </div>
      <div class="row pb-3">
        <div class="pt-5 col-md-12">
          <h5 class="hidden-xs">Follow us on social media</h5>
          <cx-page-slot position="FooterSocialMediaSlot"></cx-page-slot>
        </div>
        <div class="pt-5 col-md-12">
          <h5 class="hidden-xs">Listen to the podcast</h5>
          <cx-page-slot position="FooterPodcastMediaSlot"></cx-page-slot>
        </div>
      </div>
    </div>
  </div>
  <div class="row footerBorder ml-3 pt-4">
    <div class="col-md-2">
      <cx-page-slot position="BottomFooterLogoSlot"></cx-page-slot>
    </div>
    <div class="col-md-10">
      <cx-page-slot position="BottomFooterSlot"></cx-page-slot>
    </div>
  </div>
</div>
