<ng-container>
  <div class="row sloanProjectSettings">
    <div class="col-6 nopad">
      <input type="checkbox" [checked]="isChecked(configurator.configurationUid)" (change)="changeCheckBox($event)" />
    </div>
    <div class="col-6 text-right nopad">
      <div ngbDropdown container="body">
        <button class="btn" ngbDropdownToggle>
          <img src="assets/images/ellipsis.svg" />
        </button>
        <div ngbDropdownMenu class="w-100 dropdown-menu">
          <a *ngIf="isConfigLocked()" (click)="openAproveDesign()" ngbDropdownItem>
            <img src="assets/images/project-approve-design.svg" />
            {{ 'configuratorsList.approveDesigns' | cxTranslate }}
          </a>
          <a *ngIf="canShowOption()" (click)="openViewNotes()" ngbDropdownItem>
            <img src="assets/images/project-notes.svg" />
            {{ 'configuratorsList.viewNotes' | cxTranslate }}
          </a>
          <a *ngIf="canShowOption()" ngbDropdownItem (click)="duplicateConfig()">
            <img src="assets/images/project-duplicate.svg" />
            {{ 'configuratorsList.duplicate' | cxTranslate }}
          </a>
          <a *ngIf="isConfigLocked()" (click)="deleteConfig()" ngbDropdownItem>
            <img src="assets/images/project-delete.svg" />
            {{ 'configuratorsList.delete' | cxTranslate }}
          </a>
          <a
            *ngIf="isChecked(configurator.configurationUid) && configurator.status === 'APPROVED_LOCKED'"
            class="px-3 py-3 disabled text-success"
          >
            <img src="assets/images/project-approve-design.svg" />
            {{ 'configuratorsList.approved' | cxTranslate }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <a class="sloanProjectCard">
    <div class="img-hover">
      <ng-container *ngIf="configurator.threeKitData && configurator.threeKitData.length > 0">
        <img [src]="configurator.threeKitData[0].metadata.tileImage" class="img-fluid img-thumbnail" alt="..." />
      </ng-container>
      <ng-container *ngIf="!configurator.threeKitData || configurator.threeKitData.length < 1">
        <img src="assets/images/1.jpg" class="img-fluid img-thumbnail" alt="..." />
      </ng-container>
      <div class="projectLocking" *ngIf="isConfigLocked()" (click)="editConfig()">
        <a>
          <img src="assets/images/project-edit.svg" />
        </a>
      </div>
    </div>
  </a>
  <h3 class="projectTile">{{ configurator.configurationName }}</h3>
  <p *ngIf="configurator.status === 'EXPIRED'" class="text-danger text-center">
    {{ 'configuratorsList.configExpired' | cxTranslate }}
    <img class="globe" src="assets/images/circle-error.svg" />
  </p>
  <p class="text-success text-center" *ngIf="configurator.status === 'APPROVED_LOCKED'">
    {{ 'configuratorsList.approvedLocked' | cxTranslate }}
    <img class="globe" src="assets/images/circle-checked-outline-blue.svg" />
  </p>
</ng-container>
