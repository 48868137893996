import { SloanCpqConfirmDialogComponent } from './sloan-cpq-confirm-dialog.component';
import { I18nModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [SloanCpqConfirmDialogComponent],
  imports: [
    CommonModule, I18nModule
  ]
})
export class SloanCpqConfirmDialogModule { }
