export const SLOAN_CPQ_SIGNUP = '/cpqUsers?fields=DEFAULT';
export const SLOAN_CPQ_ROLES = '/cpqUsers/cpqUserRoles?fields=DEFAULT';
export const SLOAN_CPQ_TITLES = '/titles?fields=DEFAULT';
export const SLOAN_CPQ_USER_UPDATE = '/cpqUsers/${userId}?fields=DEFAULT';
export const SLOAN_CPQ_USER_DETAIL = '/cpqUsers/${userId}?fields=DEFAULT';
export const SLOAN_CPQ_PROJECT_LIST = '/users/current/projects?currentPage={currentPage}&fields=DEFAULT&pageSize={pageSize}&sort=yes';
export const SLOAN_CPQ_PROJECT_SAVE = '/users/current/projects?fields=DEFAULT';
export const SLOAN_CPQ_PROJECT_ARCHIVE = '/users/current/projects/${projectId}?fields=DEFAULT';
export const SLOAN_CPQ_CONFIGURATOR_LIST = '/users/current/projects/${projectId}?fields=DEFAULT';
export const SLOAN_CPQ_CONFIGURATOR_SAVE = '/users/current/projects/${projectId}?fields=DEFAULT';
export const SLOAN_CPQ_CONFIGURATOR_DETAIL = '/users/current/projects/${projectId}/${configId}?fields=DEFAULT';
export const SLOAN_CPQ_CONFIGURATOR_UPDATE = '/users/current/projects/${projectId}/${configId}?fields=DEFAULT';
export const SLOAN_CPQ_CONFIGURATOR_DELETE = '/users/current/projects/${projectId}/${configId}?fields=DEFAULT';
export const SLOAN_CPQ_DOCUMENT_URL = 'users/current/projects/${projectId}/documentRequest';
export const SLOAN_CPQ_GET_VERTICAL = 'users/current/projects/getVerticals';
export const SLOAN_CPQ_APPROVAL_DESIGN = 'users/current/projects/${projectId}/sentApproval?configIds={configId}';
export const SLOAN_CPQ_DOCUMENT_DOWNLOAD = 'users/current/projects/${projectId}/downoladDocuments';
export const SLOAN_CPQ_FORGOT_PASSWORD_REQ = '/cpqUsers/cpqforgottenpasswordtokens';
