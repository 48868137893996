import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfigModule, I18nModule, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { SloanCpqAuthGuard } from 'src/app/common/guards/sloan-cpq-auth-guard.guard';
import { SloanCpqAproveDesignComponent } from './sloan-cpq-aprove-design/sloan-cpq-aprove-design.component';
import { SloanCpqConfiguratorsListModule } from './sloan-cpq-configurators-list/sloan-cpq-configurators-list.module';
import { SloanCpqConfiguratorsSaveModule } from './sloan-cpq-configurators-save/sloan-cpq-configurators-save.module';
import { SloanCpqViewNotesComponent } from './sloan-cpq-view-notes/sloan-cpq-view-notes.component';
import { SloanDownloadAndReqDocumentsComponent } from './sloan-download-and-req-documents/sloan-download-and-req-documents.component';

@NgModule({
  declarations: [SloanCpqViewNotesComponent, SloanCpqAproveDesignComponent, SloanDownloadAndReqDocumentsComponent],
  imports: [
    CommonModule,
    BrowserModule,
    I18nModule,
    NgbModule,
    SloanCpqConfiguratorsListModule,
    FormsModule,
    ReactiveFormsModule,
    SloanCpqConfiguratorsSaveModule,
    RouterModule.forChild([
      {
        data: { pageLabel: '/Configurations', cxRoute: 'configurationlist' },
        path: null,
        canActivate: [SloanCpqAuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          configurationlist: {
            paths: ['Configurations/:code'],
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class SloanCpqConfiguratorsModule {}
