<div class="container col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-3">
  <h3 class="text-center">Account</h3>

  <h5 class="text-center">
    To make account updates, please contact <a>designersinks&#64;sloan.com</a>
  </h5>

  <form [formGroup]="editAccountForm" (ngSubmit)="submitForm()" novalidate>
    <div class="row g-3">
      <div class="col-md-6 mt-3">
        <label for="firstName" class="form-label" #firstName>First Name</label>
        <input
          type="text"
          class="form-control"
          id="firstName"
          formControlName="firstName"
        />
        <div
          *ngIf="
            editAccountForm.controls['firstName'].invalid &&
            (editAccountForm.controls['firstName'].dirty ||
              editAccountForm.controls['firstName'].touched)
          "
        >
          <div
            class="form-mandatory-text"
            *ngIf="editAccountForm.controls['firstName'].errors!.required"
          >
            First name is required
          </div>
        </div>
      </div>

      <div class="col-md-6 mt-3">
        <label for="lastName" class="form-label" #lastName>Last Name</label>
        <input
          type="text"
          class="form-control"
          formControlName="lastName"
          id="lastName"
        />
        <div
          *ngIf="
            editAccountForm.controls['lastName'].invalid &&
            (editAccountForm.controls['lastName'].dirty ||
              editAccountForm.controls['lastName'].touched)
          "
        >
          <div
            class="form-mandatory-text"
            *ngIf="editAccountForm.controls['lastName'].errors!.required"
          >
            Last name is required
          </div>
        </div>
      </div>

      <div class="col-12 mt-1">
        <label class="form-label">Email Address</label>
        <input
          type="email"
          class="form-control"
          formControlName="email"
          id="email"
        />
        <div
          *ngIf="
            editAccountForm.controls['email'].invalid &&
            (editAccountForm.controls['email'].dirty ||
              editAccountForm.controls['email'].touched)
          "
        >
          <div
            class="form-mandatory-text"
            *ngIf="editAccountForm.controls['email'].errors!.required"
          >
            Please enter email address
          </div>
        </div>
      </div>

      <div class="col-md-6 mt-3">
        <label for="firstName" class="form-label">Title</label>
        <!-- (change)="onChange($event.target.value)" -->
        <select class="form-control" formControlName="titleCode">
          <option value="" disabled selected>Title</option>
          <option *ngFor="let title of userTitles.titles" [value]="title.code">
            {{ title.name }}
          </option>
        </select>
        <div
          *ngIf="
            editAccountForm.controls['titleCode'].invalid &&
            (editAccountForm.controls['titleCode'].dirty ||
              editAccountForm.controls['titleCode'].touched)
          "
        >
          <div
            class="form-mandatory-text"
            *ngIf="editAccountForm.controls['titleCode'].errors!.required"
          >
            Please select a title
          </div>
        </div>
      </div>

      <div class="col-md-6 mt-3">
        <label for="lastName" class="form-label">Role</label>
        <select class="form-control" formControlName="roleId" id="role">
          <option value="" disabled selected>Role</option>
          <option *ngFor="let role of userRoles.roles" [value]="role.roleId">
            {{ role.roleName }}
          </option>
        </select>
        <div
          *ngIf="
            editAccountForm.controls['roleId'].invalid &&
            (editAccountForm.controls['roleId'].dirty ||
              editAccountForm.controls['roleId'].touched)
          "
        >
          <div
            class="form-mandatory-text"
            *ngIf="editAccountForm.controls['roleId'].errors!.required"
          >
            Please select a role
          </div>
        </div>
      </div>

      <div class="col-6 mt-3">
        <label class="form-label">Company Name</label>
        <input
          type="email"
          class="form-control"
          formControlName="companyName"
          id="companyName"
        />
        <div
          *ngIf="
            editAccountForm.controls['companyName'].invalid &&
            (editAccountForm.controls['companyName'].dirty ||
              editAccountForm.controls['companyName'].touched)
          "
        >
          <div
            class="form-mandatory-text"
            *ngIf="editAccountForm.controls['companyName'].errors!.required"
          >
            Please add a company name
          </div>
        </div>
      </div>

      <div class="col-6 mt-3">
        <label class="form-label">Company Phone</label>
        <input
          type="text"
          class="form-control"
          formControlName="phone"
          id="companyphone"
        />
        <div
          *ngIf="
            editAccountForm.controls['phone'].invalid &&
            (editAccountForm.controls['phone'].dirty ||
              editAccountForm.controls['phone'].touched)
          "
        >
          <div
            class="form-mandatory-text"
            *ngIf="editAccountForm.controls['phone'].errors!.required"
          >
            Please add a company phone number
          </div>
        </div>
      </div>
      <div class="col-12 mt-3 float-left">
        <label clsas="form-label">{{
          "sloansignupForm.streetAddress" | cxTranslate
        }}</label>
        <textarea
          class="form-control rounded-0"
          formControlName="streetAddress"
        >
        </textarea>
        <div
          *ngIf="
            editAccountForm.controls['streetAddress'].invalid &&
            (editAccountForm.controls['streetAddress'].dirty ||
              editAccountForm.controls['streetAddress'].touched)
          "
        >
          <div
            class="form-mandatory-text"
            *ngIf="editAccountForm.controls['streetAddress'].errors!.required"
          >
            {{ "sloansignupForm.streetAddressMsg" | cxTranslate }}
          </div>
        </div>
      </div>

      <div class="col-md-6 mt-3">
        <label class="form-label">Country</label>
        <select
          (change)="countrySelected($event.target.value)"
          class="form-control rounded-0"
          formControlName="countryIso"
        >
          <option
            *ngFor="let country of countries$ | async"
            [value]="country.isocode"
          >
            {{ country.name }}
          </option>
        </select>
        <div
          *ngIf="
            editAccountForm.controls['countryIso'].invalid &&
            (editAccountForm.controls['countryIso'].dirty ||
              editAccountForm.controls['countryIso'].touched)
          "
        >
          <div
            class="form-mandatory-text"
            *ngIf="editAccountForm.controls['countryIso'].errors!.required"
          >
            Select a Country
          </div>
        </div>
      </div>

      <div class="col-md-6 mt-3">
        <label clsas="form-label">State/Province</label>
        <select class="form-control rounded-0" formControlName="state">
          <option
            *ngFor="let state of regions$ | async"
            [value]="state.isocode"
          >
            {{ state.name }}
          </option>
        </select>
        <div
          *ngIf="
            editAccountForm.controls['state'].invalid &&
            (editAccountForm.controls['state'].dirty ||
              editAccountForm.controls['state'].touched)
          "
        >
          <div
            class="form-mandatory-text"
            *ngIf="editAccountForm.controls['state'].errors!.required"
          >
            Please select a state/province
          </div>
        </div>
      </div>

      <div class="col-md-6 mt-3">
        <label class="form-label">City</label>
        <input
          type="text"
          class="form-control"
          formControlName="city"
          id="city"
        />
        <div
          *ngIf="
            editAccountForm.controls['city'].invalid &&
            (editAccountForm.controls['city'].dirty ||
              editAccountForm.controls['city'].touched)
          "
        >
          <div
            class="form-mandatory-text"
            *ngIf="editAccountForm.controls['city'].errors!.required"
          >
            City name is invalid
          </div>
        </div>
      </div>

      <div class="col-md-6 mt-3">
        <label clsas="form-label">Zipcode</label>
        <input
          type="text"
          class="form-control"
          formControlName="zipcode"
          id="zipcode"
        />
        <div
          *ngIf="
            editAccountForm.controls['zipcode'].invalid &&
            (editAccountForm.controls['zipcode'].dirty ||
              editAccountForm.controls['zipcode'].touched)
          "
          class="form-mandatory-text"
        >
          <div
            class="form-mandatory-text"
            *ngIf="editAccountForm.controls['zipcode'].errors!.required"
          >
            Please add a Zipcode
          </div>
          <div class="form-mandatory-text" *ngIf="editAccountForm.controls['zipcode'].errors!.pattern">
            Please enter zip code in correct formats
          </div>
        </div>
      </div>

      <div class="col-12 mt-5">
        <button
          type="submit"
          class="btn btn-primary float-right"
          [disabled]="editAccountForm.invalid || disableSubmitBtn"
        >
          Update
        </button>
      </div>
    </div>
  </form>
</div>
