<div class="modal-header d-flex align-items-center p-3">
  <button type="button" class="float-right btn-close" (click)="dismiss()">&times;</button>
  <h4 class="modal-title text-center w-100 mb-3">{{ title | cxTranslate }}</h4>
</div>
<div class="modal-body text-center">
  {{ message | cxTranslate }}
</div>
<div class="modal-footer">
  <div class="row">
    <div class="col-6"><button type="button" class="btn btn-default float-right" (click)="accept()">
        {{ btnOkText | cxTranslate }}
      </button></div>
    <div class="col-6"><button type="button" class="btn btn-primary float-left" (click)="decline()">
        {{ btnCancelText | cxTranslate }}
      </button></div>
  </div>
</div>