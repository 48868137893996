<div class="row width-100 pd-10 btm-border d-none d-sm-none d-md-block d-lg-block">
  <div class="nav-links-center text-center">
    <ng-container *ngIf="(node$ | async)?.children[0] as nodes">
      <ng-container *ngFor="let menu of nodes.children; let i = index">
          <cx-generic-link class="nav-link-secondary"
          [url]="menu.url"
          [target]="menu.target"
        >
        {{ menu.title }}
        </cx-generic-link>
      </ng-container>
    </ng-container>
</div>