<div class="modal-content rounded-0 sign-up">
  <div class="modal-header">
    <h4 class="modal-title text-center w-100" id="modal-basic-title">
      {{ "saveConfiguration.saveConfigtoNewProject" | cxTranslate }}
    </h4>
  </div>
  <div class="modal-body">
    <div
      *ngIf="showErrorMessage"
      class="alert alert-danger alert-dismissible fade show"
      role="alert"
    >
      <strong>{{
        "configurators.configuratorSave.errorMsg" | cxTranslate
      }}</strong>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form [formGroup]="configForm" (ngSubmit)="submitForm()" novalidate>
      <div class="row mb-3" *ngIf="showConfigName">
        <div class="col-md-12">
          <label for="configurationName" class="form-label"
            >{{ "saveConfiguration.configurationName" | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <input
            type="text"
            class="form-control form-control-lg"
            id="configurationName"
            placeholder="Configuration Name"
            formControlName="configurationName"
          />
          <div
            *ngIf="
              configForm.controls['configurationName'].invalid &&
              (configForm.controls['configurationName'].dirty ||
                configForm.controls['configurationName'].touched)
            "
          >
            <div
              class="form-mandatory-text"
              *ngIf="configForm.controls['configurationName'].errors!.required"
            >
              {{ "saveConfiguration.configurationNameIsInvalid" | cxTranslate }}
            </div>
          </div>
        </div>
      </div>
      <h5 class="titleSec">
        {{ "saveConfiguration.newProjectName" | cxTranslate }}
      </h5>
      <div class="row mb-3">
        <div class="col-md-12">
          <label for="projectName" class="form-label"
            >{{ "saveConfiguration.newProjectDetails" | cxTranslate }}
            <span class="text-danger mandatory-font-size">*</span>
          </label>
          <input
            type="text"
            class="form-control form-control-lg"
            id="projectName"
            placeholder="Project Name"
            formControlName="projectName"
          />
          <div
            *ngIf="
              configForm.controls['projectName'].invalid &&
              (configForm.controls['projectName'].dirty ||
                configForm.controls['projectName'].touched)
            "
          >
            <div
              class="form-mandatory-text"
              *ngIf="configForm.controls['projectName'].errors!.required"
            >
              {{ "saveConfiguration.projectNameIsInvalid" | cxTranslate }}
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="cpqInstallationDate" class="form-label"
            >{{ "saveConfiguration.estimatedInstallDate" | cxTranslate }}
            <span class="text-danger mandatory-font-size">*</span></label
          >
          <input
            type="date"
            ngb-datepicker
            class="form-control"
            id="cpqInstallationDate"
            formControlName="cpqInstallationDate"
          />
          <div
            *ngIf="
              configForm.controls['cpqInstallationDate'].invalid &&
              (configForm.controls['cpqInstallationDate'].dirty ||
                configForm.controls['cpqInstallationDate'].touched)
            "
          >
            <div
              class="form-mandatory-text"
              *ngIf="
                configForm.controls['cpqInstallationDate'].errors!.required
              "
            >
              {{
                "saveConfiguration.pleaseSelectAnEstimatedInstallationDate"
                  | cxTranslate
              }}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label"
            >{{ "saveConfiguration.vertical" | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <select class="form-control" formControlName="cpqVerticalId">
            <option value="" disabled selected>Select Vertical</option>
            <option
              *ngFor="let vertical of verticals$ | async"
              [value]="vertical.verticalId"
            >
              {{ vertical.verticalName }}
            </option>
          </select>
          <div
            *ngIf="
              configForm.controls['cpqVerticalId'].invalid &&
              (configForm.controls['cpqVerticalId'].dirty ||
                configForm.controls['cpqVerticalId'].touched)
            "
          >
            <div
              class="form-mandatory-text"
              *ngIf="configForm.controls['cpqVerticalId'].errors!.required"
            >
              {{ "saveConfiguration.pleaseSelectaVertical" | cxTranslate }}
            </div>
          </div>
        </div>
      </div>
      <h5 class="titleSec">
        {{ "saveConfiguration.projectLocation" | cxTranslate }}
      </h5>
      <div class="row mb-3">
        <div class="col-md-12">
          <label for="streetAddress1" class="form-label"
            >{{ "saveConfiguration.streeAddress1" | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <input
            type="text"
            class="form-control form-control-lg"
            id="streetAddress1"
            placeholder="Street Address 1"
            formControlName="streetAddress1"
          />
          <div
            *ngIf="
              configForm.controls['streetAddress1'].invalid &&
              (configForm.controls['streetAddress1'].dirty ||
                configForm.controls['streetAddress1'].touched)
            "
          >
            <div
              class="form-mandatory-text"
              *ngIf="configForm.controls['streetAddress1'].errors!.required"
            >
              {{ "saveConfiguration.pleaseAddaStreetAddress" | cxTranslate }}
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <label for="streetAddress2" class="form-label">{{
            "saveConfiguration.streeAddress2" | cxTranslate
          }}</label>
          <input
            type="text"
            class="form-control form-control-lg"
            id="streetAddress2"
            placeholder="Street Address 2"
            formControlName="streetAddress2"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <label class="form-label"
            >{{ "saveConfiguration.country" | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <select
            (change)="countrySelected($event.target.value)"
            class="form-control rounded-0"
            formControlName="countryIso"
          >
            <option
              *ngFor="let country of countries$ | async"
              [value]="country.isocode"
            >
              {{ country.name }}
            </option>
          </select>
          <div
            *ngIf="
              configForm.controls['countryIso'].invalid &&
              (configForm.controls['countryIso'].dirty ||
                configForm.controls['countryIso'].touched)
            "
          >
            <div
              class="form-mandatory-text"
              *ngIf="configForm.controls['countryIso'].errors!.required"
            >
              {{ "saveConfiguration.pleaseSelectaCountry" | cxTranslate }}
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-4">
          <label for="zipcode" class="form-label"
            >{{ "saveConfiguration.zipCode" | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <input
            type="text"
            class="form-control form-control-lg"
            id="zipcode"
            placeholder="Zip Code"
            formControlName="zipcode"
          />
          <div
            *ngIf="
              configForm.controls['zipcode'].invalid &&
              (configForm.controls['zipcode'].dirty ||
                configForm.controls['zipcode'].touched)
            "
          >
            <div
              class="form-mandatory-text"
              *ngIf="configForm.controls['zipcode'].errors!.required"
            >
              {{ "saveConfiguration.pleaseAddaZipcode" | cxTranslate }}
            </div>
            <div class="form-mandatory-text" *ngIf="configForm.controls['zipcode'].errors!.pattern">
              Please enter zip code in correct formats
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <label class="form-label"
            >{{ "saveConfiguration.city" | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <input type="text" class="form-control" formControlName="city" />
          <div
            *ngIf="
              configForm.controls['city'].invalid &&
              (configForm.controls['city'].dirty ||
                configForm.controls['city'].touched)
            "
          >
            <div
              class="form-mandatory-text"
              *ngIf="configForm.controls['city'].errors!.required"
            >
              {{ "saveConfiguration.pleaseSelectCity" | cxTranslate }}
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <label class="form-label"
            >{{ "saveConfiguration.stateProvince" | cxTranslate
            }}<span class="text-danger mandatory-font-size ml-1">*</span></label
          >
          <select class="form-control rounded-0" formControlName="state">
            <option
              *ngFor="let state of regions$ | async"
              [value]="state.isocode"
            >
              {{ state.name }}
            </option>
          </select>
          <div
            *ngIf="
              configForm.controls['state'].invalid &&
              (configForm.controls['state'].dirty ||
                configForm.controls['state'].touched)
            "
          >
            <div
              class="form-mandatory-text"
              *ngIf="configForm.controls['state'].errors!.required"
            >
              {{ "saveConfiguration.pleaseSelectaState" | cxTranslate }}
            </div>
          </div>
        </div>
      </div>
      <h5 class="titleSec">{{ "saveConfiguration.notes" | cxTranslate }}</h5>
      <div class="row mb-3">
        <div class="col-md-12">
          <textarea
            class="form-control"
            placeholder="Add notes..."
            rows="3"
            formControlName="notes"
          ></textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-default float-left"
      (click)="closeModal()"
    >
      {{ "saveConfiguration.cancel" | cxTranslate }}
    </button>
    <button
      type="submit"
      class="btn btn-primary float-right"
      (click)="submitForm()"
      [disabled]="disableSubmitBtn"
    >
      {{ "saveConfiguration.submit" | cxTranslate }}
    </button>
  </div>
</div>
