import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { Entry2 } from 'src/app/models/configurator.model';

@Component({
  selector: 'app-sloan-cpq-view-notes',
  templateUrl: './sloan-cpq-view-notes.component.html',
  styleUrls: ['./sloan-cpq-view-notes.component.scss']
})
export class SloanCpqViewNotesComponent implements OnInit {

  configDetail: Entry2;
  constructor(
    private ngbActiveModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  closeModal(){
    this.ngbActiveModal.dismiss();
  }

}
