import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule,  } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { AuthHttpHeaderService, HttpErrorHandler, OccSiteAdapter } from '@spartacus/core';
import {
  OutletRefModule,
  PageLayoutModule,
  PageSlotModule,
} from '@spartacus/storefront';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SloanCpqAuthHttpHeaderService } from './common/services/sloan-cpq-auth-http-header.service';
import { SloancpqOutletModule } from './common/component/sloancpq-outlet/sloancpq-outlet.module';
import { SpartacusModule } from './spartacus/spartacus.module';
import { SloanBadRequestHandler } from './core/cpq-auth/cpq-global-message/http-interceptors/handlers/bad-request/sloan-bad-request.handler';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    SpartacusModule,
    BrowserModule,
    OutletRefModule,
    SloancpqOutletModule,
    PageSlotModule,
    PageLayoutModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  providers: [
    //TODO : Commented by Pushpak
    //  provideDefaultConfigFactory(bravaAuthStoreConfigFactory),
    OccSiteAdapter,
    {
      provide: HttpErrorHandler,
      useExisting: SloanBadRequestHandler,
      multi: true,
    },
    {
      provide: AuthHttpHeaderService,
      useClass: SloanCpqAuthHttpHeaderService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
//TODO : Commented by Pushpak
//export function bravaAuthStoreConfigFactory(): StateConfig {
// const config: StateConfig = {
//state: {
//  storageSync: {
//   keys: {
//   'auth.userToken.token.access_token': StorageSyncType.SESSION_STORAGE,
//  'auth.userToken.token.token_type': StorageSyncType.SESSION_STORAGE,
// 'auth.userToken.token.expires_in': StorageSyncType.SESSION_STORAGE,
// 'auth.userToken.token.expiration_time':
//  StorageSyncType.SESSION_STORAGE,
// 'auth.userToken.token.scope': StorageSyncType.SESSION_STORAGE,
//'auth.userToken.token.userId': StorageSyncType.SESSION_STORAGE,
//     },
//     },
//   },
//};
// return config;
//}
