import { NgModule } from '@angular/core';
import { defaultCmsContentProviders, layoutConfig, mediaConfig } from '@spartacus/storefront';
import { environment } from 'src/environments/environment';
import { translations, translationChunksConfig } from '@spartacus/assets';
import { provideConfig } from '@spartacus/core';

@NgModule({
  declarations: [],
  providers: [
    provideConfig(layoutConfig),
    provideConfig(mediaConfig),
    ...defaultCmsContentProviders,
    provideConfig({
        backend: {
          occ: {
            baseUrl: environment.baseUrl,
            prefix: environment.prefix,
          },
        },
        context: {
          currency: ['USD'],
          language: ['en'],
          baseSite: environment.baseSite,
        },
        i18n: {
          resources: translations,
          chunks: translationChunksConfig,
          fallbackLang: 'en',
        },
        features: {
          level: '*',
        },
        pwa: {
            enabled: true,
            addToHomeScreen: true,
        },
      }),
  ]
})
export class SpartacusConfigurationModule { }
