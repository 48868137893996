<!-- <div *ngFor="let question of questions.children[0].children; let i = index">
  <div class="accordion-item">
    <button
      [id]="'heading' + i"
      class="accordion-button collapsed accordion-header"
      type="button"
      data-bs-toggle="collapse"
      aria-expanded="false"
      [aria-controls]="'collapse' + i"
      [attr.data-bs-target]="'#collapse' + i"
      (click)="test()"
    >
      <b>{{ question.question }}</b>
    </button>

    <div
      [id]="'collapse' + i"
      class="accordion-collapse collapse"
      [aria-labelledby]="'heading' + i"
      data-bs-parent="#accordionExample"
    >
      <div class="accordion-body">
        {{ question.answer }}
      </div>
    </div>
  </div>
</div> -->
         <!-- TODO: Changed by Pushpak  [attr.aria-labelledby] from  [aria-labelledby] and aria-controls-->

<div id="accordion">
  <div  *ngFor="let question of questions.children[0].children; let i = index">
    <div class="accordion-item" [id]="'heading'+i">
        <button (click)="addCollapes($event,i)" class="accordion-button collapsed accordion-header" data-toggle="collapse" [attr.data-target]="'#collapse'+i" aria-expanded="false" [attr.aria-controls]="'collapse'+i">
         <b> {{ question.question }}</b>
        </button>
    </div>
    <div [id]="'collapse'+i" class="collapse" [attr.aria-labelledby]="'heading'+1" data-parent="#accordion">
      <div class="card-body">
        {{ question.answer }}
      </div>
    </div>
  </div>
</div>


