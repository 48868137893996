<div class="modal-content">
  <div class="modal-header">
    <h4
      class="modal-title text-center w-100"
      id="modal-basic-title"
      *ngIf="firstPage"
    >
      {{ "saveProjectForm.saveConfiguration" | cxTranslate }}
    </h4>
    <h4
      class="modal-title text-center w-100"
      id="modal-basic-title"
      *ngIf="!firstPage"
    >
      {{ "saveProjectForm.saveConfigurationToExistingProject" | cxTranslate }}
    </h4>
  </div>
  <div class="modal-body">
    <div
      *ngIf="showErrorMessage"
      class="alert alert-danger alert-dismissible fade show"
      role="alert"
    >
      <strong>{{
        "configurators.configuratorSave.errorMsg" | cxTranslate
      }}</strong>
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form [formGroup]="saveConfigForm">
      <div class="col-12">
        <div class="row g-3">
          <div class="col-md-12 mt-3">
            <label for="configurationName" class="form-label">
              {{ "saveProjectForm.configurationName" | cxTranslate }}
              <span class="text-danger mandatory-font-size">*</span></label
            >
            <input
              tabindex="-1"
              type="text"
              class="form-control"
              id="configurationName"
              formControlName="configurationName"
            />
            <div
              *ngIf="
                (saveConfigForm.controls['configurationName'].invalid &&
                  saveConfigForm.controls['configurationName'].dirty) ||
                (saveConfigForm.controls['configurationName'].invalid &&
                  saveConfigForm.controls['configurationName'].touched)
              "
            >
              <div
                class="form-mandatory-text"
                *ngIf="
                  saveConfigForm.controls['configurationName'].errors!.required
                "
              >
                {{ "saveProjectForm.configurationNameMsg" | cxTranslate }}
              </div>
            </div>
            <div class="form-mandatory-text" *ngIf="existingConfigMsg">
              {{ existingConfigMsg }}
            </div>
          </div>
          <div class="col-md-12 mt-3" *ngIf="firstPage">
            <label for="configurationName" class="form-label">
              {{ "saveProjectForm.saveConfigurationTo" | cxTranslate }}</label
            >
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                formControlName="projectType"
                value="newProject"
                name="projectType"
                id="flexRadioDefault1"
              />
              <label class="form-check-label pl-3" for="flexRadioDefault1">
                {{ "saveProjectForm.newProject" | cxTranslate }}
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="projectType"
                id="flexRadioDefault2"
                value="existingProject"
                formControlName="projectType"
              />
              <label class="form-check-label pl-3" for="flexRadioDefault2">
                {{ "saveProjectForm.existingProject" | cxTranslate }}
              </label>
            </div>
          </div>
          <div class="col-md-12 mt-3" *ngIf="!firstPage">
            <label
              for="configurationName"
              class="form-label"
              *ngIf="!isDuplicate"
            >
              {{ "saveProjectForm.selectExistingProject" | cxTranslate }}</label
            >
            <label
              for="configurationName"
              class="form-label"
              *ngIf="isDuplicate"
            >
              {{ "saveProjectForm.existingProject" | cxTranslate }}</label
            >
            <select
              (change)="selectProjectEvent($event)"
              class="form-control"
              formControlName="selectedProject"
            >
              <option
                *ngFor="let project of (existProjectList   | async)?.carts"
                [value]="project.code"
              >
                {{ project.name }}
              </option>
            </select>
            <div
              *ngIf="
                saveConfigForm.controls['selectedProject'].invalid &&
                (saveConfigForm.controls['selectedProject'].dirty ||
                  saveConfigForm.controls['selectedProject'].touched)
              "
            >
              <div
                class="form-mandatory-text"
                *ngIf="
                  saveConfigForm.controls['selectedProject'].errors!.required
                "
              >
                {{ "saveProjectForm.selectProjectMsg" | cxTranslate }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer col-12 pt-4 mt-3 float-left">
          <button
            type="button"
            class="btn btn-default float-left"
            (click)="dismissModal()"
          >
            {{ "saveProjectForm.cancelBtn" | cxTranslate }}
          </button>
          <button
            type="submit"
            class="btn btn-primary float-right"
            *ngIf="firstPage"
            (click)="next()"
            [disabled]="saveConfigForm.invalid || disableSubmitBtn"
          >
            {{ "saveProjectForm.nextBtn" | cxTranslate }}
          </button>
          <button
            type="submit"
            class="btn btn-primary float-right"
            *ngIf="!firstPage"
            (click)="save()"
            [disabled]="
              !(projectId || saveConfigForm.value.selectedProject) ||
              disableSubmitBtn ||
              saveConfigForm.invalid
            "
          >
            {{ "saveProjectForm.saveBtn" | cxTranslate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
