import { ConfigModule, CmsConfig } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanCpqFaqTcComponent } from './sloan-cpq-faq-tc.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';



@NgModule({
  declarations: [SloanCpqFaqTcComponent],
  imports: [
    CommonModule,
    // ConfigModule.withConfig({
    //   cmsComponents: {
    //     CMSLinkComponent: {
    //       component: SloanCpqFaqTcComponent
    //     }
    //   }
    // } as CmsConfig),
    // ConfigModule.withConfig({
    //   cmsComponents: {
    //     CPQFAQGeneralComponent: {
    //       component: SloanCpqFaqTcComponent
    //     }
    //   }
    // } as CmsConfig),
    NgbModule, BrowserModule
  ]
})
export class SloanCpqFaqTcModule { }
