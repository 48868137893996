import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { SpinnerModule } from '@spartacus/storefront';
import { SloanCpqPipesModule } from 'src/app/common/pipes/sloan-cpq-pipes/sloan-cpq-pipes.module';
import { SloanCpqAccountModule } from 'src/feature-libs/cpq-user/cpq-account/sloan-login-signup.module';
import { SloancpqHomeComponent } from './sloancpq-home.component';

@NgModule({
  declarations: [SloancpqHomeComponent],
  imports: [
    CommonModule,
    BrowserModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    SloanCpqPipesModule,
    SloanCpqAccountModule,
    SpinnerModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CPQHomePageBodyComponent: {
          component: SloancpqHomeComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class SloancpqHomeModule {}
