import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, Language, LanguageService, OccSiteAdapter, RoutingService, WindowRef } from '@spartacus/core';
import { Observable } from 'rxjs';
import { SloanCpqUserService } from 'src/app/common/services/sloan-cpq-user.service';
import { USER_DATA_KEY } from 'src/app/constants/storage.constant';
import { SloanSinkConfigHelpComponent } from 'src/app/cpq-libs/sloan-sink-config-help/sloan-sink-config-help.component';
import { SloanUser } from 'src/app/models/sloan-user';
import { SloanLoginComponent } from 'src/feature-libs/cpq-user/cpq-account/components/sloan-login/sloan-login.component';
import { SloanSignupComponent } from 'src/feature-libs/cpq-user/cpq-account/components/sloan-signup/sloan-signup.component';
import { SloanGlobalHelpComponent } from '../../component/sloan-global-help/sloan-global-help.component';

@Component({
  selector: 'app-sloan-my-account',
  templateUrl: './sloan-my-account.component.html',
  styleUrls: ['./sloan-my-account.component.scss'],
  providers: [OccSiteAdapter],
})
export class SloanMyAccountComponent implements OnInit {
  logInModalRef: NgbModalRef;
  signUpModalRef: NgbModalRef;
  globalHelpModalRef: NgbModalRef;
  sinkHelpModalRef: NgbModalRef;
  isLoggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();
  languages$: Observable<Language[]> = this.occSiteAdapter.loadLanguages();
  constructor(
    private modalService: NgbModal,
    private occSiteAdapter: OccSiteAdapter,
    private languageService: LanguageService,
    public authService: AuthService,
    private routingService: RoutingService,
    private sloanCpqUserService: SloanCpqUserService,
    private winRef: WindowRef
  ) {}

  selectLanguage(isocode: string) {
    this.languageService.setActive(isocode);
  }

  navigateToProfile() {
    this.routingService.goByUrl('/cpqUsers');
  }

  navigateToProjects() {
    this.routingService.goByUrl('/projects');
  }

  ngOnInit(): void {}

  openLoginModal() {
    this.logInModalRef = this.modalService.open(SloanLoginComponent);
    this.sloanCpqUserService.setGlobalMessage(false);
    this.logInModalRef.result
      .then((res) => {
        this.sloanCpqUserService.setGlobalMessage(true);
      })
      .catch(() => {
        this.sloanCpqUserService.setGlobalMessage(true);
      })
      .finally(() => {
        this.sloanCpqUserService.setGlobalMessage(true);
      });
  }

  openSignUpModal() {
    this.signUpModalRef = this.modalService.open(SloanSignupComponent);
    this.sloanCpqUserService.setGlobalMessage(false);
    this.signUpModalRef.result
      .then((res) => {
        this.sloanCpqUserService.setGlobalMessage(true);
      })
      .catch(() => {
        this.sloanCpqUserService.setGlobalMessage(true);
      })
      .finally(() => {
        this.sloanCpqUserService.setGlobalMessage(true);
      });
  }

  openGlobalHelpModal() {
    this.globalHelpModalRef = this.modalService.open(SloanGlobalHelpComponent);
  }

  openSinkHelpModal() {
    const userdata = this.winRef.sessionStorage.getItem(USER_DATA_KEY);
    if (userdata) {
      const userdataJson: SloanUser = JSON.parse(userdata);
      if (userdataJson && userdataJson.cpqAccountManager && Object.keys(userdataJson.cpqAccountManager).length > 0) {
        this.sinkHelpModalRef = this.modalService.open(SloanSinkConfigHelpComponent);
      } else {
        this.openGlobalHelpModal();
      }
    }
  }

  logout() {
    this.authService.coreLogout().then((res) => {
      this.routingService.goByUrl('');
      this.winRef.sessionStorage.removeItem(USER_DATA_KEY);
    });
  }
}
