import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule } from '@spartacus/core';
import { LayoutConfig } from '@spartacus/storefront';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    ConfigModule.withConfig({
      layoutSlots: {
        header: {
          slots: ['BottomHeaderSlot'],
        },
        CPQHomePage2Template: {
          slots: [   'HomepageBodySlot', 'FooterSlot'],
        },
        CPQUserUpdatePageTemplate: {
          slots: ['UserUpdateBodyContent']
        },
        CPQProjectGridPageTemplate: {
          slots: ['ProjectGridBodyContentSlot']
        },
        CPQConfigurationGridPageTemplate: {
          slots: ['ConfigurationGridBodyContentSlot']
        },
        CPQFaqTncPage2Template: {
          slots: ['CPQTncBodyContentSlot','CPQFaqBodyContentSlot']
        },
        CPQPasswordUpdatePageTemplate: {
          slots: ['PasswordUpdateBodyContentSlot']
        }
      }
    } as LayoutConfig)
  ]
})
export class SloancpqLayoutConfigModule { }
