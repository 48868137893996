import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfigModule, I18nModule, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard, FormErrorsModule, PageLayoutComponent } from '@spartacus/storefront';
// import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SloanCpqAuthGuard } from 'src/app/common/guards/sloan-cpq-auth-guard.guard';
import { SloanCpqProjectDetailsComponent } from './sloan-cpq-project-details/sloan-cpq-project-details.component';
import { SloanCpqProjectsListModule } from './sloan-cpq-projects-list/sloan-cpq-projects-list.module';
import { SloanSaveProjectConfigurationComponent } from './sloan-save-project-configuration/sloan-save-project-configuration.component';

@NgModule({
  declarations: [SloanSaveProjectConfigurationComponent, SloanCpqProjectDetailsComponent],
  imports: [
    CommonModule,
    BrowserModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    NgbModule,
    SloanCpqProjectsListModule,
    RouterModule.forChild([
      {
        data: { pageLabel: '/projects', cxRoute: 'projectslist' },
        path: null,
        canActivate: [SloanCpqAuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          projectslist: {
            paths: ['projects'],
          },
        },
      },
    } as RoutingConfig),
    NgbModule,
    // Ng2SearchPipeModule,
  ],
})
export class SloanCpqProjectsComponentModule {}