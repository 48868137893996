import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SloanCpqUserService } from '../../services/sloan-cpq-user.service';

@Component({
  selector: 'app-sloancpq-outlet',
  templateUrl: './sloancpq-outlet.component.html',
  styleUrls: ['./sloancpq-outlet.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SloancpqOutletComponent implements OnInit, AfterViewChecked {
  showGlobalMessage: Observable<boolean> = this.sloanCpqUserService.canShowGlobalMessage();
  constructor(private sloanCpqUserService: SloanCpqUserService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
}
