import { I18nModule, ConfigModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: {
          sloanCpqUser: ['sloanloginForm', 'sloansignupForm', 'sloanForgotPasswordForm', 'messages'],
          sloanCpqProjects: ['projectList', 'projectDetails', 'saveProjectForm', 'viewNotes'],
          sloanCpqConfigurators: ['configuratorsList', 'saveConfiguration', 'approveDesign', 'downloadAndReqDocuments'],
          sloanHeaders : ['myAccount'],
          sloanHelper : ['globalHelp', 'sinkConfigHelp'],
          sloanCpqMessages : ['button', 'common', 'projects', 'configurators', 'user'],
          sloanFAQ:['CPQFaqTabPanelContainer']
        },
        fallbackLang: 'en',
      },
    }),
  ]
})
export class Sloani18nModule { }
