import { Component, OnInit } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { SoanCpqFaqService } from 'src/app/common/services/sloan-cpq-faq-service';
import { CategoryNavigationComponent } from 'src/app/models/cmsConfig.model';

@Component({
  selector: 'app-sloan-cpq-faq-projects',
  templateUrl: './sloan-cpq-faq-projects.component.html',
  styleUrls: ['./sloan-cpq-faq-projects.component.scss'],
})
export class SloanCpqFaqProjectsComponent implements OnInit {
  component: any;
  componentData$: Observable<CategoryNavigationComponent> = this.componentData.data$;
  node$: Observable<any> = this.service.createNavigation(this.componentData.data$);
  questions: any;

  constructor(
    protected componentData: CmsComponentData<CategoryNavigationComponent>,
    protected service: SoanCpqFaqService
  ) {}

  ngOnInit(): void {
    //  this.node$.subscribe((data) =>{ this.questions = data.children[0].children;
    //   console.log(data.children[0].children)}
    //  )
    //  console.log(this.questions)
  }

  test() {
    console.log('button');
  }
}
