import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sloan-cms-faq',
  templateUrl: './sloan-cms-faq.component.html',
  styleUrls: ['./sloan-cms-faq.component.scss']
})
export class SloanCmsFaqComponent implements OnInit {
  @Input() questions:any;
  constructor() { }

  ngOnInit(): void {
  }

  addCollapes(event, i){
  document.querySelectorAll('.accordion-button').forEach((ele, index) => {if(index != i){ele.classList.add('collapsed')}})
  document.querySelectorAll('.collapse').forEach((ele,index) => {if(index != i){ele.classList.remove('show')}})
  event.currentTarget.classList.remove('collapsed')
  }

}
