<section class="col-md-12 p-0 login-wrapper d-flex justify-content-center align-items-center">
  <div class="container fluid p-3 w-50">
    <h3 class="text-center">Reset Password</h3>
    <form (ngSubmit)="resetPassword()" [formGroup]="resetPasswordForm">
      <div class="col-12 mt-3 float-left">
        <label class="form-label">{{ 'sloansignupForm.password' | cxTranslate }}</label>
        <input
          type="password"
          (keyup)="checkPasswordValidations()"
          class="form-control rounded-0"
          formControlName="password"
          id="password"
        />
        <span class="highlight"></span>
        <!-- <label>{{ 'loginForm.emailAddress.label' | cxTranslate }}</label> -->
        <cx-form-errors [control]="resetPasswordForm.get('password')"></cx-form-errors>
      </div>

      <div class="col-12 mt-3 float-left disable-pointer">
        <div class="form-check form-check-inline mb-2">
          <input
            class="form-check-input"
            type="checkbox"
            name="passwordOptions"
            id="oneLowerChar"
            value="One lowercase character"
            [checked]="isLowercase"
            readonly
          />
          <label class="form-check-label mb-2" for="oneLowerChar">
            {{ 'sloansignupForm.lowerCaseMsg' | cxTranslate }}</label
          >
        </div>
        <div class="form-check form-check-inline mb-2">
          <input
            class="form-check-input"
            [checked]="isNumber"
            type="checkbox"
            name="passwordOptions"
            id="oneNum"
            value="One number"
            readonly
          />
          <label class="form-check-label mb-2" for="oneNum"> {{ 'sloansignupForm.numberMsg' | cxTranslate }}</label>
        </div>
        <br />
        <div class="form-check form-check-inline mb-2">
          <input
            class="form-check-input"
            [checked]="isUpperCase"
            type="checkbox"
            name="passwordOptions"
            id="oneUpperChar"
            value="One uppercase charatcer"
            readonly
          />
          <label class="form-check-label mb-2" for="oneUpperChar">
            {{ 'sloansignupForm.upperCaseMsg' | cxTranslate }}</label
          >
        </div>
        <div class="form-check form-check-inline mb-2">
          <input
            class="form-check-input"
            [checked]="eightCharacters"
            type="checkbox"
            name="passwordOptions"
            id="eightCharMin"
            value="8 charatcer minimum"
            readonly
          />
          <label class="form-check-label mb-2" for="eightCharMin">
            {{ 'sloansignupForm.minCharacterMsg' | cxTranslate }}</label
          >
        </div>
      </div>

      <div class="col-12 mt-2 float-left">
        <label class="form-label">{{ 'sloansignupForm.confirmPassword' | cxTranslate }}</label>
        <input type="password" class="form-control rounded-0" formControlName="repassword" id="confirmPassword" />
        <span class="highlight"></span>
        <cx-form-errors [control]="resetPasswordForm.get('repassword')"></cx-form-errors>
      </div>
      <div class="col-12 mt-5">
        <!-- <button type="button" class="btn btn-default float-left" (click)="backOrClose()">
              Cancel
          </button> -->
        <button type="submit" class="btn btn-primary float-right" [disabled]="resetPasswordForm.invalid">
          Reset Password
        </button>
      </div>
    </form>
  </div>
</section>
