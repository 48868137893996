import { NgModule } from "@angular/core";
import { SloanCpqConfiguratorsModule } from "./sloan-cpq-configurators/sloan-cpq-configurators.module";
import { SloanCpqFaqTcModule } from "./sloan-cpq-faq-tc/sloan-cpq-faq-tc/sloan-cpq-faq-tc.module";
import { SloanCpqFaqModule } from "./sloan-faq/sloan-cpq-faq/sloan-cpq-faq.module";
import { SloancpqHomeModule } from "./sloancpq-home/sloancpq-home.module";



@NgModule({
    imports:[
        SloanCpqConfiguratorsModule,
        SloanCpqFaqTcModule,
        SloanCpqFaqModule,
        SloancpqHomeModule,
    ]
})

export class SloanCpqLibsModule {}