import { ConfigModule, CmsConfig, I18nModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanCpqUserProfileComponent } from './sloan-cpq-user-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormErrorsModule } from '@spartacus/storefront';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [SloanCpqUserProfileComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    I18nModule,
    NgSelectModule,
    ConfigModule .withConfig({
      cmsComponents: {
        CPQUserUpdateComponent: {
          component: SloanCpqUserProfileComponent
        }
      }
    } as CmsConfig),
  ],
  exports: [ReactiveFormsModule]
})
export class SloanCpqUserProfileModule { }
