import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule, UrlModule } from '@spartacus/core';
import {
  GlobalMessageComponentModule,
  OutletRefModule,
  PageComponentModule,
  PageLayoutModule,
  PageSlotModule,
} from '@spartacus/storefront';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SloancpqOutletComponent } from './sloancpq-outlet.component';

@NgModule({
  imports: [
    CommonModule,
    OutletRefModule,
    PageComponentModule,
    PageLayoutModule,
    PageSlotModule,
    GlobalMessageComponentModule,
    RouterModule,
    NgbModalModule,
    UrlModule,
    I18nModule,
    NgxSpinnerModule,
  ],
  declarations: [SloancpqOutletComponent],
  exports: [SloancpqOutletComponent],
})
export class SloancpqOutletModule {}
