<div class="modal-content">
  <div class="modal-header">
    <div class="mb-3">
      <button
        type="button"
        class="btn-close float-right"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="closeModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <h4 class="modal-title text-left w-100 mt-5" id="modal-basic-title">
      Congratulations! Your sink configuration is complete.
    </h4>
    <div class="modal-body mt-3">
      <div class="col-12">
        <p class="text-left">
          To save your design and view the total suggested list price, please
          <strong>log in or sign up.</strong>
        </p>
        <p class="text-left">
          Once logged in, you will be able to share, duplicate, or edit your
          saved design.
        </p>
        <p class="text-left">
          You can also view notes, request model-specific line drawings, and
          download your:
        </p>
        <ul>
          <li>Sink rendering</li>
          <li>Pricing and configuration details</li>
          <li>Rough-in and line drawings</li>
          <li>Installation instructions</li>
        </ul>
      </div>
    </div>
    <div class="col-md-12 text-center mt-3">
      <button
        type="submit"
        class="btn btn-outline-primary"
        (click)="openSignUpModal()"
      >
        Sign Up
      </button>
      <button
        type="submit"
        class="btn btn-primary ml-3"
        (click)="openLoginModal()"
      >
        Log In
      </button>
    </div>
  </div>
</div>
