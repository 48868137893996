import { FormErrorsModule } from '@spartacus/storefront';
import { UrlModule, I18nModule, ConfigModule, CmsConfig } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SloanCpqResetPasswordComponent } from './sloan-cpq-reset-password.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [SloanCpqResetPasswordComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CPQPasswordUpdateComponent: {
          component: SloanCpqResetPasswordComponent,
        },
      },
    } as CmsConfig)
  ]
})
export class SloanCpqResetPasswordModule { }
