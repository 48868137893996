<div class="modal-content">
  <div class="modal-header">
    <div class="mb-3">
      <button
        type="button"
        class="btn-close float-right"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="closeModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <h4 class="modal-title text-center w-100" id="modal-basic-title">
      {{ "viewNotes.configurationNotes" | cxTranslate }}
    </h4>
  </div>
  <div class="modal-body" *ngIf="configDetail">
    <p>
      {{ "viewNotes.contactDetails" | cxTranslate }}
      <a> {{ "viewNotes.contactEmail" | cxTranslate }}</a>
    </p>

    <div class="mt-5">
      <h5>{{ "viewNotes.sink" | cxTranslate }}</h5>
    </div>
    <div class="mb-3 form-group">
      <label class="form-label">
        {{ "viewNotes.optionalSinkFeatures" | cxTranslate }}</label
      >
      <textarea
        class="form-control"
        disabled
        [(ngModel)]="configDetail.sinkFeatureNotes"
      >
      </textarea>
    </div>

    <div class="mb-3 form-group">
      <label class="form-label">
        {{ "viewNotes.moutingType" | cxTranslate }}</label
      >
      <textarea
        class="form-control"
        disabled
        [(ngModel)]="configDetail.mountingNotes"
      >
      </textarea>
    </div>

    <div class="mt-5">
      <h5>
        {{ "viewNotes.faucetAndSoap" | cxTranslate }}
      </h5>
    </div>

    <div class="mb-3 form-group">
      <label class="form-label">
        {{ "viewNotes.faucetAndSoapHoles" | cxTranslate }}</label
      >
      <textarea
        class="form-control"
        disabled
        [(ngModel)]="configDetail.fixtureNotes"
      >
      </textarea>
    </div>

    <div class="mb-3 form-group">
      <label class="form-label">
        {{ "viewNotes.soapDispenser" | cxTranslate }}</label
      >
      <textarea
        class="form-control"
        disabled
        [(ngModel)]="configDetail.soapDispenserNotes"
      >
      </textarea>
    </div>
  </div>
</div>
