<ng-container>
  <div class="row sloanProjectSettings">
    <div class="col-6 nopad">
      <!-- <input type="checkbox" /> -->
      <span class="checkmark"></span>
    </div>
    <div class="col-6 text-right nopad">
      <div ngbDropdown container="body">
        <button class="btn" ngbDropdownToggle>
          <img src="../../../../assets/images/ellipsis.svg" />
        </button>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-left">
          <a
            *ngIf="!(project.projectStatus === 'ARCHIVED')"
            href="javascript:void(0)"
            (click)="archive()"
            ngbDropdownItem
          >
            <img src="../../../../assets/images/project-archive.svg" />
            {{ "projectList.archive" | cxTranslate }}
          </a>
          <!-- <a href="javascript:void(0)" ngbDropdownItem><i class="fa fa-copy"></i> {{"projectList.duplicate" | cxTranslate}}</a> -->
          <a (click)="projectDetails()" ngbDropdownItem>
            <img src="../../../../assets/images/project-notes.svg" />
            {{ "projectList.viewProjectDetails" | cxTranslate }}</a
          >
        </div>
      </div>
    </div>
  </div>
  <a
    href="javascript:void(0)"
    (click)="goToConfigurators()"
    class="sloanProjectCard"
  >
    <button type="button" class="sloanProjectDetails btn btn-outline-secondary" (click)="sloanCPQOnClickEvent('View Project')">
      {{ "projectList.viewProject" | cxTranslate }}
    </button>
    <div class="img-hover">
      <ng-container
        *ngIf="project.threeKitData && project.threeKitData.length > 0"
      >
        <img
          [src]="project.threeKitData[0].metadata.tileImage"
          class="img-fluid img-thumbnail"
          alt="..."
        />
      </ng-container>
      <ng-container
        *ngIf="!project.threeKitData || project.threeKitData.length < 1"
      >
        <img
          src="assets/images/1.jpg"
          class="img-fluid img-thumbnail"
          alt="..."
        />
      </ng-container>
      <div class="projectLocking">
        <span class="count">{{ project.entries.length }}</span>
        <a href="javascript:void(0)"><i class="fa fa-folder-open"></i></a>
      </div>
    </div>
  </a>
  <div class="projectTile">{{ project.name }}</div>
  <p
    *ngIf="project.projectStatus === 'ARCHIVED'"
    class="text-danger text-center sm-font"
  >
    {{ "projectList.projectarchived" | cxTranslate }}
    <img class="globe" src="assets/images/circle-error.svg" />
  </p>
  <p *ngIf="project.anyConfigExpired" class="text-danger text-center sm-font">
    {{ "projectList.expiredMsg" | cxTranslate }}
    <img class="globe" src="assets/images/circle-error.svg" />
  </p>
</ng-container>
