import { NgModule } from '@angular/core';
import { ConfigModule, CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { CommonModule } from '@angular/common';
import { SloanCpqFaqProjectsModule } from './sloan-cpq-faq-projects/sloan-cpq-faq-projects.module';
import { SloanCpqFaqComponent } from './sloan-cpq-faq.component';
import { PageComponentModule, OutletModule } from '@spartacus/storefront';
import { SloanCmsFaqModule } from './sloan-cms-faq/sloan-cms-faq.module';



@NgModule({
    imports: [
        SloanCpqFaqProjectsModule,
        CommonModule,
        PageComponentModule,
        SloanCmsFaqModule,
        OutletModule,
        I18nModule,
        ConfigModule.withConfig({
            cmsComponents: {
                CMSTabParagraphContainer: {
                    component: SloanCpqFaqComponent
                }
            }
        } as CmsConfig)
    ],
    declarations: [SloanCpqFaqComponent],
    exports: [SloanCpqFaqComponent],
    providers: [SloanCpqFaqComponent]
})
export class SloanCpqFaqModule { }
