import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule } from '@spartacus/core';
import { SloanCpqConfiguratorsSaveComponent} from './sloan-cpq-configurators-save.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';



@NgModule({
  declarations: [SloanCpqConfiguratorsSaveComponent],
  imports: [
    CommonModule,
    I18nModule,
    NgbModule,
    FormsModule, ReactiveFormsModule
  ]
})
export class SloanCpqConfiguratorsSaveModule { }
