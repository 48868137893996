<div class="container-fluid w-75 mt-5 mb-5 nopad">
  <div class="row mb-5">
    <ng-container *ngIf="title === 'faqs'; then faqTitle; else tncTitle">
    </ng-container>
    <ng-template #faqTitle>
      <h5 class="col-xs-12 col-sm-12 col-md-6 text-black nopad">
        Frequently Asked Questions
      </h5>
    </ng-template>
    <ng-template #tncTitle>
      <h5 class="col-xs-12 col-sm-12 col-md-6 text-black nopad">
        Terms & Conditions
        <span class="text-grey">Last Updated October 17, 2020</span>
      </h5>
    </ng-template>
    <div class="col-xs-12 col-sm-12 col-md-6 tandcbutton nopad">
      <button class="btn btn-outline-primary" (click)="openTC()">
        Terms & Conditions
      </button>
    </div>
  </div>
<!-- <ng-container *ngIf="title === 'faqs'; then faqContent; else tncContent">
  </ng-container> -->

   <ng-template #faqContent>
     <!-- Mobile version -->
    <div class="d-block d-sm-block d-md-none d-lg-none col-12 nopad">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="general-tab"
            data-toggle="tab"
            href="#general"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            <b>General</b>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="projects-tab"
            data-toggle="tab"
            href="#projects"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
            ><b>Projects</b></a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="accounts-tab"
            data-toggle="tab"
            href="#accounts"
            role="tab"
            aria-controls="messages"
            aria-selected="false"
            ><b>Accounts</b></a
          >
        </li>
      </ul>

       Tab panes
       <div class="tab-content">
        <div
          class="tab-pane active"
          id="general"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <ng-container *ngTemplateOutlet="generalQuestions"></ng-container>
        </div>
        <div
          class="tab-pane"
          id="projects"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <ng-container *ngTemplateOutlet="projectQuestions"></ng-container>
        </div>
        <div
          class="tab-pane"
          id="accounts"
          role="tabpanel"
          aria-labelledby="messages-tab"
        >
          <ng-container *ngTemplateOutlet="accountQuestions"></ng-container>
        </div>
      </div>
    </div>
   <div class="d-none d-sm-none d-md-block d-lg-block">
      <div class="d-flex">
        <ul
          ngbNav
          #nav="ngbNav"
          [(activeId)]="active"
          class="nav-pills"
          orientation="vertical"
        >
          <li ngbNavItem="general">
            <a ngbNavLink><b>General</b></a>
            <ng-template ngbNavContent>
              <ng-container *ngTemplateOutlet="generalQuestions"></ng-container>
            </ng-template>
          </li>
          <li ngbNavItem="projects">
            <a ngbNavLink><b>Projects</b></a>
            <ng-template ngbNavContent>
              <ng-container *ngTemplateOutlet="projectQuestions"></ng-container>
            </ng-template>
          </li>
          <li ngbNavItem="accounts">
            <a ngbNavLink><b>Account</b></a>
            <ng-template ngbNavContent>
              <ng-container *ngTemplateOutlet="accountQuestions"></ng-container>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="ml-4"></div>
      </div>
    </div>
   </ng-template>
 
   <!-- TODO: Uncommented by Pushpak  -->
  <ng-template #generalQuestions>
    <div class="accordion" id="accordionExample">
      <ng-container
        [ngTemplateOutlet]="questionanswers"
        [ngTemplateOutletContext]="{ questions: faqquestions.faq.general }"
      >
      </ng-container>
    </div>
  </ng-template> 

   <ng-template #projectQuestions>
    <div class="accordion" id="accordionExample">
      <ng-container
        [ngTemplateOutlet]="questionanswers"
        [ngTemplateOutletContext]="{ questions: faqquestions.faq.projects }"
      >
      </ng-container>
    </div>
  </ng-template> 

    <ng-template #accountQuestions>
    <div class="accordion" id="accordionExample">
      <ng-container
        [ngTemplateOutlet]="questionanswers"
        [ngTemplateOutletContext]="{ questions: faqquestions.faq.account }"
      >
      </ng-container>
    </div>
  </ng-template>
     <!-- TODO: Uncommented till here by Pushpak  -->

         <!-- TODO: Changed by Pushpak  [attr.aria-labelledby] from  [aria-labelledby]-->

  <ng-template #questionanswers let-questions="questions">
    <ng-container *ngFor="let question of questions; let i = index">
      <div class="accordion-item">
        <h2 class="accordion-header" [id]="'heading' + i">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#collapse' + i"
            aria-expanded="true"
            [attr.aria-controls]="'collapse' + i"
          >
            <b>{{ question.question }}</b>
          </button>
        </h2>
         <!-- TODO: Changed by Pushpak  [attr.aria-labelledby] from  [aria-labelledby]-->
        <div
          [id]="'collapse' + i"
          class="accordion-collapse collapse"
          [attr.aria-labelledby]="'heading' + i"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            {{ question.answer }}
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>

   <!-- <ng-template #tncContent>
    <h6 class="text-black mb-4">Terms & Condition 1</h6>
    <div class="mb-4">Sample Content....</div>
    <h6 class="text-black mb-4">Terms & Condition 2</h6>
    <div class="mb-4">Sample Content....</div>
  </ng-template> -->
</div>
