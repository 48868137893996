import { NgModule } from '@angular/core';
import { SloanCpqConfirmDialogModule } from './sloan-cpq-confirm-dialog/sloan-cpq-confirm-dialog.module';
import { SloanCpqFooterModule } from './sloan-cpq-footer/sloan-cpq-footer.module';
import { SloanGlobalHelpComponent } from './sloan-global-help/sloan-global-help.component';

@NgModule({
//   declarations: [SloanGlobalHelpComponent],
  imports: [
    SloanCpqConfirmDialogModule,
    SloanCpqFooterModule,
    SloanCpqFooterModule

  ],
//   exports:[SloanGlobalHelpComponent,]
})
export class SloanCpqCommonComponentModule {}