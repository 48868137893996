import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-sloan-cms-faq',
  templateUrl: './sloan-cms-faq.component.html',
  styleUrls: ['./sloan-cms-faq.component.scss']
})
export class SloanCMSFAQComponent implements OnInit {
  @Input() questions:any;


  ngOnInit(): void {
    console.log(this.questions);
    
  }

}
