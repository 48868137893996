import { SloanCpqConfiguratorItemComponent } from './sloan-cpq-configurator-item/sloan-cpq-configurator-item.component';
import { SloanCpqConfiguratorsListComponent } from './sloan-cpq-configurators-list.component';
import { ConfigModule, CmsConfig, I18nModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerModule } from '@spartacus/storefront';
// import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SloanCpqPipesModule } from 'src/app/common/pipes/sloan-cpq-pipes/sloan-cpq-pipes.module';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [SloanCpqConfiguratorsListComponent, SloanCpqConfiguratorItemComponent],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CPQCreateConfigurationComponent: {
          component: SloanCpqConfiguratorsListComponent
        }
      }
    } as CmsConfig),
    I18nModule,
    NgbModule,
    SpinnerModule,
    // Ng2SearchPipeModule,
    FormsModule, 
    ReactiveFormsModule,
    SloanCpqPipesModule,
    NgxSpinnerModule
  ]
})
export class SloanCpqConfiguratorsListModule { }
