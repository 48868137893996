import { Component, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  stopListening: Function;

  // @HostListener('window:message', ['$event'])
  // onMessage(e) {
  //   if (e && e.data) console.log(e.data);
  // }

  constructor(private renderer: Renderer2) {
    // this.stopListening = renderer.listen(
    //   'window',
    //   'message',
    //   this.handleMessage.bind(this)
    // );
  }

  // handleMessage(event: Event) {
  //   const message = event as MessageEvent;
  //   // Only trust messages from the below origin.
  //   if (message.origin !== 'http://example.com:8080') return;
  //   console.log(message.data);
  // }

  ngOnDestroy() {
    this.stopListening();
  }
}
