import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
     RouterModule.forRoot([], {
    anchorScrolling: 'enabled',
    initialNavigation: 'enabledNonBlocking',
    scrollPositionRestoration: 'top',
    scrollOffset: [0, 0]
}),
  ],
})
export class AppRoutingModule {}
