import { NgModule } from '@angular/core';
import { SloanCpqAccountComponentModule } from './components/sloan-cpq-account-component.module';
@NgModule({
  declarations: [],
  imports: [
    SloanCpqAccountComponentModule
  ],
  exports: [],
  providers: [
  ],
})
export class SloanCpqAccountModule {}
