import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { CMSTabParagraphContainer, CmsService, WindowRef } from '@spartacus/core';
import { CmsComponentData, TabParagraphContainerComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-tab-paragraph-container',
  templateUrl: './sloan-cpq-faq.component.html',
  styleUrls: ['./sloan-cpq-faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanCpqFaqComponent extends TabParagraphContainerComponent implements AfterViewInit, OnInit {
  constructor(
    public componentData: CmsComponentData<CMSTabParagraphContainer>,
    protected cmsService: CmsService,
    protected winRef: WindowRef
  ) {
    super(componentData, cmsService, winRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  select(tabNum: number, event?: MouseEvent): void {
    const previousTabValue = this.activeTabNum;
    this.activeTabNum = tabNum;
    if (event && event?.target) {
      const target = event.target as HTMLElement;
      const parentNode = target.parentNode as HTMLElement;
      if (previousTabValue !== this.activeTabNum) {
        this.winRef?.nativeWindow?.scrollTo({
          left: 0,
          top: parentNode.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }
}
